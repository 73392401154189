import { FilterElement, InputFilterElement } from '@/Domain/Entities';
import { FilterTypes } from '@/Domain/enum';

export const CourtEditStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                param: ['title'],
                label: 'lang.listManagement.searchProcess',
                icon: 'fa-search'
            })
        ]
    },
    mutations: {
        setFilters(state, filters: FilterElement[]) {
            state.filters = filters;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        }
    }
};
