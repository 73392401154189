import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/';
import csvService from '@/Services/CsvService';
import { ProcessType } from '@/Domain/enum';

export default class ProcessesService {

    public static async getProcessListByType(processType: number): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(ProcessType.urlString[processType], { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessById(id: string, processType: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(ProcessType.urlString[processType] + '/' + id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addProcess(process: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(ProcessType.urlString[process.type], process, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateProcess(process: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(ProcessType.urlString[process.type] + '/' + process.id, process, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeProcess(id: string, processType: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(ProcessType.urlString[processType] + '/' + id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async copyProcess(id: string, processType: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(ProcessType.urlString[processType] + '/' + id + '/duplicate', {}, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcessRankedTurns(processId: string, processType: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(ProcessType.urlString[processType] + '/' + processId + '/turns/', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessListInscription(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes/inscription', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessListScale(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes/scale', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessInscriptions(processId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/inscriptions', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getInscriptionStructure(processId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/inscriptionStructure', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getElementsInTabs(processId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/totalInscriptions', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getCSVToDownload(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/csv');
    }

    public static async getCSVDisabilityToDownload(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/disabilityCsv');
    }

    public static async getCSVAuthorizationToDownload(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/authorizationCsv');
    }

    public static async getCSVContactDataToDownload(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/contactDataCsv');
    }

    public static async getCSVPaymentFees(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/paymentCsv');
    }

    public static async getCSVSubmissionRequest(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/submissionRequestCsv');
    }

    public static async getCSVMerits(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/merits/csv?examId=' + examId);
    }

    public static async getCSVContactDataMerit(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/merits/contactDataCsv?examId=' + examId);
    }

    public static async getCSVMeritGroupsCourt(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/meritGroups/csv?examId=' + examId);
    }

    public static async getCSVMeritGroupsAutoScore(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/meritGroups/autoScoreCsv?examId=' + examId);
    }

    public static async getCSVMeritsOficio(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/merits/oficioCsv?examId=' + examId);
    }

    public static async getCSVExams(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/exams/' + examId + '/csv');
    }

    public static async getCSVContactDataExam(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/exams/' + examId + '/csv/contactData');
    }

    public static async getCSVExamCall(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/exams/' + examId + '/csv/examCall');
    }
    
    public static async getCSVListPosition(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/rankedInscriptionsCsv');
    }
    
    public static async getCSVContactDataList(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/rankedInscriptionsContactDataCsv');
    }

    public static async getCSVListVacancies(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/QualifiedRankedInscriptionsCsv');
    }

    public static async getCSVDraftInscriptions(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/draft/csv');
    }

    public static async getCSVTestRequirementsIsncriptions(processId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/inscriptions/testrequirements/csv');
    }

    public static async getCSVimportGradesTemplate(processId: string, examId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, 'processes/' + processId + '/exams/' + examId + '/grades/importGradesCsvTemplate');
    }

    public static async importCSVExam(data: any): Promise<any[]> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.put('processes/' + data.processId + '/exams/' + data.examId + '/grades/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async getIsInUse(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/isInUse', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcess(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcessWithFactor(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/factor', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateDataProcessFactor(processId: string, factor: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('processes/' + processId + '/factor', factor, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcessScaleInscriptions(processId: string, examId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/scaleInscriptions?examId=' + examId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcessExamsInscriptions(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/Exams/info', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcessExamsGradesInscriptions(processId: string, examId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/Exams/' + examId + '/grades', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateGradesExam(processId: string, examId: string, examList: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('processes/' + processId + '/Exams/' + examId + '/grades', examList, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async isRankBlocked(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/isRankBlocked/', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getDataProcessRankedByTurn(processId: string, turnType?: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/rankedInscriptions?turn=' + (turnType !== undefined ? turnType : ''), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async generateList(processId: string, factorObject: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('processes/' + processId + '/rankedInscriptions', factorObject, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async startProccess(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/evaluationPhase', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async finishProcesses(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/finishPhase', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async finishEmptyProcess(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/finishEmptyProcess', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async undoRankingProcess(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('processes/' + processId + '/rankedInscriptions/undo', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async solveTieBlock(processId: string, order): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('processes/' + processId + '/rankedOrder', order, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async validateTieBreaks(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/validateTieBreaks', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async getProcessesFinished(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('processes/availableForReplacementList', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getInscriptionsReplacementProvisionalRankedByTurn(processId: string, turnType: number | null, criteria: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(`processes/${processId}/inscriptionsAvailableForReplacementList?turn=${(turnType !== null ? turnType : '')}&criteria=${(criteria.criteria)}&examId=${criteria.examId}&numExams=${criteria.numExams}&showOnlyApproved=${criteria.showOnlyApproved}&orderCriteria=${(criteria.orderCriteria)}`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getTurnsByProcessId(processId: string, criteria: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`processes/${processId}/inscriptionsAvailableForReplacementList/turns?criteria=${criteria.criteria}&examId=${criteria.examId}&numExams=${criteria.numExams}&showOnlyApproved=${criteria.showOnlyApproved}&orderCriteria${(criteria.orderCriteria)}`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateVisibilityMeritIncidence(processId: string, showMeritIncidences: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/showMeritIncidences', showMeritIncidences, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async filterProcessByExamId(processId: string, filterExam: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/filterExams', filterExam, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async undoEvaluationPhase(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('processes/' + processId + '/evaluationPhase/undo', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async undoFinishPhase(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/unfinishPhase', null, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }
    
    public static async sendMassiveNoticeDocument(processId: string, massiveNoticeDocument: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('processes/' + processId + '/mail/notice', massiveNoticeDocument, { baseURL })
            .then(response => resolve(response.data))
            .catch(error => reject());
        });
    }
    
    public static async getHistoryNoticeDocument(processId: string, noticeDocumentId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/mail/notice/history?DocumentId=' + noticeDocumentId, { baseURL })
            .then(response => resolve(response.data))
            .catch(error => reject());
        });
    }

    public static async setAllowDocumentsNotice(processId: string, allowDocumentsNotice: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('processes/' + processId + '/document/notice', { allowDocumentsNotice }, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async getCSVNoticeDocumentHistorial(processId: string, documentId: string, noticeHistoryId: string): Promise<any[]> { // falta url descargar CSV
        return csvService.getCsv(baseURL, 'processes/' + processId + '/csv/notice/history?DocumentId=' + documentId + '&NoticeHistoryId=' + noticeHistoryId);
    }

    public static async createRuleTieBreak(processId: string, ruleTieBreakToServer: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('processes/' + processId + '/tiebreak/rules', ruleTieBreakToServer, { baseURL })
            .then(response => resolve(response.data))
            .catch(error => reject());
        });
    }

    public static async getRuleTieBreak(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processes/' + processId + '/tiebreak/rules', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeRuleTieBreak(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete('processes/' + processId + '/tiebreak/rules', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateRuleTieBreak(processId: string, ruleTieBreakToServer: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('processes/' + processId + '/tiebreak/rules', ruleTieBreakToServer, { baseURL })
            .then(response => resolve(response.data))
            .catch(error => reject());
        });
    }
}
