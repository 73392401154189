import { Component, Prop, Vue } from 'vue-property-decorator';
import { FilterTypes } from '@/Domain/enum';
import { MdDatepicker} from '@/Components';
import { Filter } from '@/Domain/Entities';

@Component({
    components: { MdDatepicker }
})
export default class MdFilter extends Vue {
    @Prop() value!: Filter;
    FilterTypes = FilterTypes;
}
