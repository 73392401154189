import { securityService } from '@/Application/Services/auth/SecurityService';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Roles } from '@/Domain/enum';

@Component({
  computed: {
    ...mapGetters('userStore', { authData: 'getAuthData' })
  }
})
export default class AccessDenied extends Vue {
  authData!: { role: string, saviaHubClientId: string };

  get isUserWithoutValidRole() {
    return this.authData && this.authData.role === Roles.NOROLE || this.authData.role === Roles.DISABLED;
  }
  
  closeModalNoRole() {
    securityService.signOut();
  }
}
