import { Component, Prop, Vue } from 'vue-property-decorator';
import { Process } from '@/Domain/Entities';
import { mapState, mapGetters } from 'vuex';
import { ProcessType, ProcessStatus, PayExemptionTypes, PaymentType } from '@/Domain/enum';
import { Toggle, Modal, MdRadio } from '@/Components';
import _ from 'lodash';

@Component({
    components: {
        Toggle,
        Modal,
        MdRadio
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        isAnyPayExemptionValue() {
            return this.$props.value.fees.payExemptionTypesView.find(payment => payment.value);
        },
        payExemptionTypesViewOrdered() {
            return _.orderBy(this.$props.value.fees.payExemptionTypesView, [payExemptionTypeView => _.deburr(payExemptionTypeView.text[(this as Payment).currentLanguage]).toLowerCase()]);
        }
    }
})
export default class Payment extends Vue {
    currentLanguage!: string;
    @Prop() value!: Process;
    processType = ProcessType;
    processStatus = ProcessStatus;
    payExemptionTypes = PayExemptionTypes;
    paymentType = PaymentType;

    editTogglePayment() {
        if (this.value.isUsed || this.value.stateToggle) {
            return;
        }
    }
}
