import { ReplacementListMergeOptions } from '@/Domain/enum';
import WizardMerge from './WizardMerge';
import ReplacementMergeService from './ReplacementMergeService';
import ReplacementService from '@/Services/ReplacementService';
import ReplacementMergeUseCaseBase from './ReplacementMergeUseCaseBase';
import ProcessesService from '@/Services/ProcessesService';

export default class ReplacementMergeCreateUseCase extends ReplacementMergeUseCaseBase {

  static async createReplacementMergedList(wizardMerge: WizardMerge) {
    try {
      if (wizardMerge.replacementMerge.mergeOptionSelected === ReplacementListMergeOptions.LIST_WITH_LIST) {
        await ReplacementService.createReplacementMergedListWhenMergeIsListToList(ReplacementMergeService.getReplacementMergeWithReplacementCommand(wizardMerge));
      }
      if (wizardMerge.replacementMerge.mergeOptionSelected === ReplacementListMergeOptions.LIST_WITH_PROCESS) {
        await ReplacementService.createReplacementMergedListWhenMergeIsListToProcess(ReplacementMergeService.getReplacementMergeWithProcessCommand(wizardMerge));
      }
    } catch (error) {
      console.error(error);
    }
  }

  static async getProvisionalReplacementMergedListCandidates(wizardMerge: WizardMerge) {
    try {
      const candidates = await this.getProvisionalMergedList(wizardMerge);

      return candidates ? candidates : [];
    } catch (error) {
      return [];
    }
  }

  static async getMergeItemsDataRequest(optionSelected: number) {
    try {
      const replacementLists = await this.getReplacementList();
      let elementListB;
      if (optionSelected === ReplacementListMergeOptions.LIST_WITH_PROCESS) {
        elementListB = await this.getProcessesAvailableForMerge();
      }

      return {
        listReplacementListA: replacementLists,
        replacementElementB: elementListB ? elementListB : replacementLists
      };
    } catch (error) {
      return {
        listReplacementListA: [],
        replacementElementB: []
      };
    }
  }

  private static async getReplacementList() {
    try {
      const response = await ReplacementService.getReplacementList();
      const replacementLists = response.map(replacement => ReplacementMergeService.mapReplacementMergeReplacementList(replacement));
      return replacementLists;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private static async getProcessesAvailableForMerge() {

    try {
      const response = await ProcessesService.getProcessesFinished();
      const elementListB = response.map(process => ReplacementMergeService.mapReplacementMergeProcessItem(process));
      return elementListB;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
