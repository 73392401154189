import { Component, Vue } from 'vue-property-decorator';
import { Toaster, Toggle } from '@/Components';
import { ToasterService } from '@/Services/ToasterService';
import PanelService from '@/Application/Services/PanelService';
import MasterDataService from '@/Services/MasterDataService';
import { securityService } from '@/Application/Services/auth/SecurityService';
import UserService from '@/Services/UserService';
import ClientService from '@/Services/ClientService';
import { Languages, Constants, PaymentsPlanStatusTypes, Roles } from '@/Domain/enum';
import { Candidate, ClientInfo, PaymentPlan, Security } from '@/Domain/Entities';
import * as sdPanel from '../../node_modules/saviafront/lib/js/compiled/sd-panel';
import * as notification from '../../node_modules/saviafront/lib/js/compiled/notification';
import i18n from '../lang';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import componentsGlobal from '@/Components/componentsGlobal';
import CandidatesSearch from '@/Components/CandidatesSearch/CandidatesSearch';

Object.entries(componentsGlobal).forEach(([name, component]) => {
    Vue.component(name, component);
});

@Component({
    components: { Toaster, Toggle, CandidatesSearch },
    computed: {
        ...mapState('featureFlagsStore', ['header', 'multiLanguage']),
        ...mapState('languagesStore', { availableLanguajes: 'availables', currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('candidateStore', { candidateInfo: 'getCandidateInfo' }),
        ...mapGetters('userStore', { authData: 'getAuthData' }),
        showHeader(): boolean {
            return this.$route.meta.header;
        },
        headerText(): string {
            return this.$route.meta.header.headerText;
        },
        headerURL(): string {
            return this.$route.meta.header.headerURL;
        },
        headerButton(): string {
            return this.$route.meta.header.headerButton;
        },
        headerImageClass(): string {
            return this.$route.meta.header.headerImageClass;
        },
        fullWidth(): string {
            return this.$route.meta.fullWidth;
        },
        dashboardRouteActive() {
            return this.$route.fullPath.indexOf(Constants.routeActive.DASHBOARD) === 0;
        },
        publicationRouteActive() {
            return this.$route.fullPath.indexOf(Constants.routeActive.PROCESS) === 0 || this.$route.fullPath.indexOf(Constants.routeActive.BOEP) === 0;
        },
        listManagementRouteActive() {
            return this.$route.fullPath.indexOf(Constants.routeActive.LIST_MANAGEMENT) === 0;
        },
        scalingRouteActive() {
            return this.$route.fullPath.indexOf(Constants.routeActive.RATINGS) === 0;
        },
        replacementRouteActive() {
            return this.$route.fullPath.indexOf(Constants.routeActive.REPLACEMENT) === 0;
        },
        candidatesRouteActive() {
            return this.$route.fullPath.indexOf(Constants.routeActive.CANDIDATES) === 0;
        },
        isAdminUser() {
            return (this as Layout).profile.adminRole === Roles.ADMIN;
        },
        isUserWithRole() {
            return (this as Layout).profile.adminRole !== Roles.NOROLE && (this as Layout).profile.adminRole !== Roles.DISABLED;
        }
    },
    methods: {
        ...mapActions('languagesStore', ['setAvailableLanguage']),
        ...mapMutations('languagesStore', ['setCurrentLanguage']),
        ...mapMutations('dateFormatStore', ['setDateFormat']),
        ...mapMutations('candidateStore', ['setCandidateInfo'])
    }
})

export default class Layout extends Vue {
    dateFormat!: string;
    availableLanguajes!: string[];
    currentLanguage!: string;
    isAdminUser!: boolean;
    candidateInfo!: Candidate | null;
    authData!: { role: string, saviaHubClientId: string };

    Languages = Languages;
    PaymentsPlanStatusTypes = PaymentsPlanStatusTypes;
    
    clientInfo: any = null;
    notificationList: any[] = [];
    mgr = securityService;
    nameClient: string = '';
    security: Security = new Security({});
    currentPassword = '';
    newPassword = '';
    repeatPassword = '';
    profile: any = null;
    modalAuthentication: boolean = false;
    twoFactor: boolean = false;
    notRecommendBrowser: boolean = false;
    paymentPlanClient: PaymentPlan = new PaymentPlan({});
    isPlanExceed: boolean = false;
    isPlanWarning: boolean = false;
    planClient: any = null;
    lastLogin: any = null;
    loading: boolean = false;
    showModalPersecutory: boolean = false;
    timeoutModalPersecutory: any = null;
    serviceStopStartDate: moment.Moment = moment('2025-02-19T16:30:00');
    serviceStopEndDate: moment.Moment = moment('2025-02-19T18:30:00');
    serviceStopTranslationData = { dayMonth: this.serviceStopStartDate.format('DD/MM'), startHour: this.serviceStopStartDate.format('HH:mm'), endHour: this.serviceStopEndDate.format('HH:mm') };
    isServiceStopActive: boolean = moment().utc() <= this.serviceStopStartDate;
    showServiceStopModal: boolean = false;
    dontShowServiceStopModalAgain: boolean = false;
    showNotificationServiceStop: boolean =  this.isServiceStopActive;
    serviceStopLocalStorageKey = 'dontShowServiceStopModal';
    isCandiateSlideOverVissible: boolean = false;
    saviaHubAuthUrl = process.env.VUE_APP_BASE_URL_SAVIA_HUB + 'protocol/openid-connect/auth';

    setAvailableLanguage!: (arg1: any) => void;
    setCurrentLanguage!: (arg1: string) => void;
    setDateFormat!: (arg1: string) => void;
    setCandidateInfo!: (arg1: null) => void;

    removeCandidateInfo() {
        this.setCandidateInfo(null);
    }

    changeLocal(language: string) {
        i18n.locale = language;
        moment.locale(language);
        const l = 'L';
        this.setDateFormat((moment().locale(this.$i18n.locale).localeData() as any)._longDateFormat[l].replace(/-/g, '/'));
        window.localStorage.setItem('local', language);
        axios.defaults.headers.common['Accept-Language'] = i18n.locale;
        this.setCurrentLanguage(language);
    }

    getImageUrl(image: string) {
        return require('../../assets/img/' + image);
    }
    
    goToChangePassword() {
        const redirectUrl = encodeURIComponent(window.location.href);

        window.location.href = `${this.saviaHubAuthUrl}?client_id=${this.authData.saviaHubClientId}&redirect_uri=${redirectUrl}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;
    }

    goToJira() {
        window.open('https://savia.atlassian.net/servicedesk/customer/portal/2');
    }

    openModalAuthentication() {
        if (this.security.twoFactorMandatory) {
            return;
        }
        this.modalAuthentication = true;
        this.twoFactor = _.cloneDeep(this.profile.twoFactor);
    }

    closeModalAuthentication() {
        this.modalAuthentication = false;
    }

    acceptModalServiceStop() {
        if (this.dontShowServiceStopModalAgain) {
            const serviceStopData = { serviceStopNotificationReaded: this.dontShowServiceStopModalAgain, expires: this.serviceStopEndDate};
            localStorage.setItem(this.serviceStopLocalStorageKey, JSON.stringify(serviceStopData));
        }
        this.showServiceStopModal = false;
    }

    checkModalServiceStopLocalStorage() {
        const serviceStopItem = localStorage.getItem(this.serviceStopLocalStorageKey);
        if (!serviceStopItem) {
            return;
        }
        const serviceStopItemData = JSON.parse(serviceStopItem);
        if (moment() > moment(serviceStopItemData.expires)) {
            localStorage.removeItem(this.serviceStopLocalStorageKey);
        }
    }
    
    resetTimeModal() {       
        this.showModalPersecutory = false;
        this.timeoutModalPersecutory = setTimeout(this.setModalPersecutory, 600000);
    }

    updateDoubleAuthentication() {
        this.modalAuthentication = false;
        UserService.updateDoubleAuthentication(this.clientInfo.getClientId(), this.profile.sub, this.twoFactor).then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.profile.twoFactor = this.twoFactor;
        }).catch(error => console.log(error));
    }

    getLastLogin() {
        UserService.getLastLogin(this.clientInfo.getClientId(), this.profile.sub).then(response => {
            if (response) {
                this.lastLogin = moment.utc(response).local();
            }
        }).catch(error => console.log(error));
    }

    setModalPersecutory() {
        this.showModalPersecutory = true;
        PanelService.panelsClose();
    }

    async getSubscriptionPlan() {
        this.paymentPlanClient = new PaymentPlan(await ClientService.getSubscriptionPlan());
        this.clientInfo.setPaymentPlanSelected(this.paymentPlanClient.paymentPlan);
        this.planClient = this.clientInfo.getPaymentPlanSelected();
        this.isPlanExceed = this.paymentPlanClient.levelPlanProcess === PaymentsPlanStatusTypes.enum.EXCEED || this.paymentPlanClient.levelPlanInscriptions === PaymentsPlanStatusTypes.enum.EXCEED;
        this.isPlanWarning = this.paymentPlanClient.levelPlanProcess === PaymentsPlanStatusTypes.enum.WARNING || this.paymentPlanClient.levelPlanInscriptions === PaymentsPlanStatusTypes.enum.WARNING;
        if (!this.isPlanExceed) {
            clearTimeout(this.timeoutModalPersecutory);
            this.timeoutModalPersecutory = null;
        } else if (!this.timeoutModalPersecutory) {
            this.setModalPersecutory();
        }
        this.$nextTick(() => {
            notification.initialize();
        });
        return [this.paymentPlanClient, this.isPlanExceed, this.isPlanWarning, this.planClient];
    }

    setLoadingAndPanel() {
        this.loading = false;
        this.$nextTick(() => {
            sdPanel.initialize();
        });
    }

    toggleCandatidatesSlideOver() {
        this.isCandiateSlideOverVissible = !this.isCandiateSlideOverVissible;
    }

    init() {
        MasterDataService.getClientLanguajes().then(response => {
            i18n.locale = window.localStorage.getItem('local') !== null ? window.localStorage.getItem('local') as string : (response as any).defaultLanguage;
            axios.defaults.headers.common['Accept-Language'] = i18n.locale;
            this.setAvailableLanguage(response);
            this.setCurrentLanguage(i18n.locale);
            MasterDataService.getClientInfo()
                .then(responseMasterData => {
                    this.clientInfo = ClientInfo.getInstance(responseMasterData);
                    this.nameClient = this.clientInfo.getName();
                    this.security = this.clientInfo.getSecurity();
                    this.getLastLogin();
                    this.setLoadingAndPanel();
                })
                .catch(error => console.log(error));
            this.notificationList = ToasterService.notificationList;
            moment.locale(i18n.locale);
            const l = 'L';
            this.setDateFormat((moment().locale(this.$i18n.locale).localeData() as any)._longDateFormat[l].replace(/-/g, '/'));
        }).catch(error => console.log(error));
    }

    mounted() {
        this.loading = true;
        this.profile = this.$parent.$data.profile;
        this.notRecommendBrowser = this.$parent.$data.myBrowserService.notRecommendBrowser;
        this.checkModalServiceStopLocalStorage();
        this.showServiceStopModal = this.isServiceStopActive && (!localStorage.getItem(this.serviceStopLocalStorageKey));
        this.init();
    }
}
