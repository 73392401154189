import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { MultiLanguage, TurnsWithReferences } from '@/Domain/Entities';
import { Constants } from '@/Domain/enum';
import { IProcessReference } from '@/Domain/interfaces/Process/IProcessReference';
import { ITurnWithReferences } from '@/Domain/interfaces/Process/ITurnWithReference';

@Component({
  name: 'turn-preference',
  computed: {
    ...mapState('languagesStore', { currentLanguage: 'current' }),
    ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
    ...mapState('featureFlagsStore', ['multiLanguage'])
  }
})

export default class TurnPreference extends Vue {
  @Prop() submitted!: boolean;
  @Prop() isDisabled!: boolean;
  @Prop() turnsWithReference!: TurnsWithReferences;

  currentLanguage!: any;
  availableLanguages!: any;
  Constants = Constants;
  tabActiveLanguage = Constants.language.ES_ES;

  get turns() {
    return this.turnsWithReference ? this.turnsWithReference.turns : [];
  }

  get turnActive() {
    return this.turnsWithReference ? this.turnsWithReference.turnActive : { references: [] as IProcessReference[], vacancies: 0 };
  }

  get totalVacanciesAllTurns() {
    return this.turns.reduce((acc: number, turn: ITurnWithReferences) => acc + turn.total, 0);
  }

  get isDisabledAddRefButton() {
    return this.turnsWithReference.anyTurnHasVacancies() || this.isDisabled;
  }

  get isDisabledAddVacanciesInput() {
    return this.turnsWithReference.anyTurnHasReferences() || this.isDisabled;
  }

  get turnsWithErrors() {
    return this.turnsWithReference.turns.map(turn => {
      if (this.submitted && !this.turnsWithReference.isTurnValid(turn, this.availableLanguages)) {
        return turn.type;
      }
      return undefined;
    }).filter(turnType => turnType !== undefined);
  }
  
  changeTab(type: number) {
    this.$emit('change-active-turn', type);
  }

  addReference() {
    if (this.isDisabledAddRefButton) {
      return;
    }
    
    this.$emit('add-reference');
  }

  removeReference(indexToRemove: number) {
    this.$emit('remove-reference', indexToRemove);
  }

  onVacancieChange(event) {
    const valueToSend = event.target.value !== '' ? event.target.value : 0;
    this.$emit('update-turn', valueToSend);
  }

  onReferenceMultiLanguageInput(index: number, value: any) {
    const referenceToUpdate = this.turnActive!.references[index];
    const reference = { ...referenceToUpdate, description: new MultiLanguage({ ...referenceToUpdate.description, [this.tabActiveLanguage]: value }) };
    this.updateReference({ reference, index });
  }

  onReferenceInput(index: number, propName: string, value: any) {
    const reference = { ...this.turnActive!.references[index], [propName]: value };
    this.updateReference({ reference, index });
  }

  updateReference(updateEmitData: { reference: IProcessReference, index: number }) {
    this.$emit('update-reference', updateEmitData);
  }

  mounted() {
    this.tabActiveLanguage = this.currentLanguage;
  }
}
