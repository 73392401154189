import { ReplacementMergeProcess, ReplacementMergeReplacementList } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeElement';
import ReplacementMergeService from './ReplacementMergeService';
import { ReplacementListMergeOptions } from '@/Domain/enum/ReplacementListMergeOptions';
import ReplacementService from '@/Services/ReplacementService';
import ProcessesService from '@/Services/ProcessesService';
import WizardMerge from './WizardMerge';
import { IProvisionalReplacementCandidate } from '@/Domain/interfaces/IProvisionalReplacementCandidate';
import { IReplacementCandidateIdentification } from '@/Domain/interfaces/IReplacementCandidateIdentification';
import ReplacementMergeUseCaseBase from './ReplacementMergeUseCaseBase';

export default class ReplacementMergeEditUseCase extends ReplacementMergeUseCaseBase {

    static async getSourcesListsDetails(replacementMerge): Promise<{ replacementListA: ReplacementMergeReplacementList, elementToMergeB: ReplacementMergeProcess | ReplacementMergeReplacementList } | undefined> {
        try {
            const replacementListA = await this.getReplacementListDetailById(replacementMerge.replacementListAId);

            let elementToMergeB;
            switch (replacementMerge.mergeOptionSelected) {
                case ReplacementListMergeOptions.LIST_WITH_LIST:
                    elementToMergeB = await this.getReplacementListDetailById(replacementMerge.elementToMergeBId);
                    break;
                case ReplacementListMergeOptions.LIST_WITH_PROCESS:
                    elementToMergeB = await this.getProcessDetailById(replacementMerge.elementToMergeBId);
                    break;
            }

            return { replacementListA, elementToMergeB };

        } catch (error) {
            console.error(error);
        }
    }

    static async editReplacementMergedList(editData: { wizardMerge: WizardMerge, replacementListId: string, originalMergedCandidates: string[], replacementMergedCandidates: IProvisionalReplacementCandidate[]}) {
        editData.wizardMerge.replacementMerge.candidatesIds = editData.replacementMergedCandidates.filter(candidate => candidate.selected).map(candidate => candidate.replacementListCandidateId);
        try {
            await ReplacementService.editReplacementMergedList(ReplacementMergeService.mapReplacementMergetoReplacementMergeEdit({
                wizardMerge: editData.wizardMerge,
                candidateEdition: this.someCandidateWasEdited(editData.wizardMerge.replacementMerge.candidatesIds, editData.originalMergedCandidates),
                replacementListId: editData.replacementListId
            }));
        } catch (error) {
            throw new Error('Error Editing Replacement Merged List');
        }
    }

    static async getProvisionalCandidatesOfEditedReplaementMerge(editQuery: { wizardMerge: WizardMerge, replacementListId: string, replacementMergedCandidates: IProvisionalReplacementCandidate[], originalMergedCandidates: string[] }): Promise<{ replacementMergedCandidates: IProvisionalReplacementCandidate[], originalMergedCandidates: string[] }> {
        try {
            const candidates = await this.getProvisionalMergedList(editQuery.wizardMerge);
            const candidatesIdentifications = await this.getMergeCandidatesIdentifications(editQuery);

            return {
                replacementMergedCandidates: candidates ? candidates : [],
                originalMergedCandidates: candidatesIdentifications ? candidatesIdentifications.map(candidate => candidate.nif) : []
            };
        } catch (error) {
            console.error(error);
            return {
                replacementMergedCandidates: [],
                originalMergedCandidates: []
            };
        }
    }

    private static async getMergeCandidatesIdentifications(mergedCandidateQuery: { wizardMerge: WizardMerge, originalMergedCandidates: string[], replacementListId: string }): Promise<IReplacementCandidateIdentification[] | undefined> {
        try {
            const { candidates } = await ReplacementService.getCandidates(mergedCandidateQuery.replacementListId);
            return candidates;
        } catch (error) {
            console.error(error);
        }
    }

    private static async getReplacementListDetailById(id: string): Promise<ReplacementMergeReplacementList> {
        try {
            const response = await ReplacementService.getReplacementById(id);
            if (!response) {
                throw new Error();
            }
            return ReplacementMergeService.mapReplacementMergeReplacementList(response);
        } catch (error) {
            throw new Error('List Detail Not Found');
        }
    }

    private static async getProcessDetailById(id: string): Promise<ReplacementMergeProcess> {
        try {
            const response = await ProcessesService.getDataProcess(id);
            if (!response) {
                throw new Error();
            }
            return ReplacementMergeService.mapReplacementMergeProcessItem(response);
        } catch (error) {
            throw new Error('Process Detail Not Found');
        }
    }

    private static someCandidateWasEdited(candidatesSelected: string[], originalMergedCandidates: string[]): boolean {
        return candidatesSelected.some(candidateId => !originalMergedCandidates.includes(candidateId));
    }
}
