import { Component, Vue } from 'vue-property-decorator';
import { CourtUser, Filter, FilterElement, Process } from '@/Domain/Entities';
import CourtService from '@/Services/CourtService';
import { ToasterService } from '@/Services/ToasterService';
import { OppositionType, ProcessType, ProcessPhases, GroupTypes } from '@/Domain/enum';
import i18n from '../../lang';
import _ from 'lodash';
import { Breadcrumb, Toggle, SdInputMail, SdFilter } from '@/Components';
import { mapGetters, mapMutations, mapState } from 'vuex';
import ProcessesService from '@/Services/ProcessesService';

@Component({
    components: {
        SdInputMail,
        Toggle,
        Breadcrumb,
        SdFilter
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat' }),
        ...mapGetters('courtEditStore', { filters: 'getFilters' })
    },
    methods: {
        ...mapMutations('courtEditStore', ['setFilters']),
    },
    watch: {
        filter() {
            (this as CourtEdit).setFilters((this as CourtEdit).filter.filterElements);
        }
    }
})

export default class CourtEdit extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    filters!: FilterElement[];
    setFilters!: (arg1: FilterElement[]) => void;
    selectedProcesses: Set<string> = new Set<string>();

    courtUser: CourtUser = new CourtUser({});
    isEditing: boolean = false;
    processList: Process[] = [];
    waitingResponse: boolean = false;
    submitted: boolean = false;
    showSelectProcessError = false;
    OppositionType = OppositionType;
    ProcessType = ProcessType;
    ProcessPhases = ProcessPhases;
    GroupTypes = GroupTypes;
    filter = new Filter();

    $refs!: {
        CourtForm: HTMLFormElement
    };

    setProcessIds() {
        this.courtUser.resetProcessIds();
        this.selectedProcesses.forEach((processId: string) => {
            this.courtUser.processIds.push(processId);
        });
    }

    get orderedProcesses() {
        if (this.filter.filteredData.length === 0) { return []; }
        const ordered = _.orderBy(this.filter.filteredData, [process => _.deburr(process.title[this.currentLanguage]).toLowerCase()]);
        ordered.forEach((process: Process) => { process.active = this.selectedProcesses.has(process.id)});
        return ordered;
    }

    onProcessActiveInput(process: Process) {
        if (process.active) {
            this.selectedProcesses.add(process.id);
            return;
        } else if (this.selectedProcesses.has(process.id)) {
            this.selectedProcesses.delete(process.id);
        }
    }

    save() {
        this.submitted = true;
        if (this.waitingResponse) { return; }
        if (this.$refs.CourtForm.querySelectorAll(':invalid').length || this.$refs.CourtForm.querySelectorAll('.error').length) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        this.setProcessIds();
        if (!this.courtUser.validateProcessSelected()) {
            this.showSelectProcessError = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        const action = this.isEditing ? CourtService.updateCourt : CourtService.addCourt;
        this.showSelectProcessError = false;
        this.waitingResponse = true;
        const objectToSend = this.courtUser.toServer();
        action(objectToSend).then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.waitingResponse = false;

            if (!this.isEditing) {
                this.$router.push({ name: 'CourtList' });
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
            .catch(() =>
                this.waitingResponse = false
            );
    }

    getCourtByEmail() {
        CourtService.getCourtByEmail(this.$route.params.email)
            .then(response => {
                this.courtUser = new CourtUser(response);
                if (this.courtUser.processIds.length) {
                    this.selectedProcesses = new Set<string>(this.courtUser.processIds);
                }
                this.getProcessList();
            }).catch(error => console.log(error));
    }

    getProcessList() {
        ProcessesService.getProcessList()
            .then(response => {
                this.processList = response.reduce((result, process: Process) => {
                    if (this.courtUser.processIds.length) {
                        this.courtUser.setActiveProcess(process);
                    }
                    if (!this.isEditing && process.phase !== ProcessPhases.enum.FINISH) {
                        result.push(new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
                    }
                    if (this.isEditing && ((process.phase === ProcessPhases.enum.FINISH && process.active) || process.phase !== ProcessPhases.enum.FINISH)) {
                        result.push(new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
                    }
                    return result;
                }, []);
                this.filter.setData(this.processList);
                this.filter.setFilterElements(this.filters);
            })
            .catch(error => console.log(error));
    }

    created() {
        if (this.$route.params.email) {
            this.isEditing = true;
            this.getCourtByEmail();
        } else {
            this.getProcessList();
        }
    }
}
