export enum ReplacementListMergeOptions {
  NOT_SELECTED = -1,
  LIST_WITH_LIST,
  LIST_WITH_PROCESS
}

export const ReplacementListMergeOptionsLabel = {
  LIST_WITH_LIST: 'lang.replacement.listWithList',
  LIST_WITH_PROCESS: 'lang.replacement.listWithProcess'
};
