interface TTurnList { 
  list: Array<{ 
    addCount: () => void, 
    subtractCount: () => void, 
    type: string, 
    count: number 
  }>; 
  activeTab: { 
    type: string, 
    filter: { 
      isSelectedAll: boolean 
    } 
  }; 
}

export default class ReplacementMergeCandidateSelectionHandler {
  turnList: TTurnList;

  constructor(turnList: TTurnList) {
    this.turnList = turnList;
  }
  
  selectCandidate(candidate: { selected: boolean, id: string}, selectedCandidatesIds: string[]) {
    if (candidate.selected) {
      this.handleCandidateSelection(candidate.id, selectedCandidatesIds);
      return;
    }
    this.handleCandidateSelectionRemove(candidate.id, selectedCandidatesIds);
  }

  handleCandidateSelection(replacementListCandidateId: string, selectedCandidatesIds: string[]) {
    const existOnList = selectedCandidatesIds.includes(replacementListCandidateId);
    if (existOnList) {
      return;
    }
    selectedCandidatesIds.push(replacementListCandidateId);
    this.addCount();
  }

  handleCandidateSelectionRemove(replacementListCandidateId: string, selectedCandidatesIds: string[]) {
    const index = selectedCandidatesIds.indexOf(replacementListCandidateId);
    if (index === -1) {
      return;
    }
    selectedCandidatesIds.splice(index, 1);
    this.subtractCount();
  }

  addCount() {
    if (this.turnList !== null) {
      const index = this.turnList.list.indexOf((this.turnList.list as any).find(e => e.type === this.turnList.activeTab.type));
      this.turnList.list[index].addCount();
    }
  }

  subtractCount() {
    if (this.turnList !== null) {
      const index = this.turnList.list.indexOf((this.turnList.list as any).find(e => e.type === this.turnList.activeTab.type));
      this.turnList.list[index].subtractCount();
    }
  }

  initAllTurnsCount(selectedElements: Array<{ turn: number}>) {
    this.turnList.list.forEach(tab => {
      tab.count = selectedElements.filter(candidate => candidate.turn === Number(tab.type)).length;
    });
  }
}
