export default class AnalitycsService {
    static sendEvent(event: { name: string, data: any }) {
        window.dataLayer = window.dataLayer || undefined;

        if (window.dataLayer) {
            const eventName = event.name;
            const [eventParamKey, eventParamValue] = Object.entries(event.data)[0];

            window.dataLayer.push({
                event: eventName,
                [eventParamKey]: eventParamValue
            });
        }
    }
}
