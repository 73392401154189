import { InscriptionTurn } from '@/Domain/enum';
import { ITurnWithReferences } from '@/Domain/interfaces/Process/ITurnWithReference';
import MultiLanguage from '../MultiLanguage';
import { IProcessReference } from '@/Domain/interfaces/Process/IProcessReference';
import Vacancies from '../ProcessStructure/Vacancies';

export default class TurnsWithReferences {
    turns: ITurnWithReferences[] = [
        { type: InscriptionTurn.enum.FREE, active: true, title: 'lang.shared.free', total: 0, references: [], vacancies: 0 },
        { type: InscriptionTurn.enum.FREE_DISABILITY, active: false, title: 'lang.shared.freeDisability', total: 0, references: [], vacancies: 0 },
        { type: InscriptionTurn.enum.INTERNAL, active: false, title: 'lang.shared.internal', total: 0, references: [], vacancies: 0 },
        { type: InscriptionTurn.enum.INTERNAL_DISABILITY, active: false, title: 'lang.shared.internalDisability', total: 0, references: [], vacancies: 0 },
        { type: InscriptionTurn.enum.MOBILITY, active: false, title: 'lang.shared.mobility', total: 0, references: [], vacancies: 0 }
    ];
    bopNumber: string = '';

    constructor(data: { bopNumber: string } = { bopNumber: '' }) {
        this.bopNumber = data.bopNumber;
    }

    get turnActive() {
        return this.turns.find(turn => turn.active);
    }

    public getReferences(): IProcessReference[] {
        return this.turns.reduce((acc: IProcessReference[], turn) => {
            return acc.concat(turn.references);
        }, []);
    }

    public getVacancies(): Vacancies {
       const turnVacancies = this.turns.flatMap(turn => {
            return {turn: turn.type, vacancies: turn.vacancies};
        }).sort((a, b) => a.turn - b.turn);

       return new Vacancies({ 
            free: turnVacancies[InscriptionTurn.enum.FREE].vacancies, 
            internal: turnVacancies[InscriptionTurn.enum.INTERNAL].vacancies, 
            internalDisability: turnVacancies[InscriptionTurn.enum.INTERNAL_DISABILITY].vacancies, 
            freeDisability: turnVacancies[InscriptionTurn.enum.FREE_DISABILITY].vacancies, 
            mobility: turnVacancies[InscriptionTurn.enum.MOBILITY].vacancies, 
            total: this.getTotal() 
        });
    }

    public updateCount() {
        this.turns.forEach(turn => {
            const vacanciesSum = turn.references
                .reduce((acc, reference) => acc + (reference.totalVacancies || 0), 0);
            turn.total = (turn.vacancies || 0) + vacanciesSum;
        });
    }

    public changeActiveTurn(turnType: number) {
        this.turns.forEach(turn => {
            turn.active = turn.type === turnType;
        });
    }

    public buildTurns(data: IProcessReference[] | Vacancies) {
        if (data instanceof Vacancies) {
            this.buildTurnsFromVacancies(data);
        } else {
            this.buildTurnsFromReferences(data);
        }
        this.updateCount();
    }

    anyTurnHasReferences() {
        return this.turns.some(turn => turn.references.length > 0);
    }

    anyTurnHasVacancies() {
        return this.turns.some(turn => turn.vacancies > 0);
    }

    public areTurnsValid(languages: string[] = []) {
        return this.turns.every(turn => {
            return this.isTurnValid(turn, languages);
        });
    }

    public isTurnValid(turn: ITurnWithReferences, languages: string[]) {
        return turn.references.length > 0 
            ? this.areReferencesValid(turn.references, languages)
            : this.areVacanciesValid(turn.vacancies);
    }

    getTurnsWithErrors(languages: string[]) {
        return this.turns.map(turn => {
            if (!this.isTurnValid(turn, languages)) {
              return turn;
            }
            return undefined;
          }).filter(turn => turn !== undefined);
    }
    
    private areReferencesValid(references: IProcessReference[], languages: string[]) {
        return references.every(ref => 
            languages.length > 0 ? ref.description.allHasValue(languages) : ref.description !== undefined
            && ref.reference !== undefined 
            && ref.totalVacancies > 0
        );
    } 

    private areVacanciesValid(vacancies: number) {
        return vacancies >= 0;
    }

    private getTotal() {
        return this.turns.reduce((acc, turn) => acc + turn.total, 0);
    }

    private buildTurnsFromReferences(references: IProcessReference[]) {
        this.turns = this.turns.map(turn => {
           const referencesByTurn = references.filter(ref => ref.turn === turn.type);

           return { 
               ...turn,
               references: referencesByTurn.map(ref => ({ ...ref, description: new MultiLanguage(ref.description) }))
           };
        });      
    }

    private buildTurnsFromVacancies(vacancies: Vacancies) {
       this.turns = [
            { type: InscriptionTurn.enum.FREE, active: true, title: 'lang.shared.free', total: 0, references: [], vacancies: vacancies.freeVacancies },
            { type: InscriptionTurn.enum.FREE_DISABILITY, active: false, title: 'lang.shared.freeDisability', total: 0, references: [], vacancies: vacancies.freeDisabilityVacancies },
            { type: InscriptionTurn.enum.INTERNAL, active: false, title: 'lang.shared.internal', total: 0, references: [], vacancies:  vacancies.internalVacancies },
            { type: InscriptionTurn.enum.INTERNAL_DISABILITY, active: false, title: 'lang.shared.internalDisability', total: 0, references: [], vacancies:  vacancies.internalDisabilityVacancies },
            { type: InscriptionTurn.enum.MOBILITY, active: false, title: 'lang.shared.mobility', total: 0, references: [], vacancies: vacancies.mobilityVacancies }
        ];
    }
}
