import { Component, Prop, Vue } from 'vue-property-decorator';
import { Exam, Process } from '@/Domain/Entities';
import { FormMode, GradeType, Languages, ProcessPhases, Constants, OppositionType } from '@/Domain/enum';
import { EditNewExam, Modal, Toggle } from '@/Components';
import { mapGetters, mapState } from 'vuex';
import ExamService from '@/Services/ExamService';
import ValidationService from '@/Application/Services/ValidationService';
import { OrderService } from '@/Application/Services/OrderService';
import _ from 'lodash';

@Component({
    components: {
        EditNewExam,
        Modal,
        Toggle
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        secondLanguage() {
            return (this as Exams).availableLanguages ? (this as Exams).availableLanguages[1] : null;
        },
        isPublishAndUsed() {
            return this.$props.value.stateToggle || this.$props.value.isUsed;
        },
        isPhasedNotValid() {
            return this.$props.value.phase === ProcessPhases.enum.RANKING || this.$props.value.phase === ProcessPhases.enum.FINISH;
        },
        percentageColor() {
            return Math.round(((this as Exams).totalExamsPercentage() * 79) / 100);
        },
        hasTwoExamsWithScore() {
            return this.$props.value.exams.filter((exam: Exam) => exam.gradeType === GradeType.enum.SCORE).length >= 2;
        },
        isTenderWithRequirementTest() {
            return this.$props.value.oppositionType === OppositionType.enum.TENDER && this.$props.value.hasRequirementWithTest;
        }
    }
})
export default class Exams extends Vue {
    @Prop() value!: Process;

    formMode = FormMode;
    gradeType = GradeType;
    languages = Languages;
    constants = Constants;
    newExam: Exam = new Exam({});
    showDeleteModalExam = false;
    showDisabledModalExam = false;
    examToRemove: Exam = {} as Exam;
    defaultLanguage!: string;
    availableLanguages!: string[];
    isPublish!: boolean;
    percentageTotal: number = 0;
    examsScoreValid: boolean = false;
    hasPercentage: boolean = false;
    exams: Exam[] = [];
    orderService: any;

    $refs!: {
        examList: HTMLElement,
        examFormPercentage: HTMLElement
    };

    hideExamForms() {
        this.value.isCreatingExam = false;
        this.value.isEditingExam = false;
        (this.$parent as any).examScoreError = false;
        this.getExam();
    }

    confirmWithModalExam(exam: Exam) {
        this.examToRemove = exam;
        this.showDeleteModalExam = true;
    }

    showExamScoreError() {
        (this.$parent as any).examScoreError = true;
    }

    submit() {
        (this.$parent as any).submit();
    }

    removeExam() {
        this.showDeleteModalExam = false;

        ExamService.removeExam(this.value.id, this.examToRemove)
            .then(response => {
                const examIndex = this.value.exams.indexOf(this.examToRemove);
                this.value.exams.splice(examIndex, 1);
                this.value.exams.forEach((element, index) => element.order = index);
            })
            .catch(error => console.log(error));

        if (this.value.hasPercentageExams && this.examToRemove.gradeType === this.gradeType.enum.SCORE) {
            const percentageExam = {
                ApplyExamPercentage: false,
                Exams: []
            };
            ExamService.setPercentageExams(this.$route.params.id, percentageExam)
                .then(responseExam => {
                    this.value.isCreatingPercentage = true;
                    this.value.hasPercentageExams = false;
                    this.hasPercentage = false;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getExam() {
        ExamService.getExam(this.$route.params.id)
            .then(responseExam => {
                this.value.exams = responseExam.exams.map(result => new Exam(result));
                this.exams = this.value.exams;
                this.value.hasMultiLanguageExams = responseExam.hasMultiLanguageExams;
                this.hasPercentage = this.value.hasPercentageExams;
                if (this.value.hasPercentageExams && this.value.exams.every(exam => exam.percentage === 0)) {
                    this.value.hasPercentageExams = false;
                    this.hasPercentage = false;
                }
                if (this.value.hasPercentageExams) {
                    this.value.isCreatingPercentage = false;
                }
                this.orderService = new OrderService(this.$route.params.id, this.value.exams, ExamService.setExamOrder);
            })
            .catch(error => console.log(error));
    }

    hasExamImmovableNearTop(index) {
        return this.value.exams.some((exam, i) => i === index && this.value.exams[index - 1].locked);
    }

    editPercentage(exams) {
        this.value.isCreatingExam = false;
        this.value.isEditingExam = false;
        this.value.isCreatingPercentage = true;
        this.exams = _.cloneDeep(exams);
    }

    save() {
        if (!this.examsScoreValid && !this.showDisabledModalExam) {
            return;
        }
        this.value.isSaving = true;
        const examsValid: any = [];
        this.value.exams.forEach(exam => {
            if (exam.gradeType === this.gradeType.enum.SCORE) {
                examsValid.push(exam.toServerPercentage());
            }
        });
        const percentageExam = {
            ApplyExamPercentage: this.value.hasPercentageExams,
            Exams: examsValid
        };
        ExamService.setPercentageExams(this.$route.params.id, percentageExam)
        .then(response => {
            this.value.isCreatingPercentage = false;
            this.value.isSaving = false;
            if (this.showDisabledModalExam) {
                this.showDisabledModalExam = false;
                this.value.isCreatingPercentage = true;
            }
            this.hasPercentage = this.value.hasPercentageExams;
        })
        .catch(error => {
            console.log(error);
            this.value.isSaving = false;
        });
    }

    cancel() {
        this.value.isCreatingPercentage = false;
        this.value.exams = this.exams;
        this.orderService = new OrderService(this.$route.params.id, this.value.exams, ExamService.setExamOrder);
    }

    setPercentageExams() {
        this.value.isCreatingPercentage = true;
        if (this.hasPercentage) {
            this.showDisabledModalExam = true;
            return;
        }
        this.value.exams.forEach(exam => {
            exam.percentage = 0;
        });
    }

    cancelModalSetPercentageExams() {
        this.value.isCreatingPercentage = false;
        this.value.hasPercentageExams = !this.value.hasPercentageExams;
        this.value.exams = this.exams;
        this.orderService = new OrderService(this.$route.params.id, this.value.exams, ExamService.setExamOrder);
    }

    totalExamsPercentage() {
        let total = 0;
        this.value.exams.forEach(exam => {
            total += Number(exam.percentage);
        });
        this.examsScoreValid = this.value.exams.filter(exam => exam.gradeType === this.gradeType.enum.SCORE && exam.percentage === 0).length === 0 && Number(total.toFixed(2)) === 100 && ValidationService.validateFormRequired(this.$refs.examFormPercentage);
        return Number(total.toFixed(2));
    }

    created() {
        if (this.$route.params.id) {
            this.getExam();
        }
        this.defaultLanguage = this.availableLanguages[0];
    }
}
