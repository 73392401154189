import { Component, Vue } from 'vue-property-decorator';
import { InscriptionStatus, ProcessType, MeritGroupType, MeritRatingType, TabsStatusScaling, RatingsTabs, OppositionType, ProcessPhases, Languages, BaseMeritsType, ScaleStatus, StatusExam, StatusDocuments, Constants, FileTypes } from '@/Domain/enum';
import { ProcessInscription, InputFilterElement, ProcessMeritGroup, SorterNew, MeritOepDocument, ProcessMerit, ProcessMeritSubGroup, ClientInfo, Candidate, InscriptionFile, Process } from '@/Domain/Entities';
import { Toggle, MdTextarea, DoughnutChart, FileViewer, Modal, ScalingMerit, SdSlideOver, Tooltip } from '@/Components';
import { ToasterService } from '@/Services/ToasterService';
import ProcessesService from '@/Services/ProcessesService';
import InscriptionService from '@/Services/InscriptionService';
import ValidationService from '@/Application/Services/ValidationService';
import MasterDataService from '@/Services/MasterDataService';
import ClientService from '@/Services/ClientService';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import i18n from '../../lang';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import DocumentService from '@/Application/Services/DocumentService';
import RatingsEdit from './RatingsEdit';

@Component({
    components: { Toggle, MdTextarea, DoughnutChart, FileViewer, Modal, ScalingMerit, Tooltip },
    computed: {
        inscriptionFiltered() {
            const inscriptionsTotal = (this as ScalingEdit).inscriptionList.length;
            const inscriptionsFiltered = (this as ScalingEdit).inscriptionList.filter(element => this.$data.searcherFilter.showElement(element));
            (this as ScalingEdit).$emit('setInscriptionProperties', inscriptionsTotal, inscriptionsFiltered.length);
            return (this as ScalingEdit).sorter.sortBy(inscriptionsFiltered);
        },
        hasMeritGroupListAnyMerit() {
            return this.$data.meritGroupList.some((meritGroup: ProcessMeritGroup) => meritGroup.hasGroupAnyMerit());
        },
        meritDocuments() {
            return this.$data.filesToViewer.filter((doc: MeritOepDocument) => doc.meritId === this.$data.meritSelected.id);
        },
        isFirstInscriptionWithMerits() {
            return (this as ScalingEdit).inscriptionSelected !== null && (this as ScalingEdit).inscriptionsWithMerits[0].id === ((this as ScalingEdit).inscriptionSelected as ProcessInscription).id;
        },
        isLastInscriptionWithMerits() {
            return (this as ScalingEdit).inscriptionSelected !== null && (this as ScalingEdit).inscriptionsWithMerits[(this as ScalingEdit).inscriptionsWithMerits.length - 1].id === ((this as ScalingEdit).inscriptionSelected as ProcessInscription).id;
        },
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        ...mapGetters('candidateStore', { candidateInfo: 'getCandidateInfo' })
    },
    watch: {
        'candidateInfo'() {
            (this as ScalingEdit).searcherFilter.filterString = '';
        },
        '$route.params.id': {
            handler(oldId, newId) {
                if (newId !== oldId) {
                    (this as ScalingEdit).prepareScalingView();
                }
            },
            deep: true
        }
    }
})
export default class ScalingEdit extends Vue {
    availableLanguages!: string[];
    currentLanguage!: string;
    candidateInfo!: Candidate | null;

    routeNameExam: string = '';

    processData: any = '';
    searcherFilter = new InputFilterElement({ param: ['nameSurname', 'surnameName', 'nif'] });
    ProcessType = ProcessType;
    sorter = new SorterNew(['surnameName'], ['desc', 'asc']);
    loading = true;
    inscriptionList: ProcessInscription[] = [];
    inscriptionsWithMerits: ProcessInscription[] = [];
    meritGroupList: ProcessMeritGroup[] = [];
    inscriptionSelected: ProcessInscription | null = null;
    chartLabels = ['Completados', 'Restantes'];
    chartColors = ['#63a4ff', '#DADFE3'];
    waitingResponse: boolean = false;
    sortKey = 'surnameName';
    viewingFile: any = null;
    filesToViewer: MeritOepDocument[] = [];
    nationalities: any[] = [];
    InscriptionStatus = InscriptionStatus;
    MeritGroupType = MeritGroupType;
    MeritRatingType = MeritRatingType;
    ProcessPhases = ProcessPhases;
    TabsStatusScaling = TabsStatusScaling;
    RatingsTabs = RatingsTabs;
    OppositionType = OppositionType;
    Languages = Languages;
    BaseMeritsType = BaseMeritsType;
    ScaleStatus = ScaleStatus;
    StatusDocuments = StatusDocuments;
    Constants = Constants;
    lastMerit = { id: '' };
    processId = '';
    filteredExamId: string = '';
    submitted = false;
    showOnlyApprovedCandidates: boolean = false;
    tabActive: string = '';
    pixelsToScroll: number = 365;
    modalRejectionReason = false;
    processMeritClone: ProcessMerit = new ProcessMerit({}, []);
    tabActiveClone: string = '';
    clientInfo: ClientInfo = new ClientInfo({});
    showModalFilterExams: boolean = false;
    copyExamsList: any;
    copyFilterActiveId: any;
    filterActiveId: string | null = null;
    examsList: any[] = [];
    meritGroupActive: ProcessMeritGroup = new ProcessMeritGroup({}, this.availableLanguages);
    isInvalidValidation: boolean | number = false;
    showSlideOver: boolean = false;
    meritSelected: ProcessMerit = new ProcessMerit({}, []);
    loadingFile: boolean = false;
    showModalMaxScoreReached: boolean = false;
    groupThatReachedMaxScore!: ProcessMeritGroup | ProcessMeritSubGroup;
    isNext: boolean = true;
    showModalAddDoc: boolean = false;
    addFileOrCopy: boolean = false;
    documentsToMerit: InscriptionFile[] = [];
    formDataDoc: any[] = [];
    showModalMerits: boolean = false;
    showModalDocumentNewMerit: boolean = false;
    showModalRememberReviewMerit: boolean = false;
    meritDestinationDoc: ProcessMerit = new ProcessMerit({}, this.availableLanguages);
    showFilterSelected: any;
    allProcessMeritGroups: ProcessMeritGroup[] = [];
    meritGroupsToCopyFile: ProcessMeritGroup[] = [];
    loadingSlideOver: boolean = false;
    disableCandidateNavigation: boolean = false;
    scalingMeritDataChanged: boolean = false;

    $refs!: {
        meritsStatusForm: HTMLFormElement,
        sdSlideOver: SdSlideOver,
        meritsButtonStatus: HTMLFormElement,
        fileViewer: FileViewer,
        refScrollbar: HTMLElement
    };

    acceptCopyDocument() {
        const meritGroup = this.meritGroupList.find((groupMerit: ProcessMeritGroup) => groupMerit.id === this.meritDestinationDoc.meritGroupId);
        if (!meritGroup || !meritGroup.meritGroupContainsThisMerit(this.meritDestinationDoc.id)) {
            this.showModalMerits = false;
            this.showModalDocumentNewMerit = true;
            this.setScoreMeritFixedScore();
            return;
        }
        this.createAndAddFileToInscription();
    }

    closeModalRememberReviewMerit() {
        this.showModalRememberReviewMerit = false;
        this.meritDestinationDoc = new ProcessMerit({}, this.availableLanguages);
    }

    async createAndAddFileToInscription() {
        const data: any = {
            oldMeritId: this.meritSelected.id,
            targetMerit: this.meritDestinationDoc.toServerAddDocumentOtherMerit(),
            documentId: this.viewingFile.id
        };
        InscriptionService.copyFileToInscription((this.inscriptionSelected as ProcessInscription).id, data)
            .then(() => {
                this.showModalMerits = false;
                this.showModalDocumentNewMerit = false;
                this.showModalRememberReviewMerit = true;
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any);
                this.addFileOrCopy = true;
                this.getMerits((this.inscriptionSelected as ProcessInscription));
            });
    }

    closeModalDocumentNewMerit() {
        this.showModalMerits = true;
        this.showModalDocumentNewMerit = false;
        this.meritDestinationDoc.userScore = null;
        this.meritDestinationDoc.userTotalScore = null;
    }

    setMeritDestinationDoc(merit: ProcessMerit) {
        this.meritDestinationDoc = merit;
    }

    setScoreMeritFixedScore() {
        if (this.meritDestinationDoc.ratingType === MeritRatingType.enum.FIXED_SCORE) {
            this.meritDestinationDoc.userScore = 1;
            this.meritDestinationDoc.userTotalScore = this.meritDestinationDoc.score;
        }
    }

    copyFileOtherMerit() {
        if (this.processData.phase > ProcessPhases.enum.EVALUATION) {
            ToasterService.showError(i18n.t('lang.scaling.unallowCopyDocument') as string);
            return;
        }
        this.getDestinyMerits();
        this.showModalMerits = true;
    }

    closeModalMerits() {
        this.showModalMerits = false;
        this.allProcessMeritGroups.forEach(processMeritGroup => {
            processMeritGroup.isDropped = false;
        });
        this.meritDestinationDoc = new ProcessMerit({}, this.availableLanguages);
    }

    setDocumentsInAllProcessMeritGroups() {
        this.allProcessMeritGroups.forEach(meritGroup => {
            this.setMeritGroupIdToMeritSubgroups(meritGroup);
            this.setDocumentsOnMeritAttachedByUser(meritGroup);
        });
    }

    setMeritGroupIdToMeritSubgroups(meritGroup: ProcessMeritGroup) {
        const merits = meritGroup.getAllMerits();
        merits.forEach(merit => {
            if (merit.meritSubGroupId) {
                merit.meritGroupId = meritGroup.id;
            }
        });
    }

    setDocumentsOnMeritAttachedByUser(meritGroup: ProcessMeritGroup) {
        const merits = meritGroup.getAllMerits();
        this.meritGroupList.forEach(meritGroupListAttached => {
            const meritsAttached = meritGroupListAttached.getAllMerits();
            this.updateDocumentsOnMeritAttachedByUser(merits, meritsAttached);
        });
    }

    updateDocumentsOnMeritAttachedByUser(merits: ProcessMerit[], meritsAttached: ProcessMerit[]) {
        meritsAttached.forEach(meritAttached => {
            const indexMerit = merits.findIndex((merit: ProcessMerit) => merit.id === meritAttached.id);
            if (indexMerit !== -1) {
                merits[indexMerit].documents = _.cloneDeep(meritAttached.documents);
            }
        });
    }

    getDestinyMerits() {
        this.setDocumentsInAllProcessMeritGroups();
        this.meritGroupsToCopyFile = _.cloneDeep(this.allProcessMeritGroups);
        this.meritGroupsToCopyFile = this.meritGroupsToCopyFile.filter(meritGroup => {
            meritGroup.meritChilds = meritGroup.meritChilds.filter(child => {
                if (child instanceof ProcessMeritSubGroup) {
                    child.merits = child.merits.filter(merit => {
                        return merit.documents.every(doc => doc.fileName !== this.viewingFile.fileName)
                            && (merit.ratingType !== MeritRatingType.enum.INTERVIEW && !merit.allowDocument || merit.ratingType === MeritRatingType.enum.INTERVIEW && merit.allowDocument);
                    });
                    return child.merits.length > 0;
                }
                return child instanceof ProcessMerit
                    && child.documents.every(doc => doc.fileName !== this.viewingFile.fileName)
                    && (child.ratingType !== MeritRatingType.enum.INTERVIEW && !child.allowDocument || child.ratingType === MeritRatingType.enum.INTERVIEW && child.allowDocument);
            });
            return meritGroup.meritChilds.length > 0;
        });
    }

    setTabActive(languageActive: string) {
        this.tabActive = languageActive;
    }

    rejectNonAssesedMerits() {
        this.groupThatReachedMaxScore.rejectNonAssesedMerits();
        this.closeMaxScoreReachedModal();
    }

    openModalAddDoc() {
        this.showModalAddDoc = true;
    }

    closeModalAddDoc() {
        this.showModalAddDoc = false;
        this.cleanArrayDocs();
    }

    cleanArrayDocs() {
        this.formDataDoc = [];
        this.documentsToMerit = [];
    }

    addFile(file: any) {
        if (this.documentsToMerit.some((doc: InscriptionFile) => file.name === doc.fileName) || this.meritSelected.documents.some((doc: MeritOepDocument) => file.name === doc.fileName)) {
            return;
        }
        file.fileName = file.name;
        file.evaluableConditionType = FileTypes.enum.MERIT;
        file.meritGroupId = this.meritSelected.meritGroupId;
        file.meritSubGroupId = this.meritSelected.meritSubGroupId !== null ? this.meritSelected.meritSubGroupId : '';
        file.meritId = this.meritSelected.id;

        this.formDataDoc.push({
            inscriptionId: (this.inscriptionSelected as ProcessInscription).id,
            type: FileTypes.enum.MERIT,
            formFile: file,
            meritId: file.meritId,
            meritGroupId: file.meritGroupId,
            meritSubGroupId: file.meritSubGroupId
        });

        file = new InscriptionFile(file);
        file.allowRemove = true;
        this.documentsToMerit.push(file);
    }

    removeFile(document: InscriptionFile) {
        const index = this.documentsToMerit.indexOf(document);
        if (index >= 0) {
            this.documentsToMerit.splice(index, 1);
            this.formDataDoc.splice(index, 1);
        }
        return;
    }

    addFileToMerit() {
        const requests: any[] = [];
        this.formDataDoc.forEach(data => {
            requests.push(InscriptionService.addFileToInscription(data));
        });
        Promise.all(requests)
            .then(() => {
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                this.addFileOrCopy = true;
                this.getMerits((this.inscriptionSelected as ProcessInscription));
                this.showModalAddDoc = false;
                this.cleanArrayDocs();
            })
            .catch(error => console.log(error));
    }

    closeMaxScoreReachedModal() {
        this.showModalMaxScoreReached = false;
    }

    showhasBeenReachedMaxScoreModal(group: ProcessMeritGroup | ProcessMeritSubGroup) {
        this.showModalMaxScoreReached = true;
        this.groupThatReachedMaxScore = group;
    }

    openSlideOver() {
        if (this.showSlideOver) {
            if (this.processData.phase < ProcessPhases.enum.RANKING && this.scalingMeritDataChanged) {
                this.putMerits();
            }
            if (!this.isInvalidValidation) {
                this.$refs.sdSlideOver.setOverflowAutoToBody();
            } else {
                return;
            }
        }

        this.showSlideOver = !this.showSlideOver;
        this.$nextTick(() => {
            sdPanel.initialize();
            notification.initialize();
        });
    }

    initFiles(meritGroup) {
        this.loadingFile = true;
        if (!this.addFileOrCopy) {
            const firstMeritActive = meritGroup.firstChildMerit();
            firstMeritActive.active = true;
            this.meritSelected = firstMeritActive;
        }
        this.addFileOrCopy = false;
        this.setFilesToViewer(this.meritSelected.documents);
    }

    setFilesToViewer(documents: MeritOepDocument[]) {
        this.filesToViewer = documents;
        this.filesToViewer.length ? this.showInFileViewer(this.filesToViewer[Constants.process.ZERO]) : this.loadingFile = false;
    }

    changeStatusDocument(status: number) {
        this.viewingFile.status = status;
        if (!this.inscriptionSelected) {
            return;
        }
        InscriptionService.setStatusSelectedDocMerit(this.inscriptionSelected.id, this.viewingFile).then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.dataSave') as any);
        })
            .catch(error => console.log(error));
    }

    showUserOficioComment(merit: ProcessMerit) {
        if (!merit.userOficioComment) {
            return;
        }
        merit.isDropped = !merit.isDropped;
    }

    copyScore(merit: ProcessMerit, meritGroup: ProcessMeritGroup) {
        merit.oficialScore = merit.userScore ? merit.userScore : 0;
        merit.manageIsValid(this.availableLanguages);
        meritGroup.manageTotal();
        if (merit.meritSubGroupId) {
            const meritSubGroup = meritGroup.meritChilds.find(meritChild => meritChild.id === merit.meritSubGroupId);
            (meritSubGroup as ProcessMeritSubGroup).manageTotal();
        }
        this.$nextTick(() => {
            ValidationService.validateFormRequired(this.$refs.meritsStatusForm);
        });
    }

    closeModalRejectionReason() {
        const meritGroup = this.meritGroupList.find(groupMerit => groupMerit.id === this.processMeritClone.meritGroupId);
        (meritGroup as ProcessMeritGroup).findChildrenMeritGroup(this.processMeritClone);
        this.modalRejectionReason = false;
    }

    openModalRejectionReason(processMerit: ProcessMerit) {
        processMerit.active = false;
        this.processMeritClone = _.cloneDeep(processMerit);
        this.tabActiveClone = _.cloneDeep(this.tabActive);
        this.modalRejectionReason = true;
    }

    sortBy(keys: string[]) {
        this.sorter.updateKeys(keys);
    }

    downloadCSVMerit() {
        ProcessesService.getCSVMerits(this.$route.params.id, this.filteredExamId);
    }

    downloadCSVContactDataMerit() {
        ProcessesService.getCSVContactDataMerit(this.$route.params.id, this.filteredExamId);
    }

    downloadCSVMeritGroupsCourt() {
        ProcessesService.getCSVMeritGroupsCourt(this.$route.params.id, this.filteredExamId);
    }

    downloadCSVMeritGroupsAutoScore() {
        ProcessesService.getCSVMeritGroupsAutoScore(this.$route.params.id, this.filteredExamId);
    }

    downloadCSVMeritOficio() {
        ProcessesService.getCSVMeritsOficio(this.$route.params.id, this.filteredExamId);
    }

    setGroupActive(group) {
        if (this.meritGroupActive.id === group.id) {
            return;
        }

        if (group.status !== TabsStatusScaling.enum.DISABLED) {
            this.meritGroupList.forEach(element => {
                element.active = false;
                element.setstatus(this.availableLanguages);
            });
            group.active = true;
            (document.getElementById('scrollbar-group') as any).scrollTo({ top: 0 });
            this.meritGroupActive = group;
            this.meritGroupActive.setActiveAllMerits();
            this.viewingFile = null;
            this.initFiles(group);
        }
    }

    setMeritActive(merit) {
        this.meritGroupList.forEach(elementGroup => {
            elementGroup.meritChilds.forEach(children => {
                children.setActive();
            });
        });
        merit.active = true;
        if (this.meritSelected.id !== merit.id) {
            this.meritSelected = merit;
            this.viewingFile = null;
            this.setFilesToViewer(this.meritSelected.documents);
        } else {
            this.meritSelected = merit;
        }
    }

    getInscriptionsWithMerits() {
        return this.inscriptionList.filter((inscription: ProcessInscription) => inscription.scaleStatus !== ScaleStatus.enum.NOEVALUABLE);
    }

    navigateCandidate(isNext: boolean) {
        if (this.processData.phase < ProcessPhases.enum.RANKING && this.scalingMeritDataChanged) {
            this.putMerits();
        }
        if (this.isInvalidValidation) {
            return;
        }
        const indexCurrentInscription = this.getIndexInscription();
        const indexToNavigate = isNext ? indexCurrentInscription + 1 : indexCurrentInscription - 1;
        this.getMerits(this.inscriptionsWithMerits[indexToNavigate]);
    }

    getIndexInscription() {
        return this.inscriptionsWithMerits.findIndex((inscription: ProcessInscription) => inscription.id === (this.inscriptionSelected as ProcessInscription).id);
    }

    setFirstGroupActive() {
        const firstGroupActive = this.meritGroupList[this.meritGroupList.findIndex(element => element.meritChilds.length !== 0)];
        firstGroupActive.active = true;
        return firstGroupActive;
    }

    setGroupAndMeritActive() {
        this.meritGroupList.forEach(group => {
            if (group.id === this.meritGroupActive.id) {
                group.active = true;
                this.meritGroupActive = group;
                const meritSelected = this.meritGroupActive.getAllMerits().filter(merit => merit.id === this.meritSelected.id)[0];
                this.meritSelected = meritSelected;
                this.meritSelected.active = true;
            }
        });
    }

    setGroupActiveOrGroupAndMeritActive() {
        if (this.addFileOrCopy) {
            this.setGroupAndMeritActive();
            return;
        }
        this.meritGroupActive = this.setFirstGroupActive();
    }

    getMerits(inscription: ProcessInscription) {
        this.resetViewState();
        this.inscriptionSelected = inscription;

        if (!this.showSlideOver) {
            this.loadingSlideOver = true;
            this.openSlideOver();
        }

        InscriptionService.getInscriptionsMeritsData(inscription.id).then((response: any) => {
            this.handleInscriptionMeritsReponse(response);
        }).catch(error => {
            console.log(error);
        });
    }

    handleInscriptionMeritsReponse(response: any) {
        this.meritGroupList = response.map(mertiGroup => new ProcessMeritGroup(mertiGroup, this.availableLanguages));
        this.disableCandidateNavigation = false;
        this.setGroupActiveOrGroupAndMeritActive();
        this.initFiles(this.meritGroupActive);
        if (this.showSlideOver) {
            this.loadingSlideOver = false;
        }
    }

    resetViewState() {
        this.submitted = false;
        this.meritGroupList = [];
        this.filesToViewer = [] as MeritOepDocument[];
        this.viewingFile = null;
        this.loadingFile = true;
        this.disableCandidateNavigation = true;
    }

    putMerits() {
        this.submitted = true;
        this.meritGroupList.forEach(element => element.setstatus(this.availableLanguages));

        this.isInvalidValidation = this.$refs.meritsStatusForm.querySelectorAll(':invalid').length > 0 || this.$refs.meritsStatusForm.querySelectorAll('.error').length > 0 || !this.allGroupsValid();

        if (!this.waitingResponse && this.isInvalidValidation) {
            this.setGroupActive(this.meritGroupList[this.getFirstGroupInvalidIndex()]);
            return;
        }

        if (this.waitingResponse || !this.inscriptionSelected) {
            return;
        }

        this.waitingResponse = true;
        const meritGroupListToSend = this.meritGroupList.map(element => element.toServer());
        const objectToSend = { groups: meritGroupListToSend };
        InscriptionService.putInscriptionsManagementMertis(this.inscriptionSelected.id, objectToSend).then(
            () => {
                ToasterService.showSuccess(i18n.t('lang.toaster.dataSave') as any);
                this.initList(this.processData.filterExamId, true);
                this.waitingResponse = false;
                this.submitted = false;
                this.scalingMeritDataChanged = false;
                this.checkIsRankingTabBlocked();
                this.updateMeritGroupList();
            })
            .catch(error => this.waitingResponse = false);
    }

    updateMeritGroupList() {
        InscriptionService.getInscriptionsMeritsData((this.inscriptionSelected as ProcessInscription).id).then(
            response => {
                this.meritGroupList = response.map(mertiGroup => new ProcessMeritGroup(mertiGroup, this.availableLanguages));
                const currentGroupActive = this.meritGroupList.find(element => element.id === this.meritGroupActive.id);
                this.meritGroupActive = (currentGroupActive as ProcessMeritGroup);
                this.meritGroupActive.active = true;
                this.meritGroupActive.setActiveMeritById(this.meritSelected.id);
            });
    }

    allGroupsValid() {
        return this.getFirstGroupInvalidIndex() === -1;
    }

    getFirstGroupInvalidIndex() {
        return this.meritGroupList.findIndex(element => element.status === TabsStatusScaling.enum.ERROR);
    }

    downloadDocument(url: string) {
        location.href = url;
    }

    checkIsRankingTabBlocked() {
        (this.$parent as RatingsEdit).getIsRankBlocked();
    }

    getKeepAlive() {
        ClientService.getKeepAlive(this.clientInfo.getClientId())
            .then(() => { })
            .catch(error => console.log(error));
    }

    showInFileViewer(doc: MeritOepDocument) {
        this.getKeepAlive();
        DocumentService.viewFile(doc)
            .then(docUrl => {
                doc.url = window.URL.createObjectURL(docUrl);
                this.viewingFile = doc;
                this.loadingFile = false;
                this.$nextTick(() => {
                    sdPanel.initialize();
                });
                this.moveScroll(this.viewingFile.id);
            })
            .catch(error => {
                console.log(error);
            });
    }

    getAllMeritsIngroup(mertiGoupActive) {
        let merits: ProcessMerit[] = [];
        mertiGoupActive.meritChilds.forEach(children => {
            merits = merits.concat(children.getMeritInGroup());
        });

        return merits;
    }

    setStatusSelectedDocMerit(file: MeritOepDocument) {
        if (!this.inscriptionSelected) {
            return;
        }
        InscriptionService.setStatusSelectedDocMerit(this.inscriptionSelected.id, file).then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.dataSave') as any);
        })
            .catch(error => console.log(error));
    }

    getDataProcessScaleInscriptions($event: any, isUpdate?) {
        ProcessesService.getDataProcessScaleInscriptions(this.$route.params.id, $event).then(
            response => {
                if (!isUpdate) {
                    this.sorter.sortBy([]);
                }
                this.inscriptionList = response.map(inscription => new ProcessInscription(inscription, []));
                if (this.showOnlyApprovedCandidates) {
                    this.inscriptionList = this.inscriptionList.filter(inscription => inscription.passed);
                }
                this.loading = false;
                this.sortBy([this.sortKey]);
                this.inscriptionsWithMerits = this.getInscriptionsWithMerits();
                this.$emit('dataIsReady');
            })
            .catch(() => {
                this.loading = false;
                this.$emit('dataIsReady');
            });
    }

    initList($event: any, isUpdate?) {
        this.filteredExamId = this.processData.filterExamId !== null && this.processData.filterExamId !== '' ? $event : '';
        this.showApprovedCandidates(this.processData.showApprovedCandidates);
        this.getDataProcessScaleInscriptions(this.filteredExamId, isUpdate);
    }

    showApprovedCandidates(showApprovedCandidates: boolean) {
        this.showOnlyApprovedCandidates = showApprovedCandidates;
    }

    getDataProcess() {
        if (this.inscriptionSelected) {
            this.meritGroupList = [];
            this.inscriptionSelected = null;
        }
        ProcessesService.getDataProcess(this.$route.params.id).then((response: any) => {
            this.processData = response;
            this.initList(this.processData.filterExamId);
            this.$nextTick(() => {
                sdPanel.initialize();
            });
        }).catch(error => console.log(error));
    }

    initClientInfo() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => {
                this.clientInfo = new ClientInfo(responseMasterData);
            })
            .catch(error => console.log(error));
    }

    changeVisibilityMeritIncidence() {
        const showMeritIncidences = { showMeritIncidences: this.processData.showMeritIncidences };
        ProcessesService.updateVisibilityMeritIncidence(this.$route.params.id, showMeritIncidences)
            .then(() => {
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            })
            .catch(() => {
                this.processData.showMeritIncidences = !this.processData.showMeritIncidences;
            });
    }

    copyFilterExam() {
        this.showModalFilterExams = true;
        this.copyExamsList = _.cloneDeep(this.examsList);
        this.copyFilterActiveId = this.filterActiveId;
    }

    getDataProcessExamsInscriptions() {
        ProcessesService.getDataProcessExamsInscriptions(this.$route.params.id).then(
            response => {
                this.filterActiveId = this.processData.filterExamId !== null ? this.processData.filterExamId : null;
                this.examsList = response.map(exam => ({ examId: exam.id, description: exam.description[this.currentLanguage], active: false, disabled: exam.status !== StatusExam.enum.GRADED }));
                this.showFilterSelected = this.filterActiveId;
                if (this.filterActiveId) {
                    this.examsList.unshift({ examId: '', description: i18n.t('lang.shared.allAdmitted'), active: false });
                    const element = this.examsList.find(exam => exam.examId === this.filterActiveId);
                    const indexExam = this.examsList.indexOf(element);
                    this.examsList[indexExam].active = true;
                } else {
                    this.examsList.unshift({ examId: '', description: i18n.t('lang.shared.allAdmitted'), active: true });
                }
            }).catch(error => console.log(error));
    }

    callFilterWithAllAdmitted() {
        this.setFilterMeritsByExam(this.examsList[0]);
        this.callFilter();
    }

    setFilterMeritsByExam(exam) {
        if (exam.disabled) {
            return;
        }
        this.examsList.forEach(element => element.active = false);
        exam.active = true;
        this.filterActiveId = exam.examId;
        this.showOnlyApprovedCandidates = this.filterActiveId === '' ? false : true;
    }

    setDefaultFilter() {
        this.examsList = _.cloneDeep(this.copyExamsList);
        this.filterActiveId = this.copyFilterActiveId;
        this.showOnlyApprovedCandidates = this.processData.showApprovedCandidates;
    }

    callFilter() {
        const objectToSend = this.filterActiveId !== '' ? { filterExamId: this.filterActiveId, showApprovedCandidates: this.showOnlyApprovedCandidates } : { filterExamId: null, showApprovedCandidates: false };
        ProcessesService.filterProcessByExamId(this.$route.params.id, objectToSend)
            .then(response => {
                this.getDataProcess();
                this.showModalFilterExams = false;
                this.showFilterSelected = objectToSend.filterExamId;
            }).catch(error => console.log(error));
    }

    moveScroll(scrollBoxIndex: string) {
        this.$nextTick(() => {
            const elementScrollable = document.getElementById('document_' + scrollBoxIndex);
            if (elementScrollable !== null) {
                this.$refs.refScrollbar.scrollTo({ left: elementScrollable.offsetLeft, behavior: 'smooth' });
            }
        });
    }

    getAllMeritGroupsInProcess() {
        ProcessesService.getInscriptionStructure(this.$route.params.id).then((response: any) => {
            const meritGroupType: number = 3;
            const meritsGroups = response.structure.find(group => group.type === meritGroupType);
            this.allProcessMeritGroups = meritsGroups.steps.map(meritGroup => new ProcessMeritGroup(meritGroup, this.availableLanguages));
        }).catch(error => console.log(error));
    }

    dataInScalingMeritChanged() {
        this.scalingMeritDataChanged = true;
    }

    prepareScalingView() {
        this.initClientInfo();
        this.tabActive = i18n.locale;
        this.getDataProcess();
        this.getDataProcessExamsInscriptions();
        this.getAllMeritGroupsInProcess();
    }

    goToInscriptionEdit(inscription: ProcessInscription) {
        this.$router.push(
            {
                name: 'InscriptionEdit',
                params:
                {
                    id: this.$route.params.id,
                    inscriptionId: inscription.id,
                    from: 'ScalingEdit', 
                    title: 'lang.shared.mertis',
                    tabActive: RatingsTabs.enum.MERTIS
                }
            });
    }
    
    mounted() {
        this.prepareScalingView();
        if (this.candidateInfo) {
            this.searcherFilter.filterString = this.candidateInfo.nif;
        }
    }
}
