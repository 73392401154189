import { InscriptionData, InscriptionFile, MeritGroup } from '@/Domain/Entities';
import { BaseMeritsType, Constants, FileTypes, InscriptionModules, InscriptionStatus, MeritGroupType, MeritRatingType, ProcessPhases } from '@/Domain/enum';
import Component from 'vue-class-component';
import * as dropdown from 'saviafront/lib/js/compiled/dropdown';
import { Prop, Vue } from 'vue-property-decorator';
import { FileImporter, MdTextarea } from '@/Components';

@Component({
  components: {
    FileImporter, MdTextarea
  }
})

export default class InscriptionMerits extends Vue {
  @Prop() meritGroups!: MeritGroup[];
  @Prop() currentLanguage!: string;
  @Prop() inscriptionStatus!: number;
  @Prop() meritDocuments!: InscriptionFile[];
  @Prop() inscriptionData!: InscriptionData;
  @Prop() processData!: any;
  @Prop() totalPoints!: number;
  @Prop() moduleToEdit!: number;
  
  MeritGroupType = MeritGroupType;
  MeritRatingType = MeritRatingType;
  InscriptionStatus = InscriptionStatus;
  ProcessPhases = ProcessPhases;
  InscriptionModules = InscriptionModules;
  Constants = Constants;
  BaseMeritsType = BaseMeritsType;
  FileTypes = FileTypes;

  $refs!: {
    dropdownMerit: HTMLElement[];
  };

  checkDocumentsInMerit(merit) {
    return this.meritDocuments.find(element => element.meritId === merit.id);
  }

  onUserScoreBlur(merit: any) {
    merit.calcTotalScore(this.meritDocuments); 
    this.$emit('validationErrorMerits');
  }

  onMeritEdit(merit) {
    this.$emit('editMerit', merit);
  }

  onRemoveFile(document: InscriptionFile) {
    this.$emit('removeFile', document);
  }

  onDownload(document: InscriptionFile) {
    this.$emit('download', document);
  }

  onAddFile(event, id, meritGrouId, meritSubGroupId) {
    this.$emit('addFile', event, this.FileTypes.enum.MERIT, null, id, meritGrouId, null, meritSubGroupId);
  }

  onChangeOficio(merit) {
    this.$emit('changeOficio', merit);
  }

  onClickAddMerit(meritGroup: MeritGroup) {
    this.$emit('onClickAddMerit', meritGroup);
  }

  initializeDropdown() {
    this.$nextTick(() => {
      if (this.$refs.dropdownMerit && this.$refs.dropdownMerit.length) {
        dropdown.initialize(this.$refs.dropdownMerit);
      }
    });
  }

  mounted() {
    this.initializeDropdown();
  }
}
