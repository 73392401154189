import { CandidateMoveOptionLabel, CandidateMoveOption, Constants } from '@/Domain/enum';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MoveReplacementCandidate extends Vue {
    @Prop() maxOrder!: number;
    @Prop() minOrder!: number;
    @Prop() candidatePosition!: number;
    @Prop() replacementListModified!: boolean;
    @Prop() confirmChangeIsModify!: boolean;

    CandidateMoveOption = CandidateMoveOption;
    moveOptionSelected: number | null = null;
    orderToMove: number | null = null;
    moveReason: string = '';
    moveReasonLength = Constants.sharedLengths.ONE_THOUSAND;
    showInputError: boolean = false;

    get isMoveButtonDisabled() {
        return this.isMoveOptionAndOrderUnsetted() 
            || this.isMoveToOrderAndOrderUnsetted()
            || this.isOrderOutRange() 
            || this.isOrderToMoveTheCandidatePosition()
            || !this.confirmChangeIsModify;
    }

    get isMoveToPositionSelected() {
        return this.moveOptionSelected === CandidateMoveOption.MOVE_TO_ORDER 
            || (this.moveOptionSelected === CandidateMoveOption.MOVE_TO_END && this.getSdRadioOptions().length === 1);
    }

    get isMoveToEndSelected() {
        return this.moveOptionSelected === CandidateMoveOption.MOVE_TO_END 
            && this.getSdRadioOptions().length > 1;
    }

    get isMoveOptionDefined() {
        return this.moveOptionSelected !== null;
    }

    get showModifyListAdvice() {
        return this.isMoveOptionDefined && !this.replacementListModified;
    }


    moveCandidate() {
        if (!this.confirmChangeIsModify && !this.replacementListModified) {
            return;
        }
        this.$emit('moveCandidate', 
            {
                moveOption: this.candidatePosition === this.maxOrder ? CandidateMoveOption.MOVE_TO_ORDER : this.moveOptionSelected, 
                reason: this.moveReason, 
                order: this.orderToMove
            }
        );
    }

    closeModal() {
        this.$emit('closeModal');
    }

    isMoveOptionAndOrderUnsetted() {
        return this.moveOptionSelected === null 
        || (this.moveOptionSelected === CandidateMoveOption.MOVE_TO_ORDER 
        && !this.orderToMove);
    }

    isMoveToOrderAndOrderUnsetted() {
        return this.isMoveToPositionSelected && !this.orderToMove;
    }

    isOrderOutRange() {
        return this.orderToMove! && (this.orderToMove! > this.maxOrder
        || this.orderToMove! < this.minOrder);
    }

    isOrderToMoveTheCandidatePosition() {
        return this.orderToMove! === this.candidatePosition;
    }

    isValidOrderInput() {
        if (this.isOrderToMoveTheCandidatePosition()) {
            this.showInputError = true;
            return;
        }
    }

    clearErrors() {
        this.showInputError = false;
    }

    getSdRadioOptions() {
        return this.candidatePosition === this.maxOrder ? [
            {text: CandidateMoveOptionLabel[1], value: 0}
        ] : [
            {text: CandidateMoveOptionLabel[0], value: CandidateMoveOption.MOVE_TO_END},
            {text: CandidateMoveOptionLabel[1], value: CandidateMoveOption.MOVE_TO_ORDER}
        ];
    }

}
