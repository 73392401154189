import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdTextarea, MdSelect, Toggle } from '@/Components';
import { FormMode, Languages, ProcessStatus, ProcessPhases, Constants } from '@/Domain/enum';
import ValidationService from '@/Application/Services/ValidationService';
import { mapState, mapGetters } from 'vuex';
import {  MeritSubGroup } from '@/Domain/Entities';
import _ from 'lodash';
import MeritSubGroupService from '@/Services/MeritSubGroupService';

@Component({
    components: {
        MdTextarea,
        MdSelect,
        Toggle
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    }
})

export default class EditNewMeritSubGroup extends Vue {
    @Prop() info: any;
    @Prop() value: any;
    @Prop() mode: any;
    @Prop() process: any;
    @Prop() isUsed?: boolean;
    @Prop() baseMeritsType?: number;

    FormMode = FormMode;
    Languages = Languages;
    ProcessStatus = ProcessStatus;
    ProcessPhases = ProcessPhases;
    Constants = Constants;
    objectToManage = _.cloneDeep(this.info);
    submitted = false;
    tabActive: string = '';

    currentLanguage!: string;
    availableLanguages!: string[];

    $refs!: {
        newMeritSubGroupForm: HTMLFormElement,
        editMeritSubGroupForm: HTMLFormElement
    };

    save() {
        this.submitted = true;
        const isValidRequiredForm = this.$props.mode === FormMode.enum.EDITING ? ValidationService.validateFormRequired(this.$refs.editMeritSubGroupForm) : ValidationService.validateFormRequired(this.$refs.newMeritSubGroupForm);
        if (!isValidRequiredForm) {
            return;
        }
        if (this.process.editingMeritGroup.maxScore && !this.isValidScoreSubGroup()) {
            this.$emit('subGroupMaxScoreParentError');
            return;
        }
        if (this.objectToManage.maxScore && this.isInvalidScoreChildren()) {
            this.$emit('meritSubGroupChildrenError');
            return;
        }
        if (this.$props.mode === FormMode.enum.EDITING) {
            this.process.isSaving = true;
            MeritSubGroupService.editMeritSubGroup(this.process.id, this.objectToManage.infoToServer())
                .then(response => {
                    response.merits = _.orderBy(response.merits, 'order');
                    this.process.editingMeritGroup.editingMeritSubGroup.setPropsAfterEdit(response);
                    const indexSubgroup = this.process.meritGroups.find(element => element.id === this.process.editingMeritGroup.id).meritChilds.findIndex(element => element.id === this.process.editingMeritGroup.editingMeritSubGroup.id);
                    this.process.editingMeritGroup.meritChilds[indexSubgroup].setPropsAfterEdit(response);
                    this.process.meritGroups.find(element => element.id === this.process.editingMeritGroup.id).meritChilds[indexSubgroup].setPropsAfterEdit(response);
                    this.objectToManage = {};
                    this.info.isEditing = false;
                    this.process.isSaving = false;
                    this.$emit('editedMeritSubGroup');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        } else if (this.$props.mode === FormMode.enum.CREATING) {
            this.objectToManage.meritGroupId = this.process.editingMeritGroup.id;
            this.process.isSaving = true;
            MeritSubGroupService.addMeritSubGroup(this.process.id, new MeritSubGroup(this.objectToManage).infoToServer())
                .then(response => {
                    this.process.editingMeritGroup.meritChilds.push(new MeritSubGroup(response));
                    this.objectToManage = {};
                    this.process.isSaving = false;
                    this.$emit('createdMeritSubGroup');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        }
    }

    isValidScoreSubGroup() {
        return this.process.editingMeritGroup.maxScore >= Number(this.objectToManage.maxScore);
    }
    
    isInvalidScoreChildren() {
        if (this.process.editingMeritGroup.editingMeritSubGroup) {
            return this.process.editingMeritGroup.editingMeritSubGroup.merits.some( element => {
                return element.maxScore > Number(this.objectToManage.maxScore);
            });
        }
    }

    cancel() {
        this.objectToManage = {};
        this.info.isEditing = false;
        this.$emit('canceledMeritSubGroup');
    }

    created() {
        this.tabActive = this.currentLanguage;
    }
}
