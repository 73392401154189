import { IReplacementMerge } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeBase';
import Replacement from '../Replacement/Replacement';
import { IReplacementMergeListWithListProcess, IReplacementMergeSourceListWithList } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeSource';
import { OppositionType, ProcessType, ReplacementOriginType } from '@/Domain/enum';
import { IReplacementMergeEditCommand, IReplacementMergeWithProcessCommand, IReplacementMergeWithReplacementCommand } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeTypes';
import { ReplacementListMergeOptions } from '@/Domain/enum/ReplacementListMergeOptions';
import MultiLanguage from '../MultiLanguage';
import WizardMerge from './WizardMerge';
import { ReplacementMergeProcess, ReplacementMergeReplacementList } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeElement';
import { IProvisionalReplacementCandidate } from '@/Domain/interfaces/IProvisionalReplacementCandidate';

export default class ReplacementMergeService {

    static getReplacementMergeWithReplacementCommand(wizardMerge: WizardMerge): IReplacementMergeWithReplacementCommand {
        return {
            ...wizardMerge.replacementMerge,
            replacementListBId: wizardMerge.replacementMerge.elementToMergeBId
        };
    }

    static getReplacementMergeWithProcessCommand(wizardMerge): IReplacementMergeWithProcessCommand {
        return {
            ...wizardMerge.replacementMerge,
            processBId: wizardMerge.replacementMerge.elementToMergeBId
        };
    }

    static mapCommandFromReplacement(replacement: Replacement): IReplacementMerge {
        return {
            name: replacement.name,
            creationDate: replacement.creationDate,
            validityDate: replacement.validityDate,
            replacementListAId: replacement.replacementListSourceMerge.replacementListId,
            elementToMergeBId: (replacement.replacementListSourceMerge as IReplacementMergeSourceListWithList).replacementListBId ? (replacement.replacementListSourceMerge as IReplacementMergeSourceListWithList).replacementListBId : (replacement.replacementListSourceMerge as IReplacementMergeListWithListProcess).processBId,
            candidatesIds: replacement.selectedReplacementListCandidateIds,
            mergeOptionSelected: replacement.originType === ReplacementOriginType.MERGED_WITH_LIST ? ReplacementListMergeOptions.LIST_WITH_LIST : ReplacementListMergeOptions.LIST_WITH_PROCESS,
            orderCriteria: replacement.replacementListSourceMerge.orderCriteria
        };
    }

    static mapReplacementMergeReplacementList(replacement: { id: string, name: string, process: { type: number, title: MultiLanguage }, originType: number, totalCandidates: number }): ReplacementMergeReplacementList {
        return {
            id: replacement.id,
            title: replacement.name,
            processTitle: replacement.originType === ReplacementOriginType.PROCESS ? replacement.process.title : new MultiLanguage({}),
            processType: replacement.originType === ReplacementOriginType.PROCESS ? ProcessType.translations[replacement.process.type] : '',
            totalCandidates: replacement.totalCandidates,
            originType: replacement.originType
        };
    }

    static mapReplacementMergeProcessItem(process: { id: string, title: MultiLanguage, type: number, oppositionType: number, year: number, candidateCount: number }): ReplacementMergeProcess {
        return {
            id: process.id,
            title: new MultiLanguage(process.title),
            type: ProcessType.translations[process.type],
            oppositionType: OppositionType.translations[process.oppositionType],
            year: process.year.toString(),
            totalCandidates: process.candidateCount
        };
    }

    static mapReplacementMergetoReplacementMergeEdit(objectToMap: { wizardMerge: WizardMerge, replacementListId: string, candidateEdition: boolean}): IReplacementMergeEditCommand {
        return {
            ...objectToMap.wizardMerge.replacementMerge,
            replacementListId: objectToMap.replacementListId,
            replacementListBOrProcessBId: objectToMap.wizardMerge.replacementMerge.elementToMergeBId,
            candidateEdition: objectToMap.candidateEdition
        };
    }

    static mapReplacementMergedCandidatesToIds(objectToMap: { replacementMergedCandidates: IProvisionalReplacementCandidate[], wizardMerge: WizardMerge }): string[] {
        return objectToMap.replacementMergedCandidates
            .filter(candidate => objectToMap.wizardMerge.replacementMerge.candidatesIds.includes(candidate.nif))
            .map(candidate => candidate.replacementListCandidateId);
    }
}
