import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { MultiLanguage, ProcessConfigGroup, ReplacementMergeService, SorterNew, TabProvisional, TabsList, WizardMerge, ReplacementMergeCandidateSelectionHandler, ReplacementMergeEditUseCase, ReplacementMergeCreateUseCase } from '@/Domain/Entities';
import { ReplacementListMergeOptions, ReplacementListMergeOptionsLabel } from '@/Domain/enum/ReplacementListMergeOptions';
import { MergeSteps, ProcessType, ReplacementOriginType, AddReplacementListCandidateCriteriaTranslations, InscriptionTurn } from '@/Domain/enum';
import { IProvisionalReplacementCandidate } from '@/Domain/interfaces/IProvisionalReplacementCandidate';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import { ReplacementMergeProcess, ReplacementMergeReplacementList } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeElement';

enum ReplacementMergeSorterKeys {
  ORDER = 'order',
  NIF = 'nif',
  FULL_NAME = 'fullName',
  DISABILITY = 'hasDisability',
  APPROVED_EXAMS = 'approvedExams',
  EXAMS = 'totalGradeScore',
  MERITS = 'merits',
  TOTAL = 'totalScore'
}
@Component({
  computed: {
    ...mapState('languagesStore', { currentLanguage: 'current' }),
    ...mapState('dateFormatStore', { dateFormat: 'dateFormat' }),
    ...mapGetters('processConfigStore', { processConfigGroups: 'getProcessConfigGroups' })
  }
})
export default class ReplacementMerge extends Vue {
  currentLanguage!: string;
  wizardMerge = new WizardMerge();
  listReplacementListA: ReplacementMergeReplacementList[] = [];
  replacementElementB: Array<ReplacementMergeProcess | ReplacementMergeReplacementList> = [];
  listReplacementListACopy: ReplacementMergeReplacementList[] = this.listReplacementListA;
  replacementElementBCopy: Array<ReplacementMergeProcess | ReplacementMergeReplacementList> = this.replacementElementB;
  searchTermA: string = '';
  searchTermB: string = '';
  searchTermProvisionaList: string = '';
  submitted: boolean = false;
  loading: boolean = false;
  turnList: TabsList<TabProvisional> = new TabsList(TabProvisional, []);
  replacementMergedCandidates: IProvisionalReplacementCandidate[] = [];
  provisionalMergedCandidatesOnTurn: IProvisionalReplacementCandidate[] = [];
  sorter = new SorterNew([ReplacementMergeSorterKeys.ORDER], ['asc', 'desc']);
  replacementTableSelectionHandler!: ReplacementMergeCandidateSelectionHandler;
  totalStepsCount: number = MergeSteps.FOURTH;
  isEditting: boolean = false;
  isModify: boolean = false;
  originalMergedCandidates: string[] = [];

  moment = moment;
  replacementListMergeOptions = ReplacementListMergeOptions;
  processConfigGroups!: ProcessConfigGroup[];
  ProcessType = ProcessType;
  ReplacementOriginType = ReplacementOriginType;
  MergeSteps = MergeSteps;
  InscriptionTurn = InscriptionTurn;
  ReplacementMergeSorterKeys = ReplacementMergeSorterKeys;

  addReplacementListCandidateCriteriaOptions = Object.values(AddReplacementListCandidateCriteriaTranslations).map((option, index) => ({ text: option, value: index }));
  sdRadioMergeOptions = Object.values(ReplacementListMergeOptionsLabel).map((option, index) => ({ text: option, value: index }));

  $refs!: {
    replacementMergeForm: HTMLFormElement
    radio: HTMLFormElement
  };

  get isDisabledNextStep() {
    return (this.isEditting && this.wizardMerge.isInvalidUpdate())
      || this.wizardMerge.isInvalidStepOne()
      || this.wizardMerge.isInvalidStepTwo()
      || this.wizardMerge.isInvalidStepThree()
      || this.wizardMerge.isInvalidStepFour();
  }

  get selectedListAName() {
    return this.wizardMerge.currentStep > MergeSteps.SECOND
      && this.wizardMerge.replacementMerge.replacementListAId
      && this.listReplacementListACopy.find(replacement => replacement.id === this.wizardMerge.replacementMerge.replacementListAId)!.title;
  }

  get selectedListBName() {
    return this.wizardMerge.currentStep > MergeSteps.SECOND
      && this.wizardMerge.replacementMerge.elementToMergeBId
      && this.replacementElementBCopy.find(replacement => replacement.id === this.wizardMerge.replacementMerge.elementToMergeBId)!.title;
  }

  get isVisiblePreviousStepButton() {
    return (!this.isEditting && this.wizardMerge.currentStep > MergeSteps.FIRST)
      || (this.isEditting && this.wizardMerge.currentStep > MergeSteps.SECOND && this.wizardMerge.currentStep <= this.totalStepsCount);
  }

  get turnActive() {
    return this.turnList && this.turnList.activeTab ? this.turnList.activeTab : null;
  }

  get orderedProvisionalMergedCandidatesOnTurn() {
    return this.sorter.sortBy(this.provisionalMergedCandidatesOnTurn);
  }

  get thereAreReplacements() {
    return this.listReplacementListA.length > 0 && this.listReplacementListACopy.length > 0;
  }

  get candidatesIdsOnTurn() {
    return this.isEditting ?
      this.provisionalMergedCandidatesOnTurn
        .filter(candidate => candidate.turn === this.turnList.activeTab!.type)
        .map(candidate => candidate.nif)
      :
      this.provisionalMergedCandidatesOnTurn
        .filter(candidate => candidate.turn === this.turnList.activeTab!.type)
        .map(candidate => candidate.replacementListCandidateId);
  }

  get showStepCounter() {
    return this.wizardMerge.currentStep <= this.totalStepsCount;
  }

  get showUpdateButton() {
    return this.isEditting && this.wizardMerge.currentStep === this.totalStepsCount;
  }

  get showFinishButton() {
    return !this.isEditting && this.wizardMerge.currentStep >= this.totalStepsCount;
  }

  get showNextStepButton() {
    return this.wizardMerge.currentStep < this.totalStepsCount;
  }
  
  onTurnChange(turn: TabProvisional) {
    this.resetFilters();
    this.turnList.selectTab(turn);
    this.provisionalMergedCandidatesOnTurn = this.replacementMergedCandidates.filter(candidate => candidate.turn === turn.type);
    if (this.turnList.activeTab) {
      this.turnList.activeTab!.filter.isSelectedAll = this.checkIfAllCandidatesAreSelected();
    }
  }

  resetFilters() {
    this.searchTermProvisionaList = '';
  }

  onFilterAChange() {
    this.listReplacementListACopy = this.listReplacementListA.filter(replacement => {
      const title = replacement.title instanceof MultiLanguage ? replacement.title[this.currentLanguage] : replacement.title;
      return title.toLowerCase().includes(this.searchTermA.toLowerCase());
    });
  }

  onFilterBChange() {
    this.replacementElementBCopy = this.replacementElementB.filter(replacement => {
      const title = replacement.title instanceof MultiLanguage ? replacement.title[this.currentLanguage] : replacement.title;
      return title.toLowerCase().includes(this.searchTermB.toLowerCase());
    });
  }

  onProvisionalReplacementSearcherChanges() {
    this.provisionalMergedCandidatesOnTurn = this.replacementMergedCandidates.filter(candidate => candidate.turn === this.turnList.activeTab!.type && candidate.name.toLowerCase().includes(this.searchTermProvisionaList.toLowerCase()));
  }

  onProvisionalReplacementAllSelectorChanges() {
    this.resetFilters();
    this.provisionalMergedCandidatesOnTurn = this.replacementMergedCandidates.filter(candidate => candidate.turn === this.turnList.activeTab!.type);
    this.updateCandidateOnViewMassively();
  }

  checkIfAllCandidatesAreSelected() {
    return this.candidatesIdsOnTurn.every(id => this.wizardMerge.replacementMerge.candidatesIds.includes(id));
  }

  updateCandidateOnViewMassively() {
    this.provisionalMergedCandidatesOnTurn.forEach(candidate => {
      candidate.selected = this.turnList.activeTab!.filter.isSelectedAll;
      const candidateIdentification = this.isEditting ? candidate.nif : candidate.replacementListCandidateId;
      this.replacementTableSelectionHandler.selectCandidate({ selected: candidate.selected, id: candidateIdentification }, this.wizardMerge.replacementMerge.candidatesIds);
    });
  }

  onCandidateSelectedChange(candidate: IProvisionalReplacementCandidate) {
    const candidateIdentification = this.isEditting ? candidate.nif : candidate.replacementListCandidateId;
    this.replacementTableSelectionHandler.selectCandidate({ selected: candidate.selected, id: candidateIdentification }, this.wizardMerge.replacementMerge.candidatesIds);
    if (this.turnList.activeTab) {
      this.turnList.activeTab!.filter.isSelectedAll = this.checkIfAllCandidatesAreSelected();
    }
  }

  async goToNextStep() {
    this.wizardMerge.currentStep++;

    if (this.isEditting) {
      await this.manageEditSteps();
      return;
    }
    this.manageCreateSteps();
  }

  goToPreviousStep() {
    this.wizardMerge.currentStep--;
  }

  async manageEditSteps() {
    const isThirdStep = this.wizardMerge.currentStep === MergeSteps.THIRD;
    const isBeyondThirdStep = this.wizardMerge.currentStep > MergeSteps.THIRD;
    const shouldEdit = ((this.isModify && isThirdStep) || (!this.isModify && isBeyondThirdStep));
    try {
      this.loading = true;
      if (shouldEdit) {
        this.editReplacementMergedList();
        return;
      }

      if (!this.isModify && isThirdStep) {
        this.getProvisionalCandidatesOfEditedReplaementMerge();
        return;
      }

    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async editReplacementMergedList() {
    await ReplacementMergeEditUseCase.editReplacementMergedList({ wizardMerge: this.wizardMerge, replacementListId: this.$route.params.id, originalMergedCandidates: this.originalMergedCandidates, replacementMergedCandidates: this.replacementMergedCandidates });
    this.goToReplacementListDetail();
    ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as string);
  }

  async getProvisionalCandidatesOfEditedReplaementMerge() {
    const { replacementMergedCandidates, originalMergedCandidates } = await ReplacementMergeEditUseCase.getProvisionalCandidatesOfEditedReplaementMerge({ wizardMerge: this.wizardMerge, replacementListId: this.$route.params.id, replacementMergedCandidates: this.replacementMergedCandidates, originalMergedCandidates: this.originalMergedCandidates });

    this.originalMergedCandidates = originalMergedCandidates;
    this.wizardMerge.replacementMerge.candidatesIds = originalMergedCandidates;
    this.replacementMergedCandidates = replacementMergedCandidates;

    this.turnList = new TabsList(TabProvisional, this.getTurnsOnProvisionalReplacement());
    this.replacementTableSelectionHandler = new ReplacementMergeCandidateSelectionHandler(this.turnList as any);
    this.matchSelectedCandidates();
  }

  manageCreateSteps() {
    if (this.wizardMerge.currentStep === MergeSteps.SECOND) {
      this.handleSecondStep();
      return;
    }

    if (this.wizardMerge.currentStep === MergeSteps.FOURTH) {
      this.handleFourthStep();
      return;
    }

    if (this.wizardMerge.currentStep > MergeSteps.FOURTH) {
      this.handlecreateReplacementMergedList();
      return;
    }
  }

  async handleFourthStep() {
    try {
      this.replacementMergedCandidates = await ReplacementMergeCreateUseCase.getProvisionalReplacementMergedListCandidates(this.wizardMerge);

      this.turnList = new TabsList(TabProvisional, this.getTurnsOnProvisionalReplacement());
      this.replacementTableSelectionHandler = new ReplacementMergeCandidateSelectionHandler(this.turnList as any);

      this.provisionalMergedCandidatesOnTurn = this.replacementMergedCandidates.filter(candidate => candidate.turn === this.turnList.activeTab!.type);
    } catch (error) {
      console.error(error);
    }
  }

  matchSelectedCandidates() {
    this.replacementMergedCandidates.forEach(candidate => candidate.selected = this.wizardMerge.replacementMerge.candidatesIds.includes(candidate.nif));
    this.replacementTableSelectionHandler.initAllTurnsCount(this.replacementMergedCandidates.filter(candidate => candidate.selected).map(candidate => ({ turn: candidate.turn })));
    this.provisionalMergedCandidatesOnTurn = this.replacementMergedCandidates.filter(candidate => candidate.turn === this.turnList.activeTab!.type);
    if (this.turnList.activeTab) {
      this.turnList.activeTab!.filter.isSelectedAll = this.checkIfAllCandidatesAreSelected();
    }
  }

  async handleSecondStep() {
    this.loading = true;
    try {
      const { listReplacementListA, replacementElementB } = await ReplacementMergeCreateUseCase.getMergeItemsDataRequest(this.wizardMerge.replacementMerge.mergeOptionSelected);
      this.listReplacementListA = listReplacementListA;
      this.listReplacementListACopy = [...this.listReplacementListA];
      this.replacementElementB = replacementElementB;
      this.replacementElementBCopy = [...this.replacementElementB];
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  handleOnClickElementA(id: string) {
    if (this.isEditting || id === this.wizardMerge.replacementMerge.elementToMergeBId) {
      return;
    }
    this.wizardMerge.replacementMerge.replacementListAId = id;
  }

  handleOnClickElementB(id: string) {
    if (this.isEditting || id === this.wizardMerge.replacementMerge.replacementListAId) {
      return;
    }
    this.wizardMerge.replacementMerge.elementToMergeBId = id;
  }

  getTurnsOnProvisionalReplacement() {
    const turns: Array<{ count: number, inListCount: number, turn: number }> = [];
    const candidatesOnEveryTurn = this.replacementMergedCandidates.reduce((acc, item) => {
      acc[item.turn] = (acc[item.turn] || 0) + 1;
      return acc;
    }, {} as { [key: number]: number });

    for (const [turn, count] of Object.entries(candidatesOnEveryTurn)) {
      turns.push({
        count,
        inListCount: 0,
        turn: Number(turn)
      });
    }

    return turns;
  }

  async handlecreateReplacementMergedList() {
    this.loading = true;
    try {
      await ReplacementMergeCreateUseCase.createReplacementMergedList(this.wizardMerge);
      ToasterService.showSuccess(i18n.t('lang.toaster.successReplacementListLoad') as string);
      this.goToReplacementList();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  goToReplacementList() {
    this.$router.push({
      name: 'ReplacementList'
    });
  }

  goToReplacementListDetail() {
    this.$router.push({
      name: 'ListReplacementList',
      params: {
        replacementId: this.$route.params.id
      }
    });
  }

  async manageIfIsEditting() {
    if (this.$route.params.id && this.$route.params.replacement) {
      this.wizardMerge.replacementMerge = ReplacementMergeService.mapCommandFromReplacement(this.$route.params.replacement as any);
      this.isModify = Object(this.$route.params.replacement).isModify;
      this.totalStepsCount = this.isModify ? MergeSteps.SECOND : MergeSteps.THIRD;
      this.wizardMerge.currentStep = MergeSteps.SECOND;
      this.isEditting = true;

      const data = await ReplacementMergeEditUseCase.getSourcesListsDetails(this.wizardMerge.replacementMerge);

      if (data && data.replacementListA) {
        this.listReplacementListA.push(data.replacementListA);
        this.listReplacementListACopy = [...this.listReplacementListA];
      }

      if (data && data.elementToMergeB) {
        this.replacementElementB.push(data.elementToMergeB);
        this.replacementElementBCopy = [...this.replacementElementB];
      }
    }
  }

  mounted() {
    this.manageIfIsEditting();
  }
}
