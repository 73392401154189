import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdTextarea, MdSelect } from '@/Components';
import { FormMode, MeritGroupType, Languages, ProcessStatus, ProcessPhases, Constants } from '@/Domain/enum';
import ValidationService from '@/Application/Services/ValidationService';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import MeritGroupsService from '@/Services/MeritGroupsService';
import { MeritGroup } from '@/Domain/Entities';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

@Component({
    components: {
        MdTextarea,
        MdSelect
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    }
})

export default class EditNewGroupMerit extends Vue {
    @Prop() info: any;
    @Prop() value: any;
    @Prop() mode: any;
    @Prop() isUsed?: boolean;
    @Prop() process: any;

    currentLanguage!: string;
    availableLanguages!: string[];

    FormMode = FormMode;
    MeritGroupType = MeritGroupType;
    Languages = Languages;
    ProcessStatus = ProcessStatus;
    ProcessPhases = ProcessPhases;
    Constants = Constants;
    meritGroupToManage = _.cloneDeep(this.info);
    submitted = false;
    tabActive: string = '';

    $refs!: {
        newMeritGroupsForm: HTMLFormElement,
        editMeritGroupsForm: HTMLFormElement
    };

    mounted() {
        this.$nextTick(() => {
            notification.initialize();
        });
    }

    created() {
        this.tabActive = this.currentLanguage;
    }

    save() {
        this.submitted = true;
        if (this.info.groupType !== this.meritGroupToManage.groupType && this.isMeritGroupTypeInProcces()) {
            this.$emit('meritAlreadyIncluded');
            return;
        }
        if (this.meritGroupToManage.maxScore && this.isInvalidScoreChildren()) {
            this.$emit('isInvalidScoreChildren');
            return;
        }
        if (this.$props.mode === FormMode.enum.EDITING && ValidationService.validateFormRequired(this.$refs.editMeritGroupsForm)) {
            this.process.isSaving = true;
            MeritGroupsService.editMeritGroups(this.process.id, this.meritGroupToManage.infoToServer())
                .then(response => {
                    const groupIndex = this.process.meritGroups.findIndex(meritGroup => meritGroup.id === this.info.id);
                    response.meritChilds = _.orderBy(response.meritChilds, 'order');
                    this.process.meritGroups[groupIndex].setPropsAfterEdit(response);
                    this.process.editingMeritGroup.setPropsAfterEdit(response);
                    this.meritGroupToManage = {};
                    this.process.isSaving = false;
                    this.$emit('editedGroupMerit');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        } else if (this.$props.mode === FormMode.enum.CREATING && ValidationService.validateFormRequired(this.$refs.newMeritGroupsForm)) {
            this.process.isSaving = true;
            MeritGroupsService.addMeritGroups(this.process.id, new MeritGroup(this.meritGroupToManage).infoToServer())
                .then(response => {
                    this.process.meritGroups.push(new MeritGroup(response));
                    this.meritGroupToManage = {};
                    this.process.isSaving = false;
                    this.$emit('createdGroupMerit');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        }
    }

    cancel() {
        this.meritGroupToManage = {};
        this.$emit('canceledGroupMerit');
    }

    isMeritGroupTypeInProcces() {
        return this.process.meritGroups.find( element => {
           return element.groupType === Number(this.meritGroupToManage.groupType);
        });
    }
    
    isInvalidScoreChildren() {
        if (this.process.editingMeritGroup) {
            return this.process.editingMeritGroup.meritChilds.some( element => {
                return element.maxScore > Number(this.meritGroupToManage.maxScore);
            });
        }
    }
}
