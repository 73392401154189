import axios from 'axios';
import { ToasterService } from '../ToasterService';
import i18n from '../../lang';
import { errorsDictionary } from './errorsDictionary';
import { router } from '@/router';
import { securityService } from '@/Application/Services/auth/SecurityService';
import { Exceptions } from '@/Domain/enum/Exceptions';

export default function setup() {
    axios.interceptors.response.use(async response => {
        return response;
    }, error => {
        if (error.response.status === Exceptions.UNPROCESSABLE_CONTENT_ERROR_CODE) {
            securityService.signOut();
        }

        if (error.response.status === Exceptions.NO_ROLE_AUTHORIZED || error.response.status === Exceptions.UNAUTHORIZED_ERROR_CODE) {
            router.push({ name: 'AccessDenied' });
        }

        if (error.response.status === Exceptions.INTERNAL_SERVER_ERROR) {
            ToasterService.showError(i18n.t('lang.toaster.errorGeneralSubtitle') as string, i18n.t('lang.toaster.errorGeneralTitle') as string);
        }

        const errorOnDictionary = errorsDictionary.find(obj => obj.errorText === error.response.data);

        if (errorOnDictionary) {
            errorOnDictionary.toasterError();
            return;
        }

        error = error.response.data;

        return Promise.reject(error);
    });
}
