import axios from 'axios';
import { securityService } from '@/Application/Services/auth/SecurityService';

export default function setup() {
    axios.interceptors.request.use(async config => {
        const user = await securityService.getUser() as { expired: boolean, access_token: string};

        if (user && !user.expired) {
            config.headers.Authorization = 'Bearer ' + user.access_token;
        } else {
            securityService.signIn();
        }

        return config;
    });
}
