<template>
  <div v-if="signedIn">
    <div id="app">
      <router-view />
    </div>
  </div>
</template>
<style lang="scss">
@import '../assets/fonts/font-awesome/scss/fontawesome.css';
</style>
<script>
import { securityService } from '@/Application/Services/auth/SecurityService';
import axios from 'axios';
import i18n from './lang/index';
import MasterDataService from './Services/MasterDataService';
import momentTimeZone from '../node_modules/moment-timezone';
import { ProcessConfigFactory } from './Domain/Entities/index';
import { ProcessConfigurationTypes } from './Domain/enum/index';

export default {
  name: 'App',
  data() {
    return {
      mgr: securityService,
      signedIn: false,
      profile: null,
      myBrowserService: {}
    };
  },
  onIdle() {
    securityService.stopSilentRenew();
  },
  onActive() {
    securityService.startSilenRenew();
  },
  mounted() {
    axios.defaults.headers.common['Accept-Language'] = i18n.locale;
    axios.defaults.headers.common['Time-Zone'] = momentTimeZone.tz.guess();
    this.$on('idle', this.onIdle);
    this.$on('active', this.onActive);
    this.mgr.getUser().then(user => {
      axios.defaults.headers.common.Authorization = 'Bearer ' + user.access_token;
      this.signedIn = true;
      this.profile = user.profile;
      if (this.profile) {
        this.profile.full_name = this.profile.name; 
        this.profile.initials = this.profile.given_name.substring(0, 1).toUpperCase() + this.profile.firstSurname.substring(0, 1).toUpperCase();
      }
      if (this.profile.twoFactor) {
        this.profile.twoFactor = JSON.parse(this.profile.twoFactor);
      }
      this.myBrowserService = new Browser();
      MasterDataService.getFeatureFlag().then(flags => {
        flags.forEach(element => {
          if (this.$store._mutations[`featureFlagsStore/${element}Change`]) {
            this.$store.commit(`featureFlagsStore/${element}Change`, true);
          }
        });
      });
      MasterDataService.getProcessesConfiguration(ProcessConfigurationTypes.enum.GROUPSUBGROUP)
        .then(response => {
          const processConfigGroups = response.map(processConfig => ProcessConfigFactory.create(ProcessConfigurationTypes.enum.GROUPSUBGROUP, processConfig));
          this.$store.commit('processConfigStore/setProcessConfigGroups', processConfigGroups);
        }).catch(error => console.log(error));
    });
  }
};
</script>
