import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdSelect, Toggle, MdRadio, Modal } from '@/Components';
import { FormMode, GradeType, StatusExam, Languages, ProcessPhases, ProcessConfigurationTypes, Constants } from '@/Domain/enum';
import ValidationService from '@/Application/Services/ValidationService';
import ExamService from '@/Services/ExamService';
import { Exam, MultiLanguage } from '@/Domain/Entities';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import MasterDataService from '@/Services/MasterDataService';

@Component({
    components: {
        MdSelect,
        Toggle,
        MdRadio,
        Modal
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage'])
        ,
        ...mapState('languagesStore', { currentLanguage: 'current' })
        ,
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
        ,
        requiredsProperties() {
            return this.$data.objectToManage.creditable ? [this.$data.objectToManage.description, this.$data.objectToManage.creditableDescription] : [this.$data.objectToManage.description];
        },
        publishAndUsed() {
            return this.$props.process.isUsed || this.$props.process.stateToggle;
        },
        publishAndCreditable() {
            return (this as EditNewExam).publishAndUsed && this.$data.objectToManage.creditable;
        },
        isPhasedNotValid() {
            return this.$props.process.phase === ProcessPhases.enum.RANKING || this.$props.process.phase === ProcessPhases.enum.FINISH;
        },
        examsTypesViewOrdered() {
            return _.orderBy(this.$data.examsTypesView, [examType => _.deburr(examType.name[(this as EditNewExam).currentLanguage]).toLowerCase()]);
        }
    }
})

export default class EditNewExam extends Vue {
    @Prop() info: any;
    @Prop() value: any;
    @Prop() mode: any;
    @Prop() process: any;

    currentLanguage!: string;
    availableLanguages!: string[];
    publishAndUsed!: boolean;

    FormMode = FormMode;
    GradeType = GradeType;
    StatusExam = StatusExam;
    Languages = Languages;    
    Constants = Constants;
    objectToManage = _.cloneDeep(this.info);
    submitted = false;
    tabActive: string = '';
    showModalExam: boolean = false;
    examsTypesView: any[] = [];

    $refs!: {
        newExamForm: HTMLFormElement,
        editExamForm: HTMLFormElement
    };

    save() {
        this.submitted = true;
        if (this.objectToManage.maxScore && this.objectToManage.minScore && !this.isValidScore()) {
            this.$emit('examScoreError');
            return;
        }
        if (this.objectToManage.creditable) {
            this.objectToManage.gradeType = GradeType.enum.NOSCORE ;
            this.objectToManage.isExclusive = true ;
            this.objectToManage.creditable = true ;
        }
        if (this.process.hasPercentageExams && 
            (this.$props.mode === FormMode.enum.CREATING && this.objectToManage.gradeType === GradeType.enum.SCORE && ValidationService.validateFormRequired(this.$refs.newExamForm) ||
            this.$props.mode === FormMode.enum.EDITING && this.objectToManage.gradeType !== this.info.gradeType && ValidationService.validateFormRequired(this.$refs.editExamForm))) {
                this.showModalExam = true;
                return;
        }
        if (this.$props.mode === FormMode.enum.EDITING && ValidationService.validateFormRequired(this.$refs.editExamForm)) {
            this.process.isSaving = true;
            ExamService.editExam(this.process.id, this.objectToManage.toServer())
                .then(response => {
                    const examIndex = this.process.exams.indexOf(this.info);
                    this.process.exams[examIndex] = new Exam(response);
                    this.objectToManage = {};
                    this.info.isEditing = false;
                    this.process.isSaving = false;
                    this.showModalExam = false;
                    this.$emit('editedExam');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        } else if (this.$props.mode === FormMode.enum.CREATING && ValidationService.validateFormRequired(this.$refs.newExamForm)) {
            this.process.isSaving = true;
            ExamService.addExam(this.process.id, new Exam(this.objectToManage).toServer())
                .then(response => {
                    this.process.exams.push(new Exam(response));
                    this.objectToManage = {};
                    this.process.isSaving = false;
                    this.showModalExam = false;
                    this.$emit('createdExam');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        }
    }

    saveEditNewExam() {
        this.process.hasPercentageExams = false;
        this.save();
    }

    created() {
        this.tabActive = this.currentLanguage;
        this.getProcessConfigurationExamsList();
    }

    getProcessConfigurationExamsList() {
        MasterDataService.getProcessesConfiguration(ProcessConfigurationTypes.enum.EXAMS)
        .then((response: any[]) => {
            this.examsTypesView = response.map(element => ({
                name: new MultiLanguage(element.title),
                title: new MultiLanguage(element.title),
                value: element.configurationExamType,
                id: element.configurationExamType
            }));
        }).catch(error => console.log(error));
    }

    cancel() {
        this.objectToManage = {};
        this.info.isEditing = false;
        this.$emit('canceledExam');
    }

    isValidScore() {
        return Number(this.objectToManage.minScore) < this.objectToManage.maxScore;
    }

}
