import { AddReplacementListCandidateCriteria, MergeSteps, ReplacementListMergeOptions } from '@/Domain/enum';
import { IReplacementMerge } from '@/Domain/interfaces/ReplacementMerge/IReplacementMergeBase';

export default class WizardMerge {
  private _currentStep: number = MergeSteps.FIRST;
  private _replacementMerge: IReplacementMerge = {
    name: '',
    creationDate: null,
    validityDate: null,
    replacementListAId: '',
    elementToMergeBId: '',
    mergeOptionSelected: ReplacementListMergeOptions.NOT_SELECTED,
    orderCriteria: AddReplacementListCandidateCriteria.NotSelected,
    candidatesIds: []
  };

  public get replacementMerge(): IReplacementMerge {
    return this._replacementMerge;
  }

  public set replacementMerge(value: IReplacementMerge) {
    this._replacementMerge = value;
  }

  public get currentStep() {
    return this._currentStep;
  }

  public set currentStep(value: number) {
    this._currentStep = value;
  }
  
  public isInvalidStepOne(): boolean {
    return this._currentStep >= MergeSteps.FIRST 
    && (this._replacementMerge.mergeOptionSelected === ReplacementListMergeOptions.NOT_SELECTED 
      || !this._replacementMerge.name 
      || !this._replacementMerge.creationDate);
  }

  public isInvalidStepTwo(): boolean {
    return this._currentStep >= MergeSteps.SECOND 
    && (!this._replacementMerge.replacementListAId 
      || !this._replacementMerge.elementToMergeBId);
  }

  public isInvalidStepThree(): boolean {
    return this._currentStep >= MergeSteps.THIRD
    && this._replacementMerge.orderCriteria === AddReplacementListCandidateCriteria.NotSelected;
  }

  public isInvalidStepFour(): boolean {
    return this._currentStep >= MergeSteps.FOURTH 
    && this._replacementMerge.candidatesIds.length === 0;
  }

  public isInvalidUpdate(): boolean {
    return this._currentStep >= MergeSteps.SECOND 
    && (!this._replacementMerge.name 
      || !this._replacementMerge.creationDate 
      || this._replacementMerge.candidatesIds.length === 0);
  }

}
