import Oidc from 'oidc-client';
import 'babel-polyfill';

const splitAt = '//';
const origin = window.location.origin.split(splitAt)[1];
const saviaHubBaseUrl = process.env.VUE_APP_BASE_URL_SAVIA_HUB;

const oidcClientConfig = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({}),
  authority: saviaHubBaseUrl,
  client_id: origin,
  redirect_uri: window.location.origin + '/callback.html',
  response_type: 'id_token token',
  scope: 'openid profile email',
  post_logout_redirect_uri: window.location.origin + '/',
  silent_redirect_uri: window.location.origin + '/silent-renew.html',
  accessTokenExpiringNotificationTime: 600,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
});

Oidc.Log.level = Oidc.Log.INFO;

oidcClientConfig.events.addUserLoaded(user => {});

oidcClientConfig.events.addAccessTokenExpiring( () => {});

oidcClientConfig.events.addAccessTokenExpired( () => {
  oidcClientConfig.signoutRedirect()
  .then( resp => {})
  .catch( err => {});
});

oidcClientConfig.events.addSilentRenewError( () => {});

oidcClientConfig.events.addUserSignedOut( () => {
  oidcClientConfig.signoutRedirect()
  .then( resp => {})
  .catch( err => {});
});

export default oidcClientConfig;
