export const FeatureFlagsStore = {
    namespaced: true,
    state: {
        // the name of this properties (if come from feature flags) must be the same name of the request response
        header: false,
        multiLanguage: false,
        undoCalificationPhase: false,
        preferenceForThePlace: false
    },
    mutations: {
        // the name of this mutations (if come from feature flags) must be the name of the property + 'Change'
        headerChange(state, header) {
            state.header = header;
        },
        multiLanguageChange(state, multiLanguage) {
            state.multiLanguage = multiLanguage;
        },
        undoCalificationPhaseChange(state, undoCalificationPhase) {
            state.undoCalificationPhase = undoCalificationPhase;
        },
        preferenceForThePlaceChange(state, preferenceForThePlace) {
            state.preferenceForThePlace = preferenceForThePlace;
        }
    },
    getters: {
    }
};
