import moment from 'moment';
import { Requirement, Authorization, OepDocument, MeritGroup, Exam, MultiLanguage, PayExemption, Vacancies, Turns, Fees, VacanciesAttached, ProcessConfigPayExemption, ProcessConfigAuthorization, ProcessConfigGroup, MeritSubGroup } from '@/Domain/Entities';
import { BaseMeritsType, InscriptionTurn, OppositionType, ProcessPhases, ProcessType } from '@/Domain/enum';
import { IProcessReference } from '@/Domain/interfaces/Process/IProcessReference';
import _ from 'lodash';

export default class Process {
    private _id: string;
    private _url: string;
    private _type: number;
    private _entityOepId: string;
    private _entityName: string;
    private _title: MultiLanguage;
    private _description: MultiLanguage;
    private _group: ProcessConfigGroup;
    private _groupName: MultiLanguage;
    private _startDate: moment.Moment | null;
    private _endDate: moment.Moment | null;
    private _status: number;
    private _oppositionType: number;
    private _staffType: number;
    private _vacancies: Vacancies;
    private _vacanciesAttached: VacanciesAttached | null;
    private _turns: Turns;
    private _documents: OepDocument[];
    private _year: number;
    private _bopNumber: string;
    private _bopDate: moment.Moment | null;
    private _requirements: Requirement[];
    private _authorizationEntities: number[];
    private _authorizations: Authorization[];
    private _claimsEndDate: moment.Moment | null;
    private _claimsStartDate: moment.Moment | null;
    private _meritGroups: MeritGroup[];
    private _exams: Exam[];
    private _isEditingRequirement: boolean;
    private _isCreatingRequirement: boolean;
    private _isEditingDocument: boolean;
    private _isCreatingDocument: boolean;
    private _isEditingExam: boolean;
    private _isCreatingExam: boolean;
    private _isCreatingMeritGroup: boolean;
    private _isEditingMeritGroup: boolean;
    private _editingMeritGroup: MeritGroup | null;
    private _mandatoryRequirements: boolean;
    private _autoScaling: boolean;
    private _phase: number;
    private _active: boolean;
    private _hasMultiLanguageExams: boolean;
    private _hasPercentageExams: boolean;
    private _isCheckNeeded: boolean;
    private _stateToggle: boolean;
    private _isUsed: boolean;
    private _isSaving: boolean;
    private _isCreatingPercentage: boolean;
    private _fees: Fees;
    private _hasOnlinePayment: boolean;
    private _hasPasarelaPayment: boolean;
    private _allowPostInscriptionMeritPresentation: boolean;
    private _meritPresentationStartDate: moment.Moment | null;
    private _meritPresentationEndDate: moment.Moment | null;
    private _allowDocumentsNotice: boolean;
    private _hasCryptoSignature: boolean;
    private _groupType: number | undefined;
    private _isDesert: boolean;
    private _hasAnyCandidate: boolean;
    private _allowReplacementListCreation: boolean;
    private _blockAccessToAdmin: boolean;
    private _replacementListId: string | null;
    private _hasRequirementWithTest: boolean;
    private _references: IProcessReference[];

    constructor(data: any, processConfigurationPayExemptions?: ProcessConfigPayExemption[], processConfigGroup?: ProcessConfigGroup[]) {
        this._id = data.id;
        this._url = 'processes/';
        this._entityOepId = data.entityOepId;
        this._entityName = '';
        this._type = data.type;
        this._title = new MultiLanguage(data.title);
        this._description = new MultiLanguage(data.description);
        this._group = processConfigGroup ? _.cloneDeep(processConfigGroup).filter(group => group.type === data.group)[0] : new ProcessConfigGroup({});
        this._groupName = this._group ? this._group.title : new MultiLanguage('');
        this._groupType = this._group ? this._group.type : undefined;
        this._startDate = data.startDate ? moment.utc(data.startDate) : null;
        this._endDate = data.endDate ? moment.utc(data.endDate) : null;
        this._status = data.status;
        this._oppositionType = data.oppositionType;
        this._staffType = data.staffType;
        this._vacancies = new Vacancies(data.vacancies);
        this._vacanciesAttached = data.vacanciesAttached ? new VacanciesAttached(data.vacanciesAttached) : null;
        this._turns = new Turns(data.turns);
        this._documents = data.documents || [];
        this._year = data.year;
        this._bopNumber = data.bopNumber;
        this._bopDate = data.bopDate ? moment.utc(data.bopDate) : null;
        this._requirements = [];
        this._authorizationEntities = data.authorizationEntities || [];
        this._authorizations = [];
        this._claimsEndDate = data.claimsEndDate ? moment.utc(data.claimsEndDate) : null;
        this._claimsStartDate = data.claimsStartDate ? moment.utc(data.claimsStartDate) : null;
        this._meritGroups = [];
        this._exams = [];
        this._isEditingRequirement = false;
        this._isCreatingRequirement = false;
        this._isEditingDocument = false;
        this._isCreatingDocument = false;
        this._isEditingExam = false;
        this._isCreatingExam = false;
        this._isCreatingMeritGroup = false;
        this._isEditingMeritGroup = false;
        this._editingMeritGroup = null;
        this._mandatoryRequirements = data.mandatoryRequirements != null ? data.mandatoryRequirements : true;
        this._autoScaling = data.autoScaling != null ? data.autoScaling : false;
        this._phase = data.phase || ProcessPhases.enum.INSCRIPTIONS;
        this._active = data.active ? data.active : false;
        this._hasMultiLanguageExams = data.hasMultiLanguageExams || false;
        this._hasPercentageExams = data.applyExamPercentage || false;
        this._isCheckNeeded = false;
        this._stateToggle = false;
        this._isUsed = false;
        this._isSaving = false;
        this._isCreatingPercentage = !data.applyExamPercentage;
        this._fees = new Fees(data, processConfigurationPayExemptions);
        this._hasOnlinePayment = data.hasOnlinePayment;
        this._hasPasarelaPayment = data.hasPasarelaPayment;
        this._allowPostInscriptionMeritPresentation = data.allowPostInscriptionMeritPresentation || false;
        this._meritPresentationStartDate = data.meritPresentationStartDate ? moment.utc(data.meritPresentationStartDate) : null;
        this._meritPresentationEndDate = data.meritPresentationEndDate ? moment.utc(data.meritPresentationEndDate) : null;
        this._allowDocumentsNotice = data.allowDocumentsNotice || false;
        this._hasCryptoSignature = data.hasCryptoSignature || false;
        this._hasAnyCandidate = data.hasAnyCandidate;
        this._isDesert = this.phase === ProcessPhases.enum.FINISH && !this.hasAnyCandidate;
        this._allowReplacementListCreation = data.allowReplacementListCreation;
        this._blockAccessToAdmin = data.blockAccessToAdmin;
        this._replacementListId = data.replacementListId ? data.replacementListId : null;
        this._hasRequirementWithTest = data.hasRequirementWithTest;
        this._references = data.references ? data.references : [];
    }

    public get id() {
        return this._id;
    }
    public set id(id: string) {
        this._id = id;
    }

    public get url() {
        return this._url;
    }
    public set url(url: string) {
        this._url = url;
    }

    public get type() {
        return this._type;
    }
    public set type(type: number) {
        this._type = type;
    }

    public get entityOepId() {
        return this._entityOepId;
    }
    public set entityOepId(entityOepId: string) {
        this._entityOepId = entityOepId;
    }

    public get entityName() {
        return this._entityName;
    }
    public set entityName(entityName: string) {
        this._entityName = entityName;
    }

    public get title() {
        return this._title;
    }
    public set title(title: MultiLanguage) {
        this._title = title;
    }

    public get description() {
        return this._description;
    }
    public set description(description: MultiLanguage) {
        this._description = description;
    }

    public get group() {
        return this._group;
    }
    public set group(group: ProcessConfigGroup) {
        this._group = group;
    }

    public get groupName() {
        return this._groupName;
    }

    public get startDate() {
        return this._startDate;
    }
    public set startDate(startDate: moment.Moment | null) {
        this._startDate = startDate;
    }

    public get endDate() {
        return this._endDate;
    }
    public set endDate(endDate: moment.Moment | null) {
        this._endDate = endDate;
    }

    public get claimsStartDate() {
        return this._claimsStartDate;
    }

    public set claimsStartDate(claimsStartDate: moment.Moment | null) {
        this._claimsStartDate = claimsStartDate;
    }

    public get claimsEndDate() {
        return this._claimsEndDate;
    }

    public set claimsEndDate(claimsEndDate: moment.Moment | null) {
        this._claimsEndDate = claimsEndDate;
    }

    public get status() {
        return this._status;
    }
    public set status(status: number) {
        this._status = status;
    }

    public get oppositionType() {
        return this._oppositionType;
    }
    public set oppositionType(oppositionType: number) {
        this._oppositionType = oppositionType;
    }

    public get staffType() {
        return this._staffType;
    }
    public set staffType(staffType: number) {
        this._staffType = staffType;
    }

    public get vacancies() {
        return this._vacancies;
    }
    public set vacancies(vacancies: Vacancies) {
        this._vacancies = vacancies;
    }

    public get vacanciesAttached() {
        return this._vacanciesAttached;
    }
    public set vacanciesAttached(vacanciesAttached: VacanciesAttached | null) {
        this._vacanciesAttached = vacanciesAttached;
    }

    public get turns() {
        return this._turns;
    }
    public set turns(turns: Turns) {
        this._turns = turns;
    }

    public get bopNumber() {
        return this._bopNumber;
    }
    public set bopNumber(bopNumber: string) {
        this._bopNumber = bopNumber;
    }

    public get bopDate() {
        return this._bopDate;
    }
    public set bopDate(bopDate: moment.Moment | null) {
        this._bopDate = bopDate;
    }

    public get year() {
        return this._year;
    }
    public set year(year: number) {
        this._year = year;
    }

    public get documents() {
        return this._documents;
    }

    public set documents(documents: OepDocument[]) {
        this._documents = documents;
    }

    public addDocument(document: any): void {
        this._documents.push(
            new OepDocument(document)
        );
    }

    public removeDocument(document: OepDocument): void {
        const documentIndex = this.documents.indexOf(document);
        if (documentIndex !== -1) {
            this._documents.splice(documentIndex, 1);
        }
    }

    public setDefaultDroppedDocuments() {
        this.documents.forEach((doc: OepDocument) => doc.isDropped = false);
    }

    public get requirements() {
        return this._requirements;
    }

    public set requirements(requirements: Requirement[]) {
        this._requirements = requirements;
    }

    public addRequirements(requirement: Requirement) {
        this._requirements.push(requirement);
    }

    public get authorizationEntities() {
        return this._authorizationEntities;
    }

    public get authorizations() {
        return this._authorizations;
    }

    public get meritGroups() {
        return this._meritGroups;
    }

    public set meritGroups(meritGroups: MeritGroup[]) {
        this._meritGroups = meritGroups;
    }

    public get exams() {
        return this._exams;
    }

    public set exams(exams: Exam[]) {
        this._exams = exams;
    }

    public get isEditingRequirement() {
        return this._isEditingRequirement;
    }

    public set isEditingRequirement(isEditingRequirement: boolean) {
        this._isEditingRequirement = isEditingRequirement;
    }

    public get isCreatingRequirement() {
        return this._isCreatingRequirement;
    }

    public set isCreatingRequirement(isCreatingRequirement: boolean) {
        this._isCreatingRequirement = isCreatingRequirement;
    }
    public get isCreatingExam() {
        return this._isCreatingExam;
    }

    public set isCreatingExam(isCreatingExam: boolean) {
        this._isCreatingExam = isCreatingExam;
    }
    public get isCreatingDocument() {
        return this._isCreatingDocument;
    }

    public set isCreatingDocument(isCreatingDocument: boolean) {
        this._isCreatingDocument = isCreatingDocument;
    }

    public get isEditingDocument() {
        return this._isEditingDocument;
    }

    public set isEditingDocument(isEditingDocument: boolean) {
        this._isEditingDocument = isEditingDocument;
    }
    public get isEditingExam() {
        return this._isEditingExam;
    }

    public set isEditingExam(isEditingExam: boolean) {
        this._isEditingExam = isEditingExam;
    }

    public get isCreatingMeritGroup() {
        return this._isCreatingMeritGroup;
    }

    public set isCreatingMeritGroup(isCreatingMeritGroup: boolean) {
        this._isCreatingMeritGroup = isCreatingMeritGroup;
    }

    public get isEditingMeritGroup() {
        return this._isEditingMeritGroup;
    }

    public set isEditingMeritGroup(isEditingMeritGroup: boolean) {
        this._isEditingMeritGroup = isEditingMeritGroup;
    }

    public get editingMeritGroup() {
        return this._editingMeritGroup;
    }

    public set editingMeritGroup(editingMeritGroup: MeritGroup | null) {
        this._editingMeritGroup = editingMeritGroup;
    }

    public get mandatoryRequirements() {
        return this._mandatoryRequirements;
    }

    public set mandatoryRequirements(mandatoryRequirements: boolean) {
        this._mandatoryRequirements = mandatoryRequirements;
    }

    public get autoScaling() {
        return this._autoScaling;
    }

    public set autoScaling(autoScaling: boolean) {
        this._autoScaling = autoScaling;
    }

    public get phase() {
        return this._phase;
    }

    public get active() {
        return this._active;
    }

    public set active(active: boolean) {
        this._active = active;
    }

    public get hasMultiLanguageExams() {
        return this._hasMultiLanguageExams;
    }

    public set hasMultiLanguageExams(value: boolean) {
        this._hasMultiLanguageExams = value;
    }

    public get hasPercentageExams() {
        return this._hasPercentageExams;
    }

    public set hasPercentageExams(value: boolean) {
        this._hasPercentageExams = value;
    }

    public get isCheckNeeded() {
        return this._isCheckNeeded;
    }

    public set isCheckNeeded(value: boolean) {
        this._isCheckNeeded = value;
    }

    public get stateToggle() {
        return this._stateToggle;
    }

    public set stateToggle(value: boolean) {
        this._stateToggle = value;
    }

    public get isUsed() {
        return this._isUsed;
    }

    public set isUsed(value: boolean) {
        this._isUsed = value;
    }

    public get isSaving() {
        return this._isSaving;
    }
    public set isSaving(value: boolean) {
        this._isSaving = value;
    }

    public get isCreatingPercentage() {
        return this._isCreatingPercentage;
    }
    public set isCreatingPercentage(value: boolean) {
        this._isCreatingPercentage = value;
    }

    public get fees() {
        return this._fees;
    }
    public set fees(fees: Fees) {
        this._fees = fees;
    }

    public get hasOnlinePayment() {
        return this._hasOnlinePayment;
    }

    public get hasPasarelaPayment() {
        return this._hasPasarelaPayment;
    }

    public get canProcessBePaidOnline() {
        return this._hasOnlinePayment || this._hasPasarelaPayment;
    }

    public get allowPostInscriptionMeritPresentation() {
        return this._allowPostInscriptionMeritPresentation;
    }
    public set allowPostInscriptionMeritPresentation(value: boolean) {
        this._allowPostInscriptionMeritPresentation = value;
    }

    public get meritPresentationStartDate() {
        return this._meritPresentationStartDate;
    }

    public set meritPresentationStartDate(meritPresentationStartDate: moment.Moment | null) {
        this._meritPresentationStartDate = meritPresentationStartDate;
    }

    public get meritPresentationEndDate() {
        return this._meritPresentationEndDate;
    }

    public set meritPresentationEndDate(meritPresentationEndDate: moment.Moment | null) {
        this._meritPresentationEndDate = meritPresentationEndDate;
    }

    public get allowDocumentsNotice() {
        return this._allowDocumentsNotice;
    }

    public set allowDocumentsNotice(allowDocumentsNotice: boolean) {
        this._allowDocumentsNotice = allowDocumentsNotice;
    }

    public get hasCryptoSignature() {
        return this._hasCryptoSignature;
    }

    public get groupType() {
        return this._groupType;
    }

    public get isDesert() {
        return this._isDesert;
    }

    public get hasAnyCandidate() {
        return this._hasAnyCandidate;
    }

    get allowReplacementListCreation() {
        return this._allowReplacementListCreation;
    }

    set allowReplacementListCreation(allowReplacementListCreation: boolean) {
        this._allowReplacementListCreation = allowReplacementListCreation;
    }

    get blockAccessToAdmin() {
        return this._blockAccessToAdmin;
    }

    set blockAccessToAdmin(blockAccessToAdmin: boolean) {
        this._blockAccessToAdmin = blockAccessToAdmin;
    }

    get replacementListId() {
        return this._replacementListId;
    }

    set replacementListId(replacementListId: string | null) {
        this._replacementListId = replacementListId;
    }

    get references() {
        return this._references;
    }

    set references(references: IProcessReference[]) {
        this._references = references;
    }

    public get hasRequirementWithTest() {
        return this._hasRequirementWithTest;
    }

    public set hasRequirementWithTest(hasRequirementWithTest: boolean) {
        this._hasRequirementWithTest = hasRequirementWithTest;
    }

    public setEntityName(entities: any) {
        entities.forEach(entity => {
            if (entity.id === this.entityOepId) {
                this.entityName = entity.name;
            }
        });
    }

    public isCreatingOrEditingProcess() {
        return this.isEditingRequirement || this.isCreatingRequirement || this.isEditingExam || this.isCreatingExam ||
            this.isEditingMeritGroup || this.isCreatingMeritGroup || this.editingMeritGroup || this.isEditingDocument || this.isCreatingDocument;
    }

    public createAutorizations(processConfigurationAuthorizations: ProcessConfigAuthorization[]) {
        processConfigurationAuthorizations.forEach(processConfigurationAuthorization => {
            if (processConfigurationAuthorization.type > 0) {
                this._authorizations.push(new Authorization({
                    text: processConfigurationAuthorization.title,
                    // tslint:disable-next-line: radix
                    value: this._authorizationEntities.some(elem => elem === processConfigurationAuthorization.type),
                    // tslint:disable-next-line: radix
                    id: processConfigurationAuthorization.type
                }));
            }
        });
    }

    public addRequirement() {
        this.isCreatingRequirement = true;
        this.isEditingRequirement = false;
    }

    public editRequirement(requirement) {
        this.isCreatingRequirement = false;
        this.isEditingRequirement = true;
        this.requirements.forEach(element => element.isEditing = false);
        requirement.isEditing = true;
    }

    public addExam() {
        this.isCreatingExam = true;
        this.isEditingExam = false;
    }

    public editExam(exam) {
        this.exams.forEach(element => element.isEditing = false);
        this.isCreatingExam = false;
        this.isEditingExam = true;
        exam.isEditing = true;
    }

    public addMeritGroups() {
        this.isCreatingMeritGroup = true;
        this.isEditingMeritGroup = false;
    }

    public editMeritGroups() {
        this.isCreatingMeritGroup = false;
        this.isEditingMeritGroup = true;
        if (this.editingMeritGroup) {
            this.editingMeritGroup.isEditing = true;
            this.editingMeritGroup.isEditingMerit = false;
            this.editingMeritGroup.isCreatingMerit = false;
            this.editingMeritGroup.isCreatingMeritSubGroup = false;
        }
    }

    public areRequirementsValid() {
        return (
            !this.stateToggle ||
            !this.mandatoryRequirements ||
            (this.stateToggle &&
                this.mandatoryRequirements &&
                this.requirements.length >= 1)
        );
    }

    public areVacanciesValid() {
        const vacancies = Object.values(this.vacancies);

        return (
            this.type !== ProcessType.enum.CALL ||
            !this.stateToggle ||
            vacancies.some(vacant => vacant > 0)
        );
    }

    public mapVancanciesToEnum() {
        return [ 
            InscriptionTurn.enum.FREE = this.vacancies.freeVacancies,
            InscriptionTurn.enum.FREE_DISABILITY = this.vacancies.freeDisabilityVacancies,
            InscriptionTurn.enum.INTERNAL = this.vacancies.internalVacancies,
            InscriptionTurn.enum.INTERNAL_DISABILITY = this.vacancies.internalDisabilityVacancies,
            InscriptionTurn.enum.MOBILITY = this.vacancies.mobilityVacancies
        ];
    }

    public areMeritsValid() {

        return (
            this.oppositionType === OppositionType.enum.NOMINATION ||
            this.oppositionType === OppositionType.enum.FREE_DESIGNATION ||
            !this.stateToggle ||
            this.areAllMeritsAndMeritsGroupsValid()
        );
    }

    public areMeritsChildsOrMeritSubGroupsEmpties() {
        return this.meritGroups.filter(group =>
            group.meritChilds.length === 0 ||
            group.meritChilds.some(child => child.type === BaseMeritsType.enum.MERITSUBGROUP &&
                (child as MeritSubGroup).merits.length === 0)
        );
    }

    public areTurnsCheckedValid() {
        return this.turns.isValidTurnsChecked();
    }

    public setEditAndCreateToFalse() {
        this.isCreatingMeritGroup = false;
        this.isEditingMeritGroup = false;
        this.isCreatingExam = false;
        this.isEditingExam = false;
        this.isCreatingRequirement = false;
        this.isEditingRequirement = false;
        this.isCreatingDocument = false;
        this.isEditingDocument = false;

        if (this.editingMeritGroup) {
            this.setEditingMeritGroupToFalse();
        }
    }

    public toServer(stateToggle: boolean) {
        const requirementsToServe = this.requirements.map((element: Requirement) => element.infoToServer());
        const examsToServe = this.exams.map((element: Exam) => element.toServer());

        this._authorizationEntities = [];
        this.authorizations.forEach((element: Authorization) => {
            if (element.value) {
                this._authorizationEntities.push(element.id);
            }
        });
        this.fees.setAmountToServer();
        this.fees.setPayExemptionAmountToServer();

        const payExemptionsTypes = this.fees.payExemptionTypesView.filter(item => item.value);
        let vacanciesAttached: any = [];
        if (this.vacanciesAttached) {
            vacanciesAttached = Object.values(this.vacanciesAttached);
            if (vacanciesAttached.every(vacancies => vacancies === 0)) { this.vacanciesAttached = null; }
        }

        return {
            id: this.id,
            type: this.type,
            entityOepId: this.entityOepId,
            title: this.title,
            description: this.description || '',
            group: this.group.type,
            bopNumber: this.bopNumber,
            bopDate: this.bopDate,
            year: this.year,
            startDate: this.startDate ? this.startDate.toISOString() : null,
            endDate: this.endDate ? this.endDate.toISOString() : null,
            status: stateToggle ? this.status : 0,
            oppositionType: this.oppositionType,
            staffType: this.staffType,
            vacancies: this._vacancies.toServer(),
            vacanciesAttached: this.vacanciesAttached ? (this._vacanciesAttached as VacanciesAttached).toServer() : null,
            turns: this._turns.toServer(),
            requirements: requirementsToServe,
            exams: examsToServe,
            authorizationEntities: this.authorizationEntities,
            claimsStartDate: this.claimsStartDate ? this.claimsStartDate.toISOString() : null,
            claimsEndDate: this.claimsEndDate ? this.claimsEndDate.toISOString() : null,
            meritGroups: this.oppositionType === OppositionType.enum.NOMINATION ? [] : this.meritGroups,
            mandatoryRequirements: this.mandatoryRequirements,
            autoScaling: this.autoScaling,
            hasMultiLanguageExams: this.hasMultiLanguageExams,
            fees: this._fees.toServer(payExemptionsTypes),
            payExemptionFeeAmounts: payExemptionsTypes.filter(el => el.amount !== null).map((element: PayExemption) => element.toServer()),
            allowPostInscriptionMeritPresentation: this.allowPostInscriptionMeritPresentation,
            meritPresentationStartDate: this.meritPresentationStartDate,
            meritPresentationEndDate: this.meritPresentationEndDate,
            allowDocumentsNotice: this.allowDocumentsNotice,
            allowReplacementListCreation: this.allowReplacementListCreation,
            blockAccessToAdmin: this.blockAccessToAdmin,
            hasRequirementWithTest: this.hasRequirementWithTest,
            references: this._references
        };
    }

    private areAllMeritsAndMeritsGroupsValid() {
        return this.meritGroups.some(group =>
            group.meritChilds.some(child => child.type === BaseMeritsType.enum.MERIT) ||
            group.meritChilds.some(child => child.type === BaseMeritsType.enum.MERITSUBGROUP &&
                (child as MeritSubGroup).merits.length > 0)
        );
    }

    private setEditingMeritGroupToFalse() {
        this.editingMeritGroup!.isCreatingMeritSubGroup = false;
        this.editingMeritGroup!.meritChilds.forEach(element => element.isEditing = false);
        this.editingMeritGroup = null;
    }
}
