import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { Candidate } from '@/Domain/Entities';

@Component({
    computed: {
        ...mapGetters('candidateStore', { candidateInfo: 'getCandidateInfo' })
    },
    watch: {
        data() {
            if ((this as MdSearcherNoFilter).candidateInfo && (this as MdSearcherNoFilter).data.length) {
                (this as MdSearcherNoFilter).searchString = ((this as MdSearcherNoFilter).candidateInfo as Candidate).nif;
                (this as MdSearcherNoFilter).searchStringOnData();
            }
        },
        candidateInfo() {
            (this as MdSearcherNoFilter).removeSearchString();
        }
    }
})

export default class MdSearcherNoFilter extends Vue {
    @Prop() data!: any[];
    @Prop() propertyToListenTo!: any;
    
    candidateInfo!: Candidate | null;

    searcherResults: number | null = null;
    searchString: string = '';
    searchActiveResult = 0;

    $refs!: {
        contentScrollable: HTMLElement
    };

    @Watch('propertyToListenTo')
    onChildChanged(val: any, oldVal: any) {this.removeSearchString(); }

    searchStringOnData() {
        if (this.searchString !== '') {
            this.clearResultsInName();
            this.searcherResults = this.data.filter(element => element.fullName.includes(this.searchString) || element.nif.includes(this.searchString)).length;
            this.data.forEach((element, indexElement) => {
                if (element.fullName.includes(this.searchString)) {
                    this.manageMatchStringResult(element, indexElement, 'fullName', 'fullNameToShow');
                }

                if (element.nif.includes(this.searchString)) {
                    this.manageMatchStringResult(element, indexElement, 'nif', 'nifToShow');
                }
            });
        } else {
            this.clearResultsInName();
        }
    }

    manageMatchStringResult(element, indexElement, property, propertyToshow) {
        const parts = element[property].split(this.searchString);
        let newName = '';
        parts.forEach((part, indexPart) => {
            if (indexPart !== parts.length - 1) {
                if (indexElement === this.data.findIndex(item => item[property].includes(this.searchString))) {
                    element.activeSearch = true;
                    newName += part + '<span class="match-search match-search--active">' + this.searchString + '</span>';
                } else {
                    newName += part + '<span class="match-search">' + this.searchString + '</span>';
                }
                this.searchActiveResult = 1;

            } else {
                newName += part;
            }
        });
        element[propertyToshow] = newName;
    }

    clearResultsInName() {
        this.data.forEach(element => {
            element.fullNameToShow = element.fullName;
            element.nifToShow = element.nif;
        });
        this.searcherResults = null;
        this.searchActiveResult = 0;
    }

    backSearchString() {
        const gradeListReverse = _.clone(this.data).reverse();
        const activebefore = gradeListReverse.findIndex(element => element.activeSearch);
        const newActiveIndex = gradeListReverse.findIndex((element, index) => (element.nifToShow.includes('<span') || element.fullNameToShow.includes('<span')) && index > activebefore);
        if (newActiveIndex !== -1 && newActiveIndex < gradeListReverse.length) {
            this.clearResultActiveInName(activebefore, gradeListReverse);
            if (gradeListReverse[newActiveIndex]) {
                gradeListReverse[newActiveIndex].fullNameToShow = gradeListReverse[newActiveIndex].fullNameToShow.replace(/match-search/g, 'match-search match-search--active');
                gradeListReverse[newActiveIndex].nifToShow = gradeListReverse[newActiveIndex].nifToShow.replace(/match-search/g, 'match-search match-search--active');
                gradeListReverse[newActiveIndex].activeSearch = true;
            }

            this.$nextTick(() => {
                this.moveScroll();
            });
        }

        if (this.searchActiveResult - 1 > 0) {
            this.searchActiveResult--;
        }
    }

    nextSearchString() {
        const activebefore = this.data.findIndex(element => element.activeSearch);
        const newActiveIndex = this.data.findIndex((element, index) => (element.nifToShow.includes('<span') || element.fullNameToShow.includes('<span')) && index > activebefore);
        if (newActiveIndex !== -1 && newActiveIndex < this.data.length) {
            this.clearResultActiveInName(activebefore, this.data);
            if (this.data[newActiveIndex]) {
                this.data[newActiveIndex].fullNameToShow = this.data[newActiveIndex].fullNameToShow.replace(/match-search/g, 'match-search match-search--active');
                this.data[newActiveIndex].nifToShow = this.data[newActiveIndex].nifToShow.replace(/match-search/g, 'match-search match-search--active');
                this.data[newActiveIndex].activeSearch = true;
            }

            this.$nextTick(() => {
                this.moveScroll();
            });
        }

        if (this.searcherResults && this.searchActiveResult + 1 <= this.searcherResults) {
            this.searchActiveResult++;
        }
    }

    clearResultActiveInName(activebefore, array) {
        array[activebefore].activeSearch = false;
        array[activebefore].fullNameToShow = array[activebefore].fullNameToShow.replace(/match-search--active/g, '');
        array[activebefore].nifToShow = array[activebefore].nifToShow.replace(/match-search--active/g, '');
    }

    removeSearchString() {
        this.clearResultsInName();
        this.searchString = '';
        this.$emit('removeSearchString');
    }

    moveScroll() {
        const firstTieToEdit = (document.querySelectorAll('.match-search--active')[0] as any).offsetTop;
        (document.getElementById('contentScrollable') as any).scrollTo({ top: firstTieToEdit - 200, behavior: 'smooth' });
    }
}
