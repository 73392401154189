import { Component, Vue } from 'vue-property-decorator';
import { Breadcrumb, MdRadio, MdSelect, MdTextarea, FileImporter, MdDatepicker, MdTimePicker, Modal, InscriptionMerits } from '@/Components';
import { Gender, InscriptionTurn, ReserveType, YesNo, PayExemptionTypes, FileTypes, MeritRatingType, InscriptionRegistry, ProcessPhases, InscriptionStatus, OriginDocument, Languages, EditionType, PaymentMethod, InscriptionModules, ProcessType, Constants, ProcessConfigurationTypes, Roles } from '@/Domain/enum';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import { InscriptionCandidate, InscriptionFile, InscriptionData, MeritGroup, JustificationDocument, OepDocument, ClientInfo, Merit, ProcessConfigPayExemption, ProcessConfigFactory } from '@/Domain/Entities';
import ProcessesService from '@/Services/ProcessesService';
import ValidationService from '@/Application/Services/ValidationService';
import { securityService } from '@/Application/Services/auth/SecurityService';
import MasterDataService from '@/Services/MasterDataService';
import moment from '../../../node_modules/moment';
import InscriptionService from '@/Services/InscriptionService';
import i18n from '../../lang';
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import Layout from '../Layout';
import DocumentService from '@/Application/Services/DocumentService';
import { ToasterService } from '@/Services/ToasterService';

@Component({
    components: {
        Breadcrumb, MdRadio, MdSelect, MdTextarea, FileImporter, MdDatepicker, MdTimePicker, Modal, InscriptionMerits
    },
    computed: {
        disabilityRequired() {
            return this.$data.tryToSubmit && !this.$data.inscription.disabilityData.hasDisability && (
                this.$data.inscription.contactData.inscriptionTurn === InscriptionTurn.enum.FREE_DISABILITY ||
                this.$data.inscription.contactData.inscriptionTurn === InscriptionTurn.enum.INTERNAL_DISABILITY ||
                this.$data.inscription.contactData.inscriptionTurn === InscriptionTurn.enum.FREE_DESIGNATION_DISABILITY ||
                this.$data.inscription.payExemption.payExemptionType === PayExemptionTypes.enum.DISABILITY);
        },
        breadcrumbInfo() {
            if (this.$data.processData) {
                const breadcrumb: any[] = [];
                breadcrumb.push({ title: this.$data.fromRouteParams && this.$data.fromRouteParams.title ? this.$data.fromRouteParams.title : undefined, link: this.$data.fromRouteParams && this.$data.fromRouteParams.from ? this.$data.fromRouteParams.from : undefined  }, { title: this.$data.processData.title[this.$store.state.languagesStore.current] + ' ' + i18n.t('lang.router.dateRef') + ' ' + moment(this.$data.processData.bopDate).format('L'), params: this.$data.fromRouteParams ? this.$data.fromRouteParams : undefined });
                return breadcrumb;
            }
        },
        isAdminRole() {
            return (this as InscriptionEdit).authData && (this as InscriptionEdit).authData.role === Roles.ADMIN;
        },
        meritsEmpties() {
            if (!this.$data.meritGroupId) {
                return [];
            }
            const meritGroupStructure = this.$data.inscriptionData.meritsGroup.find(meritGroup => meritGroup.id === this.$data.meritGroupId);
            const meritsEmptyStructure = meritGroupStructure.merits.filter(meritChildStructure => (this as InscriptionEdit).inscription.findMeritInInscription(this.$data.meritGroupId, meritChildStructure.id) == null);
            const meritsInSubGroupStructure = meritGroupStructure.meritSubGroups.reduce((acc, sub) => {
                return acc.concat(sub.merits.filter(meritChildStructure => (this as InscriptionEdit).inscription.findMeritInInscription(this.$data.meritGroupId, meritChildStructure.id, sub.id) == null));
            }, []);
            return _.orderBy(meritsEmptyStructure.concat(meritsInSubGroupStructure), ['parentOrder', 'order'], ['asc', 'asc']);
        },
        meritsEmptiesSelect() {
            return (this as InscriptionEdit).meritsEmpties.map(m => ({ name: m.descriptionTruncate[i18n.locale], id: m.id, title: m.meritSubGroupId ? m.description[i18n.locale] + ' (' + i18n.t('lang.shared.subGroup') + ')' : m.description[i18n.locale] }));
        },
        isRequiredFile() {
            return (this.$data.meritToManage.ratingType === MeritRatingType.enum.FIXED_SCORE || this.$data.meritToManage.ratingType === MeritRatingType.enum.INTERVIEW) && !this.$data.documentsAddToMerit.length && !this.$data.meritToManage.allowUserOficio;
        },
        pricePayExemption() {
            let priceToPay = null;
            if (this.$data.inscriptionData.hasFeePayment && this.$data.inscriptionData.payFeeAmount.generalAmount && this.$data.inscription.payExemption.payExemptionType != null) {
                this.$data.inscription.payExemption.payExemptionType === PayExemptionTypes.enum.NONE || this.$data.inscription.payExemption.payExemptionType === PayExemptionTypes.enum.NOREASON ?
                    priceToPay = this.$data.inscriptionData.payFeeAmount.generalAmount :
                    priceToPay = this.$data.inscriptionData.payFeeAmount.payExemptionFeeAmounts.find(payExemptionFeeAmount => payExemptionFeeAmount.payExemptionType === this.$data.inscription.payExemption.payExemptionType).amount;
            }
            return priceToPay;
        },
        canDeleteInscription() {
            return (this as InscriptionEdit).isAdminRole && (this as InscriptionEdit).inscription.status !== InscriptionStatus.enum.DRAFT && !(this as InscriptionEdit).inscription.isOnlineInscription && (this as InscriptionEdit).processData.phase === ProcessPhases.enum.INSCRIPTIONS && !(this as InscriptionEdit).inscription.hasDocumentOfApplicant && !(this as InscriptionEdit).inscription.hasCorrectJustificationDocument;
        },
        underSixteen() {
            if ((this as InscriptionEdit).inscription.personalDataDetails.dateOfBirth) {
                return (this as InscriptionEdit).inscription.personalDataDetails.isAgeBelowMinimum() && ((this as InscriptionEdit).inscription.status === InscriptionStatus.enum.DRAFT || (this as InscriptionEdit).moduleToEdit === InscriptionModules.enum.PERSONALDATA);
            }
        },
        retirementAge() {
            if ((this as InscriptionEdit).inscription.personalDataDetails.dateOfBirth) {
                return (this as InscriptionEdit).inscription.personalDataDetails.isAgeAboveMaximum() && ((this as InscriptionEdit).inscription.status === InscriptionStatus.enum.DRAFT || (this as InscriptionEdit).moduleToEdit === InscriptionModules.enum.PERSONALDATA);
            }
        },
        showMeritGroups() {
            return (this as InscriptionEdit).meritLoaded && (this as InscriptionEdit).inscription.meritGroups.length > 0;
        },
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        ...mapGetters('userStore', { userConnectedDni: 'getDni' }),
        ...mapGetters('userStore', { authData: 'getAuthData' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat' }),
        ...mapState('languagesStore', { currentLanguage: 'current' })
    },
    methods: {
        ...mapActions('userStore', ['fetchAdminBlockedProcessesIds'])
    },
    watch: {
        'currentLanguage'() {
            (this as InscriptionEdit).refreshStreeTypes();
            (this as InscriptionEdit).getOrderedPayExemptionTypes();
        },
        async '$route.params'(newParams, oldParams) {
            (this as InscriptionEdit).loadRouteParams();
            if (newParams.id !== oldParams.id) {
                await (this as InscriptionEdit).getDataProcessAsync();
                await (this as InscriptionEdit).getInscriptionStructureAsync();
                
            }
            await (this as InscriptionEdit).getInscriptionData();
        }
    }
})

export default class InscriptionEdit extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    meritsEmpties!: Merit[];
    meritsEmptiesSelect!: any[];
    pricePayExemption!: number;
    isAdminRole!: boolean;
    canDeleteInscription!: boolean;
    availableLanguages!: string[];

    Gender = Gender;
    YesNo = YesNo;
    InscriptionTurn = InscriptionTurn;
    ReserveType = ReserveType;
    FileTypes = FileTypes;
    MeritRatingType = MeritRatingType;
    PayExemptionTypes = PayExemptionTypes;
    InscriptionRegistry = InscriptionRegistry;
    InscriptionStatus = InscriptionStatus;
    ProcessPhases = ProcessPhases;
    OriginDocument = OriginDocument;
    Languages = Languages;
    EditionType = EditionType;
    PaymentMethod = PaymentMethod;
    InscriptionModules = InscriptionModules;
    ProcessType = ProcessType;
    Constants = Constants;

    mgr = securityService;
    inscription = new InscriptionCandidate({
        documents: [],
        personalData: {
            personalDataDetails: {},
            address: {},
            contactData: {},
            authorization: {},
            disabilityData: {}
        },
        justificationDocuments: [],
        payExemption: {}
    });
    inscriptionToManage: InscriptionCandidate | null = null;
    payExemptionTypesEdited: any = [];
    hasInscriptionMunicipalitySaved: boolean = false;
    formValidationError: boolean = false;
    loading: boolean = false;
    tryToSubmit: boolean = false;
    countries: any;
    municipalities: any = [];
    autonomousCommunityList: any;
    streetTypes: any;
    provinces: any;
    nationalities: any;
    inscriptionData: any = null;
    inscriptionDate = moment();
    processData: any = null;
    defaultLanguage: string = '';
    secondLanguage: string = '';
    loadingStreetTypes: boolean = false;
    meritLoaded: boolean = false;
    authData!: { role: string, saviaHubClientId: string };
    hasRegistryApi: boolean = false;
    editMeritModal: boolean = false;
    clientInfo: ClientInfo = new ClientInfo({});
    meritToManage: Merit = new Merit({});
    formDataDoc: any[] = [];
    documentsAddToMerit: any[] = [];
    documentRemoveToMerit: any[] = [];
    documentRemoveToModule: any[] = [];
    meritGroupId?: string | null = null;
    fileJustify: any = null;
    savingDocuments: boolean = false;
    showModalDeleteInscription: boolean = false;
    disabledDisability: boolean = false;
    disabledNoReason: boolean = false;
    moduleToEdit: number = InscriptionModules.enum.NONE;
    processConfigurationPayExemptions: ProcessConfigPayExemption[] = [];
    showModalApplyPersonalDataAllInscriptions: boolean = false;
    submitted: boolean = false;
    maxRetirementAge: moment.Moment = moment().subtract(70, 'years').add(1, 'day');
    minUnderSixteen: moment.Moment = moment().subtract(16, 'years');
    fetchAdminBlockedProcessesIds!: (dni: string) => void;
    userConnectedDni!: string;
    fromRouteParams: any = null;

    $refs!: {
        inscriptionEditForm: HTMLFormElement,
        modalNif: HTMLFormElement,
        checkbox: HTMLFormElement,
        dropdownMerit: HTMLElement,
        dropdownMeritOnSubGroup: HTMLElement,
        modalEditMerit: HTMLFormElement,
        dataPersonal: HTMLElement,
        address: HTMLElement,
        registryDay: HTMLElement,
        authorizations: HTMLElement,
        notificationData: HTMLElement,
        turnDisability: HTMLElement,
        exemptionPayment: HTMLElement,
        justify: HTMLElement,
        fileImporter: HTMLFormElement
    };

    copyIdInscription() {
        navigator.clipboard.writeText(this.inscription.id);
        ToasterService.showSuccess(i18n.t('lang.inscription.copyIdInscriptionClipboard') as any);
    }

    editInscriptionModule(moduleToEdit, justifyDoc?) {
        if (this.moduleToEdit || this.cantEditAuthAndRegistryDate(moduleToEdit) || this.cantEditJustifyDoc(moduleToEdit, justifyDoc)) {
            return;
        }
        this.moduleToEdit = moduleToEdit;
        this.inscriptionToManage = _.cloneDeep(this.inscription);
        if (moduleToEdit === InscriptionModules.enum.PAYEXEMPTION && this.processData.phase === ProcessPhases.enum.INSCRIPTIONS) {
            this.changeSelectPayExemption();
        }
        if (moduleToEdit === InscriptionModules.enum.JUSTIFY) {
            justifyDoc.isEditing = true;
        }
        if (moduleToEdit === InscriptionModules.enum.ADDRESS) {
            this.hasInscriptionMunicipalitySaved = true;
            this.getMunicipalities();
        }
    }

    changeSelectPayExemption() {
        this.payExemptionTypesEdited = _.cloneDeep(this.inscriptionData.payExemptionTypes);
        this.getOrderedPayExemptionTypes();
        if (!this.inscription.disabilityData.hasDisability && this.payExemptionTypesEdited.find(element => element.id === PayExemptionTypes.enum.DISABILITY)) {
            this.disabledDisability = true;
            this.payExemptionTypesEdited.find(element => element.id === PayExemptionTypes.enum.DISABILITY).disabled = true;
        }
        if (this.inscription.filterDocuments(FileTypes.enum.PAY_EXEMPTION).some(element => element.editionType === EditionType.enum.APPLICANTINSCRIPTION || element.editionType === EditionType.enum.APPLICANTCORRECTION)) {
            this.disabledNoReason = true;
            this.payExemptionTypesEdited.find(element => element.id === PayExemptionTypes.enum.NOREASON).disabled = true;
        }
    }

    cantEditAuthAndRegistryDate(moduleToEdit) {
        return (moduleToEdit === InscriptionModules.enum.AUTHORIZATION || moduleToEdit === InscriptionModules.enum.DAYOFREGISTRY)
            && (this.processData.phase > ProcessPhases.enum.INSCRIPTIONS || this.inscription.isOnlineInscription);
    }

    cantEditJustifyDoc(moduleToEdit, justifyDoc) {
        return moduleToEdit === InscriptionModules.enum.JUSTIFY
            && justifyDoc === undefined || justifyDoc && (this.inscription.isOnlineInscription && justifyDoc.registryErrorDate === null && (justifyDoc.origin === OriginDocument.enum.JUSTIFY || justifyDoc.origin === OriginDocument.enum.CORRECTION));
    }

    cancelEditInscriptionJustifyDoc(justifyDoc) {
        justifyDoc.isEditing = false;
        this.resetModuleToEditToNone();
        if (this.inscriptionToManage) {
            this.inscription = this.inscriptionToManage;
        }
        this.$nextTick(() => {
            this.formValidationError = !ValidationService.validateFormRequired(this.$refs.inscriptionEditForm);
        });
    }

    moveToRef(ref) {
        window.scrollTo({ top: ref.offsetTop, behavior: 'smooth' });
    }

    cancelEditInscription() {
        this.resetPropsDisabledAndSavingDocuments();
        this.resetModuleToEditToNone();
        this.hasInscriptionMunicipalitySaved = true;
        this.resetNotification();
        if (this.formDataDoc) {
            this.formDataDoc.forEach(doc => {
                const index = this.inscription.documents.indexOf(doc);
                this.inscription.removeDocument(index, 1);
            });
        }
        this.resetDocumentRemoveToModuleAndFormDataDoc();
        if (this.inscriptionToManage) {
            this.inscription = this.inscriptionToManage;
        }
        if (this.payExemptionTypesEdited) {
            this.payExemptionTypesEdited = this.inscriptionData.payExemptionTypes;
        }
        this.getMunicipalities();
        this.$nextTick(() => {
            this.formValidationError = !ValidationService.validateFormRequired(this.$refs.inscriptionEditForm);
        });
    }

    applyPersonalDataChangesToPreviousInscription(ref) {
        InscriptionService.putInscriptionPersonalDataDetails(this.inscription.id, this.inscription.personalDataDetails.toServer(this.inscription.id)).then(() => {
            this.inscriptionToManage = null;
            this.inscription.personalDataDetails.applyPersonalDataChangesToPreviousInscription = false;
            this.showModalApplyPersonalDataAllInscriptions = false;
            this.moveToRef(ref);
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
        })
            .catch(() => {
                if (this.inscriptionToManage) {
                    this.inscription = this.inscriptionToManage;
                }
                this.showModalApplyPersonalDataAllInscriptions = false;
            });
    }

    saveEditInscription(moduleToEdit, ref) {
        this.formValidationError = !ValidationService.validateFormRequired(this.$refs.inscriptionEditForm);
        if (this.formValidationError || this.savingDocuments) {
            return;
        }
        if (moduleToEdit === InscriptionModules.enum.PERSONALDATA) {
            this.showModalApplyPersonalDataAllInscriptions = true;
            this.resetModuleToEditToNone();
            this.moveToRef(ref);
            return;
        }
        const action = this.getInscriptionUpdateAction(moduleToEdit);
        action.then(() => {
            this.inscriptionToManage = null;
            if (moduleToEdit === InscriptionModules.enum.TURNDISABILITY || moduleToEdit === InscriptionModules.enum.PAYEXEMPTION) {
                const requests: any[] = [];
                this.savingDocuments = true;
                this.formDataDoc.forEach(data => {
                    requests.push(InscriptionService.addFileToInscription(data));
                });
                this.documentRemoveToModule.forEach(doc => {
                    requests.push(InscriptionService.removeFileFromInscription(this.inscription.id, doc.id));
                });
                Promise.all(requests)
                    .then(() => {
                        this.getDataProcess();
                        this.resetPropsDisabledAndSavingDocuments();
                        this.resetModuleToEditToNone();
                        this.resetDocumentRemoveToModuleAndFormDataDoc();
                        this.getInscriptionStructure();
                        this.getInscriptionData(ref);
                        ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                    })
                    .catch(error => console.log(error));
            } else {
                this.resetModuleToEditToNone();
                this.moveToRef(ref);
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            }
        })
            .catch(error => {
                console.log(error);
            });
    }

    getInscriptionUpdateAction(moduleToEdit) {
        if (moduleToEdit === InscriptionModules.enum.ADDRESS) {
            this.inscription.address.resetAddress();
            return InscriptionService.putInscriptionAddress(this.inscription.id, this.inscription.address.toServer(this.inscription.id));
        }
        if (moduleToEdit === InscriptionModules.enum.DAYOFREGISTRY) {
            const myDayOfRegistry = { inscriptionId: this.inscription.id, inscriptionDate: this.inscription.inscriptionDate };
            return InscriptionService.putInscriptionInscriptionDate(this.inscription.id, myDayOfRegistry);
        }
        if (moduleToEdit === InscriptionModules.enum.AUTHORIZATION) {
            return InscriptionService.putInscriptionAuthorization(this.inscription.id, this.inscription.authorization.toServer(this.inscription.id));
        }
        if (moduleToEdit === InscriptionModules.enum.LANGUAGEEXAMS) {
            const myLanguageSelected = { inscriptionId: this.inscription.id, examsLanguageSelected: this.inscription.contactData.examsLanguageSelected };
            return InscriptionService.putInscriptionLanguageSelected(this.inscription.id, myLanguageSelected);
        }
        if (moduleToEdit === InscriptionModules.enum.TURNDISABILITY) {
            return InscriptionService.putInscriptionDisabilityTurn(this.inscription.id, this.inscription.disabilityData.toServer(this.inscription.id, this.inscription.contactData.inscriptionTurn));
        }
        if (moduleToEdit === InscriptionModules.enum.PAYEXEMPTION) {
            const myAutonomousCommunity = this.inscription.disabilityData.hasDisability && !this.inscription.payExemption.isExceptionPaymentFamily ? this.inscription.disabilityData.autonomousCommunityDisability : this.inscription.payExemption.autonomousCommunity;
            return InscriptionService.putInscriptionPayExemption(this.inscription.id, this.inscription.payExemption.toServer(this.inscription.id, myAutonomousCommunity));
        }
        return InscriptionService.putInscriptionContactData(this.inscription.id, this.inscription.contactData.toServer(this.inscription.id));
    }

    addDocumentEditModule(file: any, evaluableConditionType: number) {
        if (this.inscription.filterDocuments(FileTypes.enum.DISABILITY).some((doc: InscriptionFile) => file.name === doc.fileName && evaluableConditionType === FileTypes.enum.DISABILITY)) {
            return;
        }
        if (this.inscription.filterDocuments(FileTypes.enum.PAY_EXEMPTION).some((doc: InscriptionFile) => file.name === doc.fileName && evaluableConditionType === FileTypes.enum.PAY_EXEMPTION)) {
            return;
        }

        file.evaluableConditionType = evaluableConditionType;
        file.loading = true;

        this.formDataDoc.push({
            processId: this.$route.params.id,
            inscriptionId: this.inscription.id,
            type: evaluableConditionType,
            formFile: file
        });
        this.inscription.addDocument(file);
    }

    removeDocumentEditModule(document: InscriptionFile) {
        const index = this.inscription.documents.indexOf(document);
        this.inscription.removeDocument(index, 1);
        if (!document.id) {
            const formData = this.formDataDoc.find(fD => fD.formFile.name === document.fileName);
            const indexFormData = this.formDataDoc.indexOf(formData);
            this.formDataDoc.splice(indexFormData, 1);
        } else {
            this.documentRemoveToModule.push(document);
        }
    }

    closeModal() {
        this.resetProperties();
        this.meritToManage = new Merit({});
    }

    resetPropsDisabledAndSavingDocuments() {
        this.disabledDisability = false;
        this.disabledNoReason = false;
        this.savingDocuments = false;
    }

    resetModuleToEditToNone() {
        this.moduleToEdit = InscriptionModules.enum.NONE;
    }

    resetDocumentRemoveToModuleAndFormDataDoc() {
        this.documentRemoveToModule = [];
        this.formDataDoc = [];
    }

    resetProperties() {
        this.editMeritModal = false;
        this.resetDocArrays();
        this.meritGroupId = null;
    }

    resetDocArrays() {
        this.documentsAddToMerit = [];
        this.documentRemoveToMerit = [];
        this.formDataDoc = [];
    }

    saveMerit() {
        this.submitted = true;
        if (!ValidationService.validateFormRequired(this.$refs.modalEditMerit) || !this.meritToManage.isEditing) {
            return;
        }
        this.meritToManage.isEditing = false;
        const requests: any[] = [];

        InscriptionService.putInscriptionMerit(this.inscription.id, this.meritToManage.toServerToUpdateMerit(this.inscription.id))
            .then(() => {
                this.formDataDoc.forEach(data => {
                    requests.push(InscriptionService.addFileToInscription(data));
                });
                this.documentRemoveToMerit.forEach(doc => {
                    requests.push(InscriptionService.removeFileFromInscription(this.inscription.id, doc.id));
                });
                Promise.all(requests)
                    .then(() => {
                        this.getDataProcess();
                        this.resetProperties();
                        this.meritToManage = new Merit({});
                        this.getInscriptionStructure();
                        this.getInscriptionData();
                    })
                    .catch(error => console.log(error));
            }).catch(error => console.log(error));
    }

    addDocumentMerit(file: any, evaluableConditionType: number, meritId: string, meritGroupId: string, meritSubGroupId?: string) {
        if ((this as any).documentsAddToMerit.some((doc: InscriptionFile) => file.name === doc.fileName && doc.meritId === meritId)) {
            return;
        }

        file.evaluableConditionType = evaluableConditionType;
        file.meritGroupId = meritGroupId;
        file.meritSubGroupId = meritSubGroupId !== null ? meritSubGroupId : '';
        file.meritId = meritId;
        file.loading = true;

        this.formDataDoc.push({
            processId: this.$route.params.id,
            inscriptionId: this.inscription.id,
            type: evaluableConditionType,
            formFile: file,
            meritId,
            meritGroupId,
            meritSubGroupId: file.meritSubGroupId
        });
        this.documentsAddToMerit.push(new InscriptionFile(file));
        this.documentsAddToMerit.forEach(doc => {
            if (file.name === doc.fileName) {
                doc.allowRemove = true;
            }
        });
        if (this.meritToManage.ratingType === MeritRatingType.enum.FIXED_SCORE || this.meritToManage.ratingType === MeritRatingType.enum.INTERVIEW) {
            this.meritToManage.calcTotalScore(this.documentsAddToMerit);
        }
    }

    removeDocumentMerit(document: InscriptionFile) {
        const index = this.documentsAddToMerit.indexOf(document);
        this.documentsAddToMerit.splice(index, 1);
        if (!document.id) {
            const formData = this.formDataDoc.find(fD => fD.formFile.name === document.fileName);
            const indexFormData = this.formDataDoc.indexOf(formData);
            this.formDataDoc.splice(indexFormData, 1);
        }
        if (document.id) {
            this.documentRemoveToMerit.push(document);
        }
        this.meritToManage.calcTotalScore(this.documentsAddToMerit);
    }

    changeMeritSelected(event) {
        this.submitted = false;
        this.meritToManage.isEditing = false;
        this.resetDocArrays();
        const meritToClone = this.meritsEmpties.filter(merit => merit.id === event.target.value);
        this.meritToManage = _.cloneDeep(meritToClone[0]);
        this.meritToManage.isEditing = true;
        this.$refs.fileImporter.resetProps();
        ValidationService.removeClassError(this.$refs.modalEditMerit);
    }

    selectMeritsEmpties(meritGroupInscription) {
        this.meritGroupId = meritGroupInscription.id;
        this.meritToManage = _.cloneDeep(this.meritsEmpties[0]);
        this.meritToManage.isEditing = true;
        this.editMeritModal = true;
    }

    editMerit(merit) {
        this.submitted = false;
        if (this.processData.phase > ProcessPhases.enum.EVALUATION || this.moduleToEdit || (merit.ratingType === MeritRatingType.enum.INTERVIEW && !merit.allowDocument)) {
            return;
        }
        this.meritToManage = _.cloneDeep(merit);
        this.meritToManage.isEditing = true;
        this.documentsAddToMerit = _.cloneDeep(this.inscription.documents.filter(doc => doc.meritId === merit.id));
        this.documentsAddToMerit.forEach(doc => {
            if (doc.editionType === EditionType.enum.ADMININSCRIPTION) {
                doc.allowRemove = true;
            }
        });
        this.editMeritModal = true;
    }

    refreshStreeTypes() {
        this.loadingStreetTypes = true;
        MasterDataService.getStreetTypes().then((response: any) => {
            this.streetTypes = response;
            this.loadingStreetTypes = false;
        }).catch(error => console.log(error));
    }

    getOrderedPayExemptionTypes() {
        this.payExemptionTypesEdited = _.orderBy(this.payExemptionTypesEdited, [element => _.deburr(element.name[this.currentLanguage]).toLocaleLowerCase()]);
    }

    getMunicipalities() {
        this.municipalities = [];
        if (!this.hasInscriptionMunicipalitySaved) {
            this.inscription.address.municipality = 0;
        }
        if (this.inscription.address.province) {
            this.$nextTick(() => {
                MasterDataService.getMunicipalities(Number(this.inscription.address.province))
                    .then((response: any) => {
                        response.forEach(municipality => {
                            if (!municipality.isObsolete || this.inscription.address.municipality === municipality.municipalityCode) {
                                this.municipalities.push({ name: municipality.municipalityName, id: municipality.municipalityCode, disabled: municipality.isObsolete });
                            }
                        });
                        this.municipalities = this.municipalities.sort((a, b) => a.name.localeCompare(b.name));
                    })
                    .catch(error => console.log(error));
            });
        }
        this.hasInscriptionMunicipalitySaved = false;
    }

    addFile(file: any, evaluableConditionType: number, requirementId?: string, meritId?: string, meritGroupId?: string, examId?: string, meritSubGroupId?: string) {
        const index = this.inscription.documents.length;
        file.evaluableConditionType = evaluableConditionType;
        file.requirementId = requirementId;
        file.meritGroupId = meritGroupId;
        file.meritSubGroupId = meritSubGroupId;
        file.examId = examId;
        file.meritId = meritId;
        file.loading = true;

        if (evaluableConditionType === FileTypes.enum.DISABILITY && this.inscription.filterDocuments(FileTypes.enum.DISABILITY).some((doc: InscriptionFile) => file.name === doc.fileName)) {
            return;
        }
        if (evaluableConditionType === FileTypes.enum.PAY_EXEMPTION && this.inscription.filterDocuments(FileTypes.enum.PAY_EXEMPTION).some((doc: InscriptionFile) => file.name === doc.fileName)) {
            return;
        }
        if (evaluableConditionType === FileTypes.enum.FEE_PAYMENT && this.inscription.filterDocuments(FileTypes.enum.FEE_PAYMENT).some((doc: InscriptionFile) => file.name === doc.fileName)) {
            return;
        }
        if (evaluableConditionType === FileTypes.enum.REQUIREMENT && this.inscription.filterDocuments(FileTypes.enum.REQUIREMENT).some((doc: InscriptionFile) => file.name === doc.fileName && doc.requirementId === requirementId)) {
            return;
        }
        if (evaluableConditionType === FileTypes.enum.MERIT && this.inscription.filterDocuments(FileTypes.enum.MERIT).some((doc: InscriptionFile) => file.name === doc.fileName && doc.meritId === meritId)) {
            return;
        }
        if (evaluableConditionType === FileTypes.enum.CREDITABLEEXAMS && this.inscription.filterDocuments(FileTypes.enum.CREDITABLEEXAMS).some((doc: InscriptionFile) => file.name === doc.fileName && doc.examId === examId)) {
            return;
        }

        const data: any = {
            processId: this.$route.params.id,
            inscriptionId: this.inscription.id,
            type: evaluableConditionType,
            formFile: file
        };
        if (data.type === FileTypes.enum.REQUIREMENT) {
            data.requirementId = requirementId;
        }
        if (data.type === FileTypes.enum.MERIT) {
            data.meritId = meritId;
            data.meritGroupId = meritGroupId;
            data.meritSubGroupId = meritSubGroupId;
        }
        if (data.type === FileTypes.enum.CREDITABLEEXAMS) {
            data.examId = examId;
        }
        this.inscription.addDocument(file);
        InscriptionService.addFileToInscription(data)
            .then((response: any) => {
                this.inscription.documents[index].id = response.id;
                this.inscription.documents[index].url = process.env.VUE_APP_ROOT_API + 'api/files/' + response.localizator + '/Content?origin=' + document.location.origin;
                this.inscription.documents[index].loading = false;
                this.inscription.documents[index].allowRemove = true;
                if (data.type === FileTypes.enum.MERIT) {
                    this.calcTotalScoreOnFixedScore(meritGroupId, meritId, this.inscription.filterDocuments(FileTypes.enum.MERIT), meritSubGroupId);
                    this.validationErrorMerits();
                }
            })
            .catch(() => {
                this.inscription.removeDocument(index, 1);
            });
    }

    calcTotalScoreOnFixedScore(meritGroupId, meritId, arrayToFind, meritSubGroupId?) {
        const meritToCalc = this.inscription.findMeritInInscription(meritGroupId, meritId, meritSubGroupId);
        if (meritToCalc.ratingType === MeritRatingType.enum.FIXED_SCORE || meritToCalc.ratingType === MeritRatingType.enum.INTERVIEW) {
            meritToCalc.calcTotalScore(arrayToFind);
        }
    }

    removeFile(document: InscriptionFile) {
        document.fileName = '';
        document.loading = true;
        InscriptionService.removeFileFromInscription(this.inscription.id, document.id)
            .then(() => {
                const index = this.inscription.documents.findIndex((doc: InscriptionFile) => doc.id === document.id);
                if (index !== -1) {
                    this.inscription.removeDocument(index, 1);
                    if (document.meritId) {
                        this.calcTotalScoreOnFixedScore(document.meritGroupId, document.meritId, this.inscription.filterDocuments(FileTypes.enum.MERIT), document.meritSubGroupId);
                        this.validationErrorMerits();
                    }
                }
            }).catch(error => console.log(error));
    }

    saveEditedJustifyDocument(justificationDocument, ref) {
        this.formValidationError = !ValidationService.validateFormRequired(this.$refs.inscriptionEditForm);
        if (this.formValidationError) {
            return;
        }
        justificationDocument.setTime();
        const data: any = {
            inscriptionId: this.inscription.id,
            formFile: this.fileJustify,
            registryDate: justificationDocument.date ? justificationDocument.date.toISOString() : null,
            registryReference: justificationDocument.registryReference,
            origin: justificationDocument.origin,
            isNewDocument: justificationDocument.isNewDocument,
            justificationDocumentId: justificationDocument.justificationDocumentId
        };
        InscriptionService.editJustifyFileToInscription(data, justificationDocument.justificationDocumentId)
            .then((response: any) => {
                justificationDocument.document.id = response; // id file
                justificationDocument.justificationDocumentId = response; // id to remove document without reloading data
                justificationDocument.document.url = process.env.VUE_APP_ROOT_API + 'api/files/' + response + '/Content?origin=' + document.location.origin;
                justificationDocument.loading = false;
                justificationDocument.isNewDocument = false;
                justificationDocument.isEditing = false;
                this.resetModuleToEditToNone();
                this.fileJustify = null;
                this.moveToRef(ref);
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            })
            .catch(() => {
                justificationDocument.loading = false;
            });
    }

    addNewJustifyDocument(file: File, justificationDocument: JustificationDocument) {
        this.fileJustify = file;
        justificationDocument.loading = true;
        justificationDocument.isNewDocument = true;
        justificationDocument.document = new OepDocument(file);
        justificationDocument.document.fileName = file.name.toString();
    }

    addJustifyDocument(file: OepDocument, justificationDocument: JustificationDocument) {
        justificationDocument.loading = true;
        const data: any = {
            processId: this.$route.params.id,
            inscriptionId: this.inscription.id,
            formFile: file
        };
        file.fileName = file.name.toString();
        justificationDocument.document = new OepDocument(file);
        InscriptionService.addJustifyFileToInscription(data)
            .then((response: any) => {
                justificationDocument.document.id = response.id;
                justificationDocument.document.url = process.env.VUE_APP_ROOT_API + 'api/files/' + response.localizator + '/Content?origin=' + document.location.origin;
                justificationDocument.loading = false;
            })
            .catch(() => {
                justificationDocument.document = null;
                justificationDocument.loading = false;
            });
    }

    removeJustifyFile(justificationDocument: JustificationDocument) {
        if (!justificationDocument.document) {
            return;
        }
        InscriptionService.removeJustifyFileFromInscription(this.inscription.id, justificationDocument.document.id)
            .then(() => {
                justificationDocument.document = null;
            }).catch(error => console.log(error));
    }

    showModalDelete() {
        this.showModalDeleteInscription = true;
    }

    cancelRemoveInscription() {
        this.showModalDeleteInscription = false;
    }

    removeInscription() {
        if (!this.canDeleteInscription) {
            return;
        }
        this.cancelRemoveInscription();
        InscriptionService.removeInscription(this.inscription.id)
            .then(() => {
                this.$router.push({ name: this.fromRouteParams && this.fromRouteParams.from ? this.fromRouteParams.from : 'ListManagementList', params: this.$route.params });
                this.updateDataPlan();
            }).catch(error => console.log(error));
    }

    resetNotification() {
        this.tryToSubmit = false;
        this.formValidationError = false;
    }

    onSubmit() {
        this.$nextTick(() => {
            notification.initialize();
        });
        this.tryToSubmit = true;
        this.formValidationError = !ValidationService.validateFormRequired(this.$refs.inscriptionEditForm);
        if (this.formValidationError || (this as any).disabilityRequired) {
            this.manageFormError();
            return;
        }
        this.loading = true;
        if ((!this.inscriptionData.hasFeePayment || this.pricePayExemption === 0) && this.inscription.status === InscriptionStatus.enum.DRAFT) {
            this.inscription.paymentMethod = PaymentMethod.enum.NONE;
        }
        InscriptionService.putInscription(this.inscription.toServer())
            .then(() => {
                this.loading = false;
                this.$router.push({ name: this.fromRouteParams && this.fromRouteParams.from ? this.fromRouteParams.from  : 'ListManagementList', params: this.$route.params });
                this.updateDataPlan();
                if (this.inscription.nif === this.userConnectedDni) {
                    this.updateUserStore();
                }
            })
            .catch(() => this.loading = false);
    }
    
    manageFormError() {
        ValidationService.validateErrorCollapsableBox();
        const firstErrorElement = ValidationService.getFirstErrorElement(this.$refs.inscriptionEditForm);
        if (firstErrorElement) {
            firstErrorElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    async updateDataPlan() {
        await (this.$parent.$parent as Layout).getSubscriptionPlan();
    }

    updateUserStore() {
        this.fetchAdminBlockedProcessesIds(this.userConnectedDni);
    }

    changeOficio(merit) {
        if (merit.ratingType === MeritRatingType.enum.FIXED_SCORE) {
            merit.calcTotalScore(this.inscription.filterDocuments(FileTypes.enum.MERIT));
            if (this.editMeritModal) {
                this.$refs.modalEditMerit.checkValidity();
            }
            return;
        }
        this.validationErrorMerits();
    }

    validationErrorMerits() {
        this.$nextTick(() => {
            if (this.tryToSubmit && !this.editMeritModal) {
                this.formValidationError = !ValidationService.validateFormRequired(this.$refs.inscriptionEditForm);
                ValidationService.validateErrorCollapsableBox();
            }
        });
    }

    async getCountries() {
        try {
            const response = await MasterDataService.getCountriesAsync();
            this.countries = response.map(element => ({ name: element.value, id: element.key }));
        } catch (error) {
            console.error(error);
        }
    }

    async getAutonomousCommunity() {
        try {
            this.autonomousCommunityList = await MasterDataService.getAutonomousCommunityAsync();
        } catch (error) {
            console.error(error);
        }
    }

    async getStreetTypes() {
        try {
            this.streetTypes = await MasterDataService.getStreetTypesAsync();
        } catch (error) {
            console.error(error);
        }
    }

    async getProvinces() {
        try {
            this.provinces = await MasterDataService.getProvincesAsync();
        } catch (error) {
            console.error(error);
        }
    }

    async getNationalities() {
        try {
            const response = await MasterDataService.getNationalitiesAsync();
            this.nationalities = response.map(element => ({ name: element.value, id: element.key }));
        } catch (error) {
            console.error(error);
        }
    }

    async getAddresMasterData() {
        try {
            await this.getCountries();
            await this.getNationalities();
            await this.getProvinces();
            await this.getAutonomousCommunity();
            await this.getStreetTypes();
        } catch (error) {
            console.log(error);
        }
    }

    getInscriptionStructure() {
        ProcessesService.getInscriptionStructure(this.$route.params.id).then((response: any) => {
            this.inscriptionData = new InscriptionData(response, this.processConfigurationPayExemptions);
            this.payExemptionTypesEdited = this.inscriptionData.payExemptionTypes;
            this.getOrderedPayExemptionTypes();
        }).catch(error => console.log(error));
    }

    async getInscriptionStructureAsync() {
        try {
            const response = await ProcessesService.getInscriptionStructure(this.$route.params.id);
            this.inscriptionData = new InscriptionData(response, this.processConfigurationPayExemptions);
            this.payExemptionTypesEdited = this.inscriptionData.payExemptionTypes;
            this.getOrderedPayExemptionTypes();
        } catch (error) {
            console.log(error);
        }
    }

    getDataProcess() {
        ProcessesService.getDataProcess(this.$route.params.id).then((response: any) => {
            this.processData = response;
            this.processData.type = parseInt(this.processData.type, 10);
        }).catch(error => console.log(error));
    }

    async getDataProcessAsync() {
        try {
            const response = await ProcessesService.getDataProcess(this.$route.params.id);
            this.processData = response;
            this.processData.type = parseInt(this.processData.type, 10);
        } catch (error) {
            console.log(error);
        }
    }

    async getProcessConfigurationPayExemptionsList() {
        try {
            const response = await MasterDataService.getProcessesConfigurationAsync(ProcessConfigurationTypes.enum.PAYEXEMPTIONS);
            this.processConfigurationPayExemptions = response
                .map(processConfig => ProcessConfigFactory.create(ProcessConfigurationTypes.enum.PAYEXEMPTIONS, processConfig))
                .filter(processConfig => processConfig.type >= processConfig.filterMinimumValidType);
        } catch (error) {
            console.log(error);
        }
    }

    async initClientInfo() {
        try {
            const response = await MasterDataService.getClientInfoAsync();
            this.clientInfo = new ClientInfo(response);
            this.hasRegistryApi = this.clientInfo.getHasRegistryApi();
        } catch (error) {
            console.log(error);
        }
    }

    async getInscriptionData(ref?) {
        this.meritLoaded = false;
        try {
            const inscription = await InscriptionService.getInscriptionById(this.$route.params.inscriptionId);
            const meriGroups = await InscriptionService.getInscriptionsMeritsDataAsync(this.$route.params.inscriptionId);
            inscription.meritGroups = meriGroups;
            this.inscription = new InscriptionCandidate(inscription);
            this.handleInscriptionDataResponse(ref);
        } catch (error) {
            console.log(error);
        }
    }

    handleInscriptionDataResponse(ref) {
        this.meritLoaded = true;
        if (this.inscriptionData.turnTypes.length === 1) {
            this.inscription.contactData.inscriptionTurn = this.inscriptionData.turnTypes[0].id;
        }
        if (this.inscription.contactData.disabilityTurnSelected) {
            this.inscription.disabilityData.hasDisability = YesNo.enum.YES;
        }
        this.inscription.hasAuthorizationProcess = this.inscriptionData.hasAuthorization;
        this.inscription.justificationDocuments.forEach(justifyDoc => {
            if ((justifyDoc.origin === OriginDocument.enum.JUSTIFY || justifyDoc.origin === OriginDocument.enum.CORRECTION) && (!justifyDoc.registryReference && this.hasRegistryApi)) {
                justifyDoc.date = null;
                justifyDoc.time = null;
            }
        });
        this.hasInscriptionMunicipalitySaved = this.inscription.address.municipality !== null;
        this.inscription.documents.forEach(doc => {
            doc.setAllowRemove(this.processData.phase, this.inscription.status);
        });
        this.$nextTick(() => {
            if (ref) {
                this.moveToRef(ref);
            }
        });
        if (this.inscription.status === InscriptionStatus.enum.DRAFT && this.inscriptionData.hasFeePayment) {
            this.inscription.paymentMethod = PaymentMethod.enum.OFFLINE;
        }
        this.getMunicipalities();
    }

    download(document) {
        DocumentService.download(document);
    }

    async loadData() {
        try {
            await this.initClientInfo();
            await this.getAddresMasterData();
            await this.getProcessConfigurationPayExemptionsList();
            await this.getDataProcessAsync();
            await this.getInscriptionStructureAsync();
            await this.getInscriptionData();
        } catch (error) {
            console.log(error);
        }
    }

    loadRouteParams() {
        this.fromRouteParams = this.$route.params;
    }

    async mounted() {
        this.loadRouteParams();
        this.defaultLanguage = this.availableLanguages[0];
        this.secondLanguage = this.availableLanguages[1];
        await this.loadData();
    }
}
