import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdTextarea, MdSelect, Toggle } from '@/Components';
import { FormMode, MeritRatingType, Languages, ProcessStatus, BaseMeritsType, ProcessPhases, Constants } from '@/Domain/enum';
import ValidationService from '@/Application/Services/ValidationService';
import MeritsService from '@/Services/MeritsService';
import { mapState, mapGetters } from 'vuex';
import { Merit, MultiLanguage } from '@/Domain/Entities';
import _ from 'lodash';

@Component({
    components: {
        MdTextarea,
        MdSelect,
        Toggle
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        requiredsProperties() {
            let requiredProps: MultiLanguage[] = [];
            requiredProps = Number(this.$data.objectToManage.ratingType) === MeritRatingType.enum.UNITS ? [this.$data.objectToManage.description, this.$data.objectToManage.helpText] : [this.$data.objectToManage.description];
            if (this.$data.objectToManage.allowExOficioDescription && Number(this.$data.objectToManage.ratingType) !== MeritRatingType.enum.INTERVIEW) {
                requiredProps.push(this.$data.objectToManage.exOficioDescription);
            }
            return requiredProps;
        }
    }
})

export default class EditNewMerit extends Vue {
    @Prop() info: any;
    @Prop() value: any;
    @Prop() mode: any;
    @Prop() process: any;
    @Prop() isUsed?: boolean;
    @Prop() baseMeritsType?: any;

    FormMode = FormMode;
    Languages = Languages;
    MeritRatingType = MeritRatingType;
    ProcessStatus = ProcessStatus;
    ProcessPhases = ProcessPhases;
    Constants = Constants;
    objectToManage = _.cloneDeep(this.info);
    submitted = false;
    tabActive: string = '';

    currentLanguage!: string;
    availableLanguages!: string[];

    $refs!: {
        newMeritForm: HTMLFormElement,
        editMeritForm: HTMLFormElement
    };

    save() {
        this.submitted = true;
        if (Number(this.objectToManage.ratingType) === MeritRatingType.enum.FIXED_SCORE || Number(this.objectToManage.ratingType) === MeritRatingType.enum.INTERVIEW) {
            this.objectToManage.maxScore = null;
        }
        if (Number(this.objectToManage.ratingType) === MeritRatingType.enum.INTERVIEW) {
            this.objectToManage.score = 0;
            this.objectToManage.allowCheckExOficio = false;
            this.objectToManage.allowExOficioDescription = false;
            this.objectToManage.exOficioDescription = new MultiLanguage({});
        }
        if (this.objectToManage.maxScore && this.objectToManage.score && !this.isValidScore()) {
            this.$emit('meritScoreError');
            return;
        }
        if (this.objectToManage.maxScore && !this.isValidScoreMerit()) {
            this.$emit('meritMaxScoreParentError');
            return;
        }
        if (Number(this.objectToManage.ratingType) !== MeritRatingType.enum.INTERVIEW) {
            this.objectToManage.allowDocument = null;
        }
        if (this.$props.mode === FormMode.enum.EDITING && ValidationService.validateFormRequired(this.$refs.editMeritForm)) {
            this.process.isSaving = true;
            MeritsService.editMerit(this.process.id, this.objectToManage.infoToServer())
                .then(response => {
                    let groupIndex;
                    if (this.baseMeritsType.from === BaseMeritsType.enum.MERITGROUP) { 
                        groupIndex = this.process.editingMeritGroup.meritChilds.findIndex(element => element.id === this.info.id);
                        this.process.editingMeritGroup.meritChilds[groupIndex] = new Merit(response, this.objectToManage.meritGroupId);
                        this.process.meritGroups.find(element => element.id === this.process.editingMeritGroup.id).meritChilds[groupIndex] = new Merit(response, this.objectToManage.meritGroupId);
                    }

                    if (this.baseMeritsType.from === BaseMeritsType.enum.MERITSUBGROUP) { 
                        groupIndex = this.process.editingMeritGroup.editingMeritSubGroup.merits.findIndex(element => element.id === this.info.id);
                        this.process.editingMeritGroup.editingMeritSubGroup.merits[groupIndex] = new Merit(response, this.objectToManage.meritGroupId);
                        this.process.editingMeritGroup.meritChilds.find(element => element.id === this.process.editingMeritGroup.editingMeritSubGroup.id).merits[groupIndex] = new Merit(response, this.objectToManage.meritGroupId);
                        const meritGroup = this.process.meritGroups.find(element => element.id === this.process.editingMeritGroup.id);
                        const subGroup = meritGroup.meritChilds.find(element => element.id === this.process.editingMeritGroup.editingMeritSubGroup.id);
                        subGroup.merits[groupIndex] = new Merit(response, this.objectToManage.meritGroupId);
                    }
                    this.objectToManage = {};
                    this.info.isEditing = false;
                    this.process.isSaving = false;
                    this.$emit('editedMerit');

                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        } else if (this.$props.mode === FormMode.enum.CREATING && ValidationService.validateFormRequired(this.$refs.newMeritForm)) {
            if (this.baseMeritsType.from === BaseMeritsType.enum.MERITSUBGROUP) {
                this.objectToManage.meritSubGroupId = this.process.editingMeritGroup.editingMeritSubGroup.id;
            }
            this.process.isSaving = true;
            MeritsService.addMerit(this.process.id, this.objectToManage.infoToServer())
                .then(response => {
                    if (this.baseMeritsType.from === BaseMeritsType.enum.MERITGROUP) { 
                        this.process.editingMeritGroup.meritChilds.push(new Merit(response, this.objectToManage.meritGroupId));
                    }

                    if (this.baseMeritsType.from === BaseMeritsType.enum.MERITSUBGROUP) { 
                        this.process.editingMeritGroup.editingMeritSubGroup.merits.push(new Merit(response, this.objectToManage.meritGroupId));
                    }
                        
                    this.objectToManage = {};
                    this.process.isSaving = false;
                    this.$emit('createdMerit');
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        }
    }

    cancel() {
        this.objectToManage = {};
        this.info.isEditing = false;
        this.$emit('canceledMerit');
    }

    setAllowExOficioDescription() {
        if (!this.objectToManage.allowCheckExOficio) {
            this.objectToManage.allowExOficioDescription = false;
            this.objectToManage.exOficioDescription = new MultiLanguage({});
        }
    }

    setExOficioDescription() {
        if (!this.objectToManage.allowExOficioDescription) {
            this.objectToManage.exOficioDescription = new MultiLanguage({});
        }
    }

    isValidScore() {
        return  Number(this.objectToManage.score) <= Number(this.objectToManage.maxScore);
    }

    isValidScoreMerit() {
        if (this.baseMeritsType.from === BaseMeritsType.enum.MERITSUBGROUP && this.process.editingMeritGroup.editingMeritSubGroup) {
            return this.process.editingMeritGroup.editingMeritSubGroup.maxScore >= Number(this.objectToManage.maxScore);
        }
        if (this.baseMeritsType.from === BaseMeritsType.enum.MERITGROUP && this.process.editingMeritGroup.maxScore) {
            return this.process.editingMeritGroup.maxScore >= Number(this.objectToManage.maxScore);
        }
        return true;
    }

    setAllowDocument() {
        if (Number(this.objectToManage.ratingType) === MeritRatingType.enum.INTERVIEW) {
            this.objectToManage.allowDocument = true;
        }
    }

    created() {
        this.tabActive = this.currentLanguage;
    }
}
