import { IProvisionalReplacementCandidate } from '@/Domain/interfaces/IProvisionalReplacementCandidate';
import WizardMerge from './WizardMerge';
import ReplacementService from '@/Services/ReplacementService';
import ReplacementMergeService from './ReplacementMergeService';
import { ReplacementListMergeOptions } from '@/Domain/enum';

export default class ReplacementMergeUseCaseBase {

  static async getProvisionalMergedList(wizardMerge: WizardMerge): Promise<IProvisionalReplacementCandidate[]> {
    try {
      let provisionalMergeList;
      if (wizardMerge.replacementMerge.mergeOptionSelected === ReplacementListMergeOptions.LIST_WITH_LIST) {
        provisionalMergeList = await ReplacementService.getProvisionalMergedListWhenMergeIsListToList(ReplacementMergeService.getReplacementMergeWithReplacementCommand(wizardMerge));
      }
      if (wizardMerge.replacementMerge.mergeOptionSelected === ReplacementListMergeOptions.LIST_WITH_PROCESS) {
        provisionalMergeList = await ReplacementService.getProvisionalMergedListWhenMergeIsListToProcess(ReplacementMergeService.getReplacementMergeWithProcessCommand(wizardMerge));
      }
      return provisionalMergeList ? provisionalMergeList : [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

}
