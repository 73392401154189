import { MultiLanguageTabs, MdCheckbox, SdModal, SdButton, SdSelect, SdFileImporter, SdInputText, SdTextarea, SdInputDatepicker, SdInputNumber, SdInputNif, SdRadio, SdTag, SdSlideOver, SdDocumentViewer, SdToggleMultiple, AutoTranslate, Tooltip, AddressForm, PersonalDataForm, TurnsForm, ContactDataForm, SdInputMail, RoundBadge, SdCard, MdInput } from '@/Components';

const componentsGlobal = {
    'multiLanguageTabs': MultiLanguageTabs,
    'md-checkbox': MdCheckbox,
    'md-input': MdInput,
    'sd-modal': SdModal,
    'sd-button': SdButton,
    'sd-select': SdSelect,
    'sd-file-importer': SdFileImporter,
    'sd-input-text': SdInputText,
    'sd-textarea': SdTextarea,
    'sd-input-datepicker': SdInputDatepicker,
    'sd-input-number': SdInputNumber,
    'sd-input-nif': SdInputNif,
    'sd-input-mail': SdInputMail,
    'sd-radio': SdRadio,
    'sd-tag': SdTag,
    'sd-slide-over': SdSlideOver,
    'sd-document-viewer': SdDocumentViewer,
    'sd-toggle-multiple': SdToggleMultiple,
    'sd-card': SdCard,
    'auto-translate': AutoTranslate,
    'tooltip': Tooltip,
    'address-form': AddressForm,
    'personal-data-form': PersonalDataForm,
    'turns-form': TurnsForm,
    'contact-data-form': ContactDataForm,
    'round-badge': RoundBadge
};

export default componentsGlobal;
