export enum AddReplacementListCandidateCriteria {
    NotSelected = -1,
    Top,
    Bottom,
    ByScore
}

export enum AddReplacementListCandidateCriteriaTranslations {
    Top = 'lang.replacement.addReplacementCandidatesOnTop',
    Bottom = 'lang.replacement.addReplacementCandidatesOnBottom',
    ByScore = 'lang.replacement.addReplacementCandidatesByScore'
}
