import { Component, Vue } from 'vue-property-decorator';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import i18n from '../../lang';
import moment from 'moment';
import { Replacement, ProcessInfo, Filter, SorterNew } from '@/Domain/Entities';
import { OppositionType, ProcessType, GroupTypes, ReplacementOriginType } from '@/Domain/enum';
import { SdFilter } from '@/Components';
import ProcessesService from '@/Services/ProcessesService';
import filters from './ReplacementEdit.filter';
import { mapState } from 'vuex';
import ReplacementService from '@/Services/ReplacementService';
import { ToasterService } from '@/Services/ToasterService';

@Component({
    components: {
        SdFilter
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat' }),
        orderedProcess() {
            return (this as ReplacementEdit).sorter.sortBy((this as ReplacementEdit).filter.filteredData);
        }
    }
})
export default class ReplacementEdit extends Vue {
    currentLanguage!: string;
    dateFormat!: string;

    replacementList: Replacement = new Replacement({});
    filter = new Filter();
    sorter = new SorterNew(['year', 'title'], ['desc', 'asc']);
    OppositionType = OppositionType;
    ProcessType = ProcessType;
    GroupTypes = GroupTypes;
    waitingResponse: boolean = false;
    selectProcessError = false;
    processList: ProcessInfo[] = [];
    isEditing: boolean = false;
    submitted: boolean = false;

    moment = moment;
    ReplacementOriginType = ReplacementOriginType;

    $refs!: {
        replacementForm: HTMLFormElement
    };

    get actionButtonTextToTranslate() {
       return this.replamementListComeFromProcessAndIsEditable || !this.isEditing
        ? 'lang.shared.next' 
        : 'lang.shared.update';
    }

    get replamementListComeFromProcessAndIsEditable() {
        return this.replacementList.originType === ReplacementOriginType.PROCESS && !this.replacementList.isModify;
    }

    goToNextAction() {
        if (this.replamementListComeFromProcessAndIsEditable || !this.isEditing) {
            this.goToListReplacementProvisional();
            return;
        }
        this.updateReplacement();
    }

    updateReplacement() {
        ReplacementService.updateReplacement(this.replacementList.toServer())
            .then(() => {
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                this.$router.push({
                    name: 'ListReplacementList',
                    params: {
                        replacementId: this.replacementList.id
                    }
                });
            });
    }

    goToListReplacementProvisional() {
        this.submitted = true;
        if (this.waitingResponse || this.$refs.replacementForm.querySelectorAll(':invalid').length) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        if (!this.replacementList.process.id) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.selectProcessError = true;
            return;
        }
        this.$router.push({
            name: 'ListReplacementProvisional',
            params: {
                replacementList: (this.replacementList as any)
            }
        });
        this.waitingResponse = true;
        this.selectProcessError = false;
    }

    setProcessList() {
        this.processList = (this as ReplacementEdit).sorter.sortBy((this as ReplacementEdit).filter.filteredData);
        this.replacementList.process = new ProcessInfo({});
    }

    selectProcess(process) {
        if (this.replacementList.id) {
            return;
        }
        if (process.active) {
            this.replacementList.process = new ProcessInfo({});
            process.active = false;
            return;
        }

        this.processList.forEach(element => {
            element.active = false;
        });

        process.active = true;
        this.replacementList.process = new ProcessInfo(process);
    }

    getProcessesFinished() {
        ProcessesService.getProcessesFinished().then(
            response => {
                this.processList = response.map(process => new ProcessInfo(process, this.$store.state.processConfigStore.processConfigGroups));
                this.filter.setData(this.processList);
                this.filter.setFilterElements(filters);
            })
            .catch(error => console.log(error));
    }

    goToCreateReplacement() {
        this.$router.push({
            name: 'ReplacementNew'
        });
        this.getProcessesFinished();
    }

    cloneReplacementListCriteriasIfNecessary() {
        if (this.$route.params.cloneCriteria) {
            this.replacementList.criteria = (this.$route.params.cloneCriteria as any);
        }
    }

    cloneSelecredReplacementListCandidateIdsIfNecessary() {
        if (this.$route.params.cloneSelectedReplacementListCandidateIds) {
            this.replacementList.selectedReplacementListCandidateIds = (this.$route.params.cloneSelectedReplacementListCandidateIds as any);
        }
    }

    initReplacementEditFlow() {
        this.replacementList = new Replacement(this.$route.params.replacement, this.$store.state.processConfigStore.processConfigGroups);
        this.isEditing = true;

        if (this.replacementList.originType === ReplacementOriginType.PROCESS) {
            this.replacementList.process.active = true;
            this.processList.push(this.replacementList.process);
            this.filter.setData(this.processList);
        }
    }

    manageCreateOrEditReplacementFlow() {
        if (this.$route.params.replacement && (this.$route.params.replacement as any).id) {
            this.initReplacementEditFlow();
            return;
        }
        this.goToCreateReplacement();
    }

    mounted() {
        this.manageCreateOrEditReplacementFlow(); 
        this.cloneReplacementListCriteriasIfNecessary();
        this.cloneSelecredReplacementListCandidateIdsIfNecessary();
        this.$nextTick(() => {
            notification.initialize();
        });
    }
}
